import React, { useEffect, useState } from "react";
import CustomerList from "../../components/customer-profile/CustomerList";
import BottomNav from "../../components/navbar/BottomNav";
import NavBar from "../../components/navbar/NavBar";
import styles from "./Customers.module.css";
import Input from "../../components/inputs/Input";
import SearchInput from "../../components/inputs/SearchInput";
import { useSelector } from "react-redux";
import VendorApi from "../../utils/api/VendorApi";
import LoadingAnimation from "../../components/UI/LoadingAnimation";
import Button from "../../components/button/Button";
import Modal from "../../components/UI/Modal";

const listColors = ["#5D5FEF", "#F57E00", "#8F00A7", "#BC0101"];

function Customers(props) {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [vendorCustomers, setVendorCustomers] = useState(null);
  const [filteredResults, setFilteredResults] = useState(vendorCustomers);
  const [isLoading, setIsLoading] = useState(false);
  const [formLoader, setFormLoader] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [password, setPassword] = useState("");
  const [userData, setUserData] = useState(null);
  const [vehicleType, setVehicleType] = useState("");
  const [openMessageModal, setOpenMessageModal] = useState(false);
  let [modalMessage, setModalMessage] = useState("");

  const token = useSelector((data) => data.auth.token);

  useEffect(() => {
    const fetchVendorCustomers = async () => {
      try {
        setIsLoading(true);
        const res = VendorApi.vendorCustomersData(token, currentPage);
        res.then((response) => {
          setVendorCustomers(response.data);
        });
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      } catch (error) {
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
        console.log(error.response.message);
      }
    };
    fetchVendorCustomers();
  }, [token, currentPage]);

  useEffect(() => {
    const filterResults = vendorCustomers?.results?.filter((data, index) =>
      data.user.fullname.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredResults(filterResults);
  }, [search, vendorCustomers]);

  const searchInputHandler = (e) => {
    setSearch(e.target.value);
  };
  const searchSubmitHandler = (e) => {
    e.preventDefault();
  };

  // Function to navigate to another page
  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const passwordChangeHandler = (e) => {
    setPassword(e.target.value);
  };

  const temporaryPasswordSubmitHandler = async (e) => {
    e.preventDefault();
    setFormLoader(true);
    try {
      const res = await VendorApi.vendorSignupHandler({
        email: userData?.user?.email,
        password: password,
        fullname: userData?.user?.fullname,
        phone: userData?.user?.phone,
        vehicle_data: {
          plate_number: userData?.plate_number,
          vehicle_year: userData?.vehicle_year,
          vehicle_make: userData?.vehicle_make,
          vehicle_model: userData?.vehicle_model,
          vehicle_trim: "44444",
        },
      });

      const setEngineUpload = await VendorApi.setengineHandler({
        plate_no: userData?.plate_number,
        make: userData?.vehicle_make,
        model: userData?.vehicle_model,
        year: userData?.vehicle_year,
        v_type: vehicleType,
      });
      setFormLoader(false);
      setOpenMessageModal(true);

      setModalMessage({
        title: "Success",
        message: `User has been onboarded successfully.`,
      });

      return {
        res,
        setEngineUpload,
      };
    } catch (err) {
      setFormLoader(false);
      setOpenMessageModal(true);
      const errors = [];
      if (err.response.data.errors.message) {
        errors.push(err.response.data.errors.message);
      } else if (err.response.data.errors) {
        const errorMessages = err.response.data.errors;
        const errorMessage = Object.values(errorMessages);
        errors.push(errorMessage);
      }

      setModalMessage({
        title: "Error!",
        message: `${errors[0]}`,
      });
    }
  };

  const vehicleTypeHandler = (e) => {
    setVehicleType(e.target.value);
  };

  // close message modal
  const backHandler = () => {
    setOpenMessageModal(false);
  };
  return (
    <div>
      {openMessageModal && (
        <Modal
          success={modalMessage.title === "Error!" ? false : true}
          title={modalMessage.title}
          message={modalMessage.message}
          accept="Back"
          back=""
          close={backHandler}
          confirm={backHandler}
        />
      )}{" "}
      <NavBar title="Customers list" setShowMenu={props.setShowMenu} />
      {isLoading && (
        <div className={styles.loading}>
          <LoadingAnimation />
        </div>
      )}
      {/* Modal overlay container */}
      {openModal && (
        <div
          className={styles.overlay}
          onClick={() => {
            setOpenModal(false);
          }}
        >
          <div
            className={styles.modal}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <h1>Create Customer Account</h1>

            <form
              onSubmit={temporaryPasswordSubmitHandler}
              className={styles.form}
            >
              <Input
                label="Enter Temporary Password"
                onChange={passwordChangeHandler}
                value={password}
                type="text"
                placeholder="Enter Temporary Password"
              />

              <div className={styles.form__inner}>
                <label htmlFor="Year">Vehicle Type</label>
                <div className={styles.form__passwordInput}>
                  <select onChange={vehicleTypeHandler} required>
                    <option value="">Vehicle Type</option>
                    <option value="SUV">SUV</option>
                    <option value="Hatchback">Hatchback</option>
                    <option value="Crossover">Crossover</option>
                    <option value="Convertible">Convertible</option>
                    <option value="Sedan">Sedan</option>
                    <option value="Sports Car">Sports Car</option>
                    <option value="Coupe">Coupe</option>
                    <option value="Minivan">Minivan</option>
                    <option value="Station Wagon">Station Wagon</option>
                    <option value="Pickup Truck">Pickup Truck</option>
                  </select>
                </div>
              </div>

              {!formLoader ? (
                <Button>Create Account</Button>
              ) : (
                <Button>Loading...</Button>
              )}
            </form>
          </div>
        </div>
      )}
      {!isLoading && (
        <div className={styles.head}>
          <div className={styles.main_hero}>
            <div className={styles.main_hero_inner}>
              <form onSubmit={searchSubmitHandler}>
                <SearchInput
                  placeholder="Search"
                  onChange={searchInputHandler}
                  value={search}
                  type="text"
                />
              </form>
            </div>
          </div>
          <div className={styles.transactions_list}>
            {vendorCustomers?.results?.length === 0 ? (
              <div className={styles.empty}>
                <h3>Your Customers list is empty</h3>
                <p>
                  Customers contact would be saved here automatically during
                  diagnosis
                </p>
                <svg
                  width="258"
                  height="206"
                  viewBox="0 0 258 206"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_5226_80215)">
                    <path
                      opacity="0.5"
                      d="M129 205.999C200.245 205.999 258 163.965 258 112.114C258 60.2624 200.245 18.2285 129 18.2285C57.7553 18.2285 0 60.2624 0 112.114C0 163.965 57.7553 205.999 129 205.999Z"
                      fill="#D7FBDB"
                    />
                    <path
                      d="M86.3907 53.1402C86.3187 53.1401 86.2482 53.1256 86.1867 53.0983C86.1253 53.071 86.0753 53.0319 86.0423 52.9853C86.0093 52.9387 85.9945 52.8865 85.9996 52.8342C86.0046 52.7819 86.0293 52.7317 86.0709 52.6889C86.1432 52.614 93.245 45.1422 85.3172 38.3307C83.9139 37.1185 82.1633 36.1444 80.1881 35.4767C78.2129 34.809 76.0609 34.4637 73.883 34.4652H73.7785C74.5314 36.3686 74.397 38.061 73.331 39.3593C72.8767 39.946 72.2063 40.4287 71.3954 40.7531C70.5845 41.0775 69.6652 41.2306 68.7412 41.1952C67.9764 41.1497 67.2464 40.9427 66.6408 40.5998C66.0351 40.2568 65.5801 39.7927 65.3314 39.2644C64.4595 37.6092 65.3707 36.0043 67.7718 34.97C69.2932 34.3594 70.9838 34.0056 72.7184 33.9347C72.1542 32.8458 71.4206 31.8074 70.5318 30.84C67.6693 27.6583 62.452 25.456 56.2178 24.7974C50.9349 24.2392 45.8716 24.8964 43.3172 26.4709C43.2377 26.52 43.1346 26.544 43.0308 26.5378C42.9269 26.5315 42.8307 26.4955 42.7633 26.4376C42.696 26.3797 42.6629 26.3048 42.6715 26.2292C42.6801 26.1536 42.7296 26.0836 42.8091 26.0345C45.5278 24.3588 50.8351 23.6504 56.3306 24.2315C62.7879 24.9135 68.2033 27.2082 71.1895 30.5268C72.1577 31.5792 72.9453 32.7139 73.5345 33.9056C75.8835 33.8645 78.2151 34.2075 80.3578 34.9093C82.5004 35.6111 84.3994 36.6538 85.9149 37.9606C94.2426 45.1153 86.7864 52.9414 86.7102 53.0195C86.674 53.0567 86.6261 53.087 86.5705 53.108C86.515 53.129 86.4533 53.14 86.3907 53.1402ZM72.9734 34.4872C71.2915 34.5391 69.6487 34.8728 68.173 35.4624C66.0999 36.3555 65.3321 37.6666 66.0657 39.0599C66.2644 39.4868 66.6298 39.8624 67.1172 40.1405C67.6046 40.4186 68.1929 40.5873 68.8099 40.6259C69.5869 40.6512 70.3582 40.5187 71.0376 40.2432C71.717 39.9678 72.2777 39.5603 72.6571 39.0662C73.6371 37.8737 73.727 36.2888 72.9734 34.4872Z"
                      fill="#8EA396"
                    />
                    <path
                      d="M36.5364 31.0848C35.8694 31.2302 35.1508 31.1803 34.5335 30.9459C33.9162 30.7114 33.4491 30.3108 33.2315 29.8294C33.014 29.3481 33.0633 28.8239 33.3689 28.3685C33.6744 27.9132 34.2122 27.5626 34.8676 27.3915C35.523 27.2204 36.2442 27.2423 36.8778 27.4525C37.5114 27.6626 38.0072 28.0445 38.2597 28.5167C38.5123 28.989 38.5015 29.5143 38.2297 29.9809C37.958 30.4475 37.4467 30.8184 36.8048 31.0147C36.7171 31.0415 36.6275 31.0649 36.5364 31.0848ZM35.2299 27.9056C35.1662 27.9197 35.1035 27.9361 35.0421 27.9548C34.5895 28.0935 34.2292 28.3552 34.0379 28.6844C33.8465 29.0135 33.8393 29.384 34.0177 29.7169C34.1962 30.0499 34.5462 30.319 34.9932 30.4669C35.4402 30.6148 35.9489 30.6299 36.4109 30.5089C36.873 30.3879 37.2519 30.1404 37.4669 29.8191C37.6819 29.4978 37.7161 29.1281 37.5621 28.7887C37.4082 28.4494 37.0782 28.1672 36.6426 28.0022C36.207 27.8373 35.7001 27.8026 35.2299 27.9056Z"
                      fill="#8EA396"
                    />
                    <path
                      d="M36.5313 26.6012C35.8997 26.7384 35.2207 26.7008 34.6248 26.4956C34.029 26.2905 33.5583 25.9323 33.3034 25.4899C33.0989 25.1353 33.0436 24.7444 33.1443 24.3669C33.245 23.9893 33.4974 23.642 33.8694 23.3688C34.2414 23.0956 34.7163 22.9088 35.2342 22.8321C35.752 22.7553 36.2895 22.7921 36.7787 22.9377C37.2678 23.0833 37.6867 23.3312 37.9822 23.65C38.2778 23.9689 38.4368 24.3444 38.4391 24.729C38.4414 25.1136 38.287 25.4901 37.9953 25.8108C37.7036 26.1316 37.2878 26.3821 36.8005 26.5309C36.7124 26.5576 36.6226 26.5811 36.5313 26.6012ZM35.2264 23.423C35.1621 23.437 35.0989 23.4535 35.037 23.4724C34.576 23.6133 34.2107 23.8816 34.0214 24.2184C33.8321 24.5552 33.8343 24.933 34.0276 25.2686C34.1685 25.5132 34.4039 25.7229 34.7051 25.8722C35.0063 26.0216 35.3606 26.1042 35.725 26.1101C36.0893 26.116 36.4483 26.0449 36.7583 25.9054C37.0684 25.766 37.3163 25.5642 37.472 25.3243C37.6278 25.0845 37.6847 24.817 37.636 24.5541C37.5872 24.2912 37.4348 24.0442 37.1972 23.8431C36.9597 23.6419 36.6471 23.4951 36.2973 23.4205C35.9476 23.3459 35.5756 23.3469 35.2264 23.423Z"
                      fill="#8EA396"
                    />
                    <path
                      d="M35.769 28.6233C37.6987 28.6233 39.2629 27.8629 39.2629 26.9249C39.2629 25.9869 37.6987 25.2266 35.769 25.2266C33.8394 25.2266 32.2751 25.9869 32.2751 26.9249C32.2751 27.8629 33.8394 28.6233 35.769 28.6233Z"
                      fill="#F5FFF1"
                    />
                    <path
                      d="M37.3144 28.7475C36.8135 28.8554 36.2938 28.9097 35.7714 28.9088C33.5918 28.9088 31.8834 28.0394 31.8814 26.9267C31.8794 25.814 33.5867 24.9415 35.7659 24.9401C36.716 24.9269 37.6507 25.1157 38.4555 25.4834C38.8168 25.6205 39.1196 25.8271 39.331 26.0806C39.5425 26.3341 39.6544 26.6249 39.6547 26.9215C39.6551 27.218 39.5437 27.509 39.3329 27.7627C39.122 28.0165 38.8196 28.2234 38.4586 28.3609C38.1057 28.5303 37.72 28.6606 37.3144 28.7475ZM34.4622 25.6505C33.4123 25.8792 32.6677 26.3728 32.6684 26.9276C32.6684 27.6934 34.0897 28.3391 35.7706 28.3383C36.5655 28.3504 37.348 28.1937 38.0225 27.8873C38.2755 27.8025 38.4898 27.6666 38.6399 27.4958C38.7901 27.325 38.8697 27.1265 38.8695 26.9238C38.8692 26.721 38.7891 26.5226 38.6385 26.352C38.488 26.1814 38.2734 26.0458 38.0201 25.9613C37.345 25.6559 36.5622 25.5003 35.7675 25.5135C35.3255 25.5128 34.8858 25.559 34.4622 25.6505Z"
                      fill="#8EA396"
                    />
                    <path
                      d="M36.626 28.5725L36.5435 25.2698L35.415 25.2363L35.336 28.6111L36.626 28.5725Z"
                      fill="#8EA396"
                    />
                    <path
                      d="M31.1337 27.6442C31.3329 27.6442 31.4944 27.5266 31.4944 27.3817C31.4944 27.2367 31.3329 27.1191 31.1337 27.1191C30.9344 27.1191 30.7729 27.2367 30.7729 27.3817C30.7729 27.5266 30.9344 27.6442 31.1337 27.6442Z"
                      fill="#8EA396"
                    />
                    <path
                      d="M31.4154 26.2379C31.6146 26.2379 31.7761 26.1204 31.7761 25.9754C31.7761 25.8304 31.6146 25.7129 31.4154 25.7129C31.2162 25.7129 31.0547 25.8304 31.0547 25.9754C31.0547 26.1204 31.2162 26.2379 31.4154 26.2379Z"
                      fill="#8EA396"
                    />
                    <path
                      d="M151.138 37.967C151.054 37.967 150.972 37.9474 150.904 37.911C150.837 37.8746 150.787 37.8234 150.762 37.7649C149.459 34.2228 149.111 30.5304 149.738 26.892C150.754 21.573 154.784 14.5922 168.218 11.5354C170.108 11.0931 172.046 10.7697 174.012 10.5686C174.645 7.69258 177.053 4.57008 182.436 2.41672C195.231 -2.69985 203.874 1.89083 203.959 1.93772C204.043 1.98323 204.098 2.05098 204.113 2.12606C204.127 2.20114 204.101 2.27741 204.038 2.33808C203.976 2.39876 203.882 2.43887 203.779 2.4496C203.676 2.46032 203.571 2.44078 203.488 2.39528C203.406 2.35066 195.147 -2.01352 182.816 2.91831C177.784 4.93068 175.48 7.81898 174.825 10.5002C180.75 10.0756 183.587 11.514 184.861 12.6004C185.649 13.2536 186.156 14.0612 186.318 14.9225C186.48 15.7838 186.29 16.6608 185.772 17.4441C185.362 18.077 184.698 18.6046 183.868 18.9573C183.038 19.3101 182.08 19.4713 181.122 19.4199C177.813 19.2786 175.075 17.2528 174.146 14.2587C173.84 13.2452 173.759 12.2025 173.905 11.1703C172.056 11.3636 170.232 11.6688 168.453 12.0828C157.983 14.4652 151.95 19.4751 150.516 26.9724C149.902 30.5282 150.241 34.137 151.513 37.599C151.531 37.6418 151.535 37.687 151.525 37.7311C151.515 37.7752 151.49 37.8168 151.454 37.8528C151.417 37.8888 151.37 37.918 151.315 37.9381C151.26 37.9583 151.199 37.9688 151.138 37.9688V37.967ZM174.706 11.0988C174.543 12.1114 174.613 13.1368 174.913 14.1326C175.762 16.8716 178.217 18.7227 181.168 18.8488C181.972 18.8907 182.775 18.7548 183.471 18.4588C184.167 18.1628 184.724 17.7206 185.069 17.1902C185.518 16.5072 185.681 15.7432 185.538 14.9933C185.394 14.2433 184.952 13.5406 184.264 12.9724C182.427 11.4033 179.074 10.7696 174.706 11.0988Z"
                      fill="#8EA396"
                    />
                    <path
                      d="M213.114 4.82893C212.585 4.82818 212.066 4.71852 211.619 4.51261C211.172 4.3067 210.814 4.01294 210.586 3.66523C210.358 3.31751 210.27 2.93003 210.332 2.5475C210.394 2.16496 210.604 1.80297 210.936 1.50331C211.269 1.20365 211.711 0.978533 212.212 0.85394C212.712 0.729347 213.251 0.71036 213.766 0.79917C214.281 0.88798 214.751 1.08097 215.122 1.35585C215.493 1.63074 215.749 1.97633 215.862 2.35272C215.95 2.65105 215.945 2.95994 215.848 3.25678C215.751 3.55361 215.564 3.83087 215.3 4.06827C215.037 4.30566 214.704 4.49717 214.325 4.62879C213.947 4.7604 213.533 4.82878 213.114 4.82893ZM213.12 1.31608C212.702 1.31601 212.294 1.41029 211.953 1.58593C211.612 1.76156 211.354 2.00991 211.215 2.29672C211.075 2.58354 211.062 2.89471 211.176 3.18735C211.29 3.47998 211.526 3.73967 211.851 3.93062C212.177 4.12158 212.575 4.23439 212.992 4.25351C213.409 4.27263 213.824 4.19712 214.18 4.03739C214.536 3.87765 214.815 3.64156 214.978 3.36165C215.142 3.08174 215.182 2.7718 215.093 2.47454C214.998 2.14544 214.75 1.85035 214.39 1.63924C214.031 1.42813 213.582 1.31396 213.12 1.31608Z"
                      fill="#8EA396"
                    />
                    <path
                      d="M211.93 9.49663C211.21 9.49652 210.518 9.29547 209.995 8.93489C209.472 8.57431 209.159 8.08162 209.121 7.55825C209.082 7.03489 209.321 6.52067 209.787 6.12145C210.254 5.72223 210.913 5.4684 211.629 5.4122C212.345 5.35601 213.064 5.50172 213.636 5.81936C214.209 6.13699 214.593 6.60236 214.709 7.11969C214.824 7.63701 214.663 8.1669 214.258 8.60029C213.853 9.03368 213.235 9.33759 212.532 9.44944C212.334 9.48081 212.133 9.49663 211.93 9.49663ZM211.941 5.98349C211.795 5.98355 211.65 5.99496 211.508 6.01752C211.006 6.09775 210.566 6.31493 210.277 6.62438C209.989 6.93383 209.874 7.31202 209.957 7.68112C210.04 8.05023 210.314 8.38218 210.723 8.60867C211.132 8.83517 211.644 8.93899 212.155 8.89876C212.666 8.85854 213.136 8.67733 213.469 8.39242C213.802 8.10752 213.973 7.74059 213.945 7.36712C213.918 6.99366 213.694 6.64207 213.322 6.3847C212.949 6.12732 212.454 5.98374 211.941 5.98349Z"
                      fill="#8EA396"
                    />
                    <path
                      d="M216.15 5.66372C216.398 4.68695 214.97 3.67635 212.96 3.40649C210.951 3.13663 209.121 3.7097 208.874 4.68647C208.626 5.66325 210.054 6.67384 212.064 6.9437C214.073 7.21356 215.903 6.6405 216.15 5.66372Z"
                      fill="#F5FFF1"
                    />
                    <path
                      d="M213.055 7.29806C212.699 7.29765 212.344 7.27372 211.994 7.22657C211.006 7.10725 210.087 6.78067 209.35 6.28687C209.027 6.08659 208.779 5.83035 208.629 5.54184C208.479 5.25334 208.43 4.94188 208.489 4.63626C208.584 4.33554 208.782 4.05711 209.064 3.8267C209.346 3.59628 209.703 3.42131 210.103 3.31794C211.038 3.0609 212.051 2.99479 213.034 3.12675C214.017 3.25871 214.931 3.58371 215.678 4.06661C216.001 4.26689 216.249 4.52313 216.399 4.81164C216.55 5.10015 216.598 5.4116 216.539 5.71722C216.444 6.01795 216.246 6.29637 215.964 6.52678C215.682 6.7572 215.325 6.93217 214.925 7.03554C214.333 7.21407 213.696 7.30337 213.055 7.29806ZM211.97 3.62678C211.436 3.62185 210.907 3.69548 210.413 3.84326C210.134 3.91131 209.883 4.02894 209.681 4.18553C209.48 4.34211 209.336 4.5327 209.262 4.74006C209.229 4.95311 209.27 5.16867 209.381 5.36718C209.493 5.5657 209.67 5.74087 209.898 5.87679C210.533 6.28052 211.306 6.55236 212.136 6.66381C212.966 6.77526 213.821 6.72221 214.613 6.51022C214.892 6.4422 215.144 6.32457 215.345 6.16798C215.546 6.01139 215.69 5.82078 215.764 5.61342C215.796 5.40039 215.755 5.18487 215.644 4.98639C215.533 4.7879 215.356 4.61271 215.128 4.47669C214.503 4.06139 213.724 3.78781 212.888 3.68941C212.585 3.64834 212.278 3.62738 211.97 3.62678Z"
                      fill="#8EA396"
                    />
                    <path
                      d="M212.058 3.3418L211.268 6.79231L212.434 6.98562L213.412 3.48278L212.058 3.3418Z"
                      fill="#8EA396"
                    />
                    <path
                      d="M216.795 7.05397C217.006 7.05397 217.177 6.92952 217.177 6.77601C217.177 6.62249 217.006 6.49805 216.795 6.49805C216.584 6.49805 216.413 6.62249 216.413 6.77601C216.413 6.92952 216.584 7.05397 216.795 7.05397Z"
                      fill="#8EA396"
                    />
                    <path
                      d="M217.461 5.63014C217.672 5.63014 217.843 5.5057 217.843 5.35218C217.843 5.19867 217.672 5.07422 217.461 5.07422C217.25 5.07422 217.079 5.19867 217.079 5.35218C217.079 5.5057 217.25 5.63014 217.461 5.63014Z"
                      fill="#8EA396"
                    />
                    <g clip-path="url(#clip1_5226_80215)">
                      <path
                        d="M121.083 86.7773H143.333V91.7218H121.083V86.7773Z"
                        fill="#8EA396"
                      />
                      <path
                        d="M121.083 96.666H143.333V101.61H121.083V96.666Z"
                        fill="#8EA396"
                      />
                      <path
                        d="M121.083 106.555H143.333V111.499H121.083V106.555Z"
                        fill="#8EA396"
                      />
                      <path
                        d="M121.083 116.445H143.333V121.39H121.083V116.445Z"
                        fill="#8EA396"
                      />
                      <path
                        d="M121.083 126.334H143.333V131.278H121.083V126.334Z"
                        fill="#8EA396"
                      />
                      <path
                        d="M111.194 86.7773H116.139V91.7218H111.194V86.7773Z"
                        fill="#8EA396"
                      />
                      <path
                        d="M111.194 96.666H116.139V101.61H111.194V96.666Z"
                        fill="#8EA396"
                      />
                      <path
                        d="M111.194 106.555H116.139V111.499H111.194V106.555Z"
                        fill="#8EA396"
                      />
                      <path
                        d="M111.194 116.445H116.139V121.39H111.194V116.445Z"
                        fill="#8EA396"
                      />
                      <path
                        d="M111.194 126.334H116.139V131.278H111.194V126.334Z"
                        fill="#8EA396"
                      />
                      <path
                        d="M153.222 71.9453H103.778C102.466 71.9453 101.209 72.4662 100.282 73.3935C99.3543 74.3208 98.8333 75.5784 98.8333 76.8898V146.112C98.8333 147.423 99.3543 148.681 100.282 149.608C101.209 150.535 102.466 151.056 103.778 151.056H153.222C154.534 151.056 155.791 150.535 156.718 149.608C157.646 148.681 158.167 147.423 158.167 146.112V76.8898C158.167 75.5784 157.646 74.3208 156.718 73.3935C155.791 72.4662 154.534 71.9453 153.222 71.9453ZM153.222 146.112H103.778V76.8898H153.222V146.112Z"
                        fill="#8EA396"
                      />
                    </g>
                  </g>
                  <defs>
                    <clipPath id="clip0_5226_80215">
                      <rect width="258" height="206" fill="white" />
                    </clipPath>
                    <clipPath id="clip1_5226_80215">
                      <rect
                        width="89"
                        height="89"
                        fill="white"
                        transform="translate(84 67)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            ) : (
              <div>
                {filteredResults?.map((data, index) => {
                  const randColor = Math.floor(
                    Math.random() * listColors.length
                  );
                  return (
                    <CustomerList
                      setOpenModal={setOpenModal}
                      setUserData={setUserData}
                      data2={data}
                      color={listColors[randColor]}
                    />
                  );
                })}
              </div>
            )}
          </div>
          <div className={styles.pagination}>
            {Array.from(
              { length: vendorCustomers?.total_pages },
              (_, index) => (
                <div
                  onClick={() => goToPage(index + 1)}
                  style={{
                    backgroundColor: currentPage === index + 1 ? "#D7FBDB" : "",
                  }}
                >
                  {index + 1}
                </div>
              )
            )}
          </div>
        </div>
      )}
      <BottomNav />
    </div>
  );
}

export default Customers;
