import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./Login.module.css";
import Logo from "../../components/logo/Logo";
import PasswordInput from "../../components/inputs/PasswordInput";
import "react-toastify/dist/ReactToastify.css";
import Button from "../../components/button/Button";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Authentication from "../../utils/api/Authentication";
import EmailInput from "../../components/inputs/EmailInput";
import jwt_decode from "jwt-decode";
import { authActions } from "../../utils/store/redux-store/Index";

const initialValues = {
  password: "",
  email: "",
};
const initialBoolenValues = {
  toggle: false,
  passwordInvalid: false,
  phoneNumberInvalid: false,
};

function Login() {
  const [values, setValues] = useState(initialValues);
  const [boolen, setBoolen] = useState(initialBoolenValues);
  const [loading, setLoading] = useState(false);
  const [validEmail, setValid] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  //Form Validation//

  useEffect(() => {
    if (
      values.email.trim().length >= 1 &&
      values.email.includes("@") &&
      values.email.includes(".com")
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [values.email]);

  useEffect(() => {
    if (
      values.password.trim().length >= 1 &&
      values.email.trim().length >= 1 &&
      values.email.includes("@") &&
      values.email.includes(".com")
    ) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [values.password, values.email]);

  //Form Validation end
  let dispatch = useDispatch();
  let navigate = useNavigate();
  //Input change handler
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  //Input change handler end

  //Form submit Handler
  let submitHandler = async (e) => {
    e.preventDefault();
    dispatch(authActions.clearVendorInfo());
    try {
      setLoading(true);
      const res = await Authentication.loginHandler({
        email: values.email,
        phone: "1",
        password: values.password,
      });
      var decoded = jwt_decode(res.data.access);
      const userDetails = await Authentication.fetchUserDetails({
        user_id: decoded.user_id,
        access_token: res.data.access,
      });
      dispatch(authActions.login(res.data));
      dispatch(authActions.getVendorData(userDetails.data));
      navigate("/");
    } catch (error) {
      setLoading(false);
      const err = error.response.data.errors;
      console.log(error.response.data);
      toast.error(err, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  //Form submit Handler end

  //Password Toggle function
  let passwordToggleHandler = () => {
    setBoolen({ ...boolen, toggle: !boolen.toggle });
  };

  let passwordInputBlur = () => {
    if (values.password.trim().length === 0) {
      setBoolen({ ...boolen, passwordInvalid: true });
    } else {
      setBoolen({ ...boolen, passwordInvalid: false });
    }
  };

  //onBlur function handler end

  let type = boolen.toggle ? "name" : "password";
  return (
    <section className={styles.login}>
      <ToastContainer />
      <div className={styles.login__hero}>
        <Logo />
        <div className={styles.header}>
          <p>Login</p>
        </div>
      </div>
      <div className={styles.form}>
        <form onSubmit={submitHandler}>
          <EmailInput
            type="email"
            value={values.email}
            label="Email"
            onChange={handleInputChange}
            placeholder="Enter email"
            validEmail={validEmail}
            name="email"
          />
          <PasswordInput
            label="Password"
            passwordInvalid={boolen.passwordInvalid}
            value={values.password}
            onChange={handleInputChange}
            onBlur={passwordInputBlur}
            type={type}
            name="password"
            placeholder="Enter password"
            onClick={passwordToggleHandler}
            toggle={boolen.toggle}
          />
          <div className={styles.forgotPassword}>
            <span>
              <Link to="/forgotpassword">Forgot Password?</Link>
            </span>
          </div>
          {!loading && <Button disabled={!formIsValid}>Login</Button>}
          {loading && <Button disabled={formIsValid}>Loading...</Button>}
        </form>
        <div className={styles.login__options}>
          <p>
            Don’t have an account?{" "}
            <span>
              {" "}
              <Link to="/signup">Sign up</Link>
            </span>
          </p>
        </div>
      </div>
    </section>
  );
}

export default Login;
