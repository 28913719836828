import React, { useEffect, useState } from "react";
import styles from "./SubscriptionSummary.module.css";

function SubscriptionSummary(props) {
  let [number, setNumber] = useState(null);
  useEffect(() => {
    const initMonth = props?.data?.subscription?.end_date.charAt(5);
    const initMonth2 = props?.data?.subscription?.end_date[6];

    const finalMonth = props?.data?.subscription?.start_date[5];
    const finalMonth2 = props?.data?.subscription?.start_date[6];

    let num1 = parseInt(`${initMonth}${initMonth2}`);
    let num2 = parseInt(`${finalMonth}${finalMonth2}`);

    // declare a variable called difference
    let difference;

    difference = num1 - num2;

    // check if difference is negative(the subscriptions are not in the same year)
    if (difference < 0) {
      // set the initial month to zero since it is a new year and using the previous month will get you a negative result
      num2 = 0;
      difference = num1 - num2;
    }

    // assign the difference to the state
    setNumber(difference);
  }, [
    props?.data?.subscription?.end_date,
    props?.data?.subscription?.start_date,
  ]);

  return (
    <div>
      <div
        className={styles.wallet}
        style={{ background: props?.data?.is_active ? "#D7FBDB" : "#FFDFD7" }}
      >
        <div className={styles.wallet_ballance}>
          <h3 style={{ color: props?.data?.is_active ? "#008069" : "#FD4D1E" }}>
            ₦ {Number(props?.data?.subscription?.price || 0)?.toLocaleString()}
          </h3>
          <span>
            <p>No. of scan(s): </p>
            <p>{props?.data?.total_scans}</p>
          </span>
          <p>{number || 0} month subscription</p>
        </div>
        <div className={styles.wallet_ballance}>
          <div>
            <svg
              width="12"
              height="13"
              viewBox="0 0 12 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="6"
                cy="6.5"
                r="6"
                fill={props?.data?.is_active ? "#168912" : "#FD4D1E"}
              />
            </svg>
            <h3>{props?.data?.is_active ? "Active" : "Inactive"}</h3>
          </div>
          <p>{props?.data?.subscription?.end_date || "No subscription"}</p>
          <p>Expiring date</p>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionSummary;
