import React from "react";
import styles from "./Transactions.module.css";

function Transactions(props) {
  return (
    <div>
      {" "}
      <div className={styles.transactionList}>
        <div className={styles.transactionList_inner}>
          <div className={styles.transactionList_icon}>
            {props?.credit ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_3491_46069"
                  style={{ maskType: "alpha" }}
                  maskUnits="userSpaceOnUse"
                  x="4"
                  y="4"
                  width="16"
                  height="16"
                >
                  <path
                    d="M11.005 5.20898V16.379L6.12498 11.499C5.73498 11.109 5.09498 11.109 4.70498 11.499C4.31498 11.889 4.31498 12.519 4.70498 12.909L11.295 19.499C11.685 19.889 12.315 19.889 12.705 19.499L19.295 12.909C19.685 12.519 19.685 11.889 19.295 11.499C19.1081 11.3117 18.8545 11.2065 18.59 11.2065C18.3255 11.2065 18.0718 11.3117 17.885 11.499L13.005 16.379V5.20898C13.005 4.65898 12.555 4.20898 12.005 4.20898C11.455 4.20898 11.005 4.65898 11.005 5.20898Z"
                    fill="black"
                  />
                </mask>
                <g mask="url(#mask0_3491_46069)">
                  <rect width="24" height="24" fill="#008069" />
                </g>
              </svg>
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_3467_46255"
                  style={{ maskType: "alpha" }}
                  maskUnits="userSpaceOnUse"
                  x="4"
                  y="4"
                  width="16"
                  height="16"
                >
                  <path
                    d="M13 18.7915V7.62147L17.88 12.5015C18.27 12.8915 18.91 12.8915 19.3 12.5015C19.69 12.1115 19.69 11.4815 19.3 11.0915L12.71 4.50147C12.5231 4.31422 12.2695 4.20898 12.005 4.20898C11.7405 4.20898 11.4868 4.31422 11.3 4.50147L4.69997 11.0815C4.30997 11.4715 4.30997 12.1015 4.69997 12.4915C5.08997 12.8815 5.71997 12.8815 6.10997 12.4915L11 7.62147V18.7915C11 19.3415 11.45 19.7915 12 19.7915C12.55 19.7915 13 19.3415 13 18.7915Z"
                    fill="black"
                  />
                </mask>
                <g mask="url(#mask0_3467_46255)">
                  <rect width="24" height="24" fill="#FF1843" />
                </g>
              </svg>
            )}
          </div>
          <div>
            <h3>
              {props.product?.reference || props.product?.payment.reference}
            </h3>
            <p>{props.product?.created_at?.slice(0, 10)}</p>
          </div>
          <div className={styles.transactionList_amount}>
            <h5>
              ₦ {props.product?.amount || props.product?.payment.amount_paid}
            </h5>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_3467_46264"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="8"
                y="6"
                width="8"
                height="12"
              >
                <path
                  d="M9.00002 6.70978C8.61002 7.09978 8.61002 7.72978 9.00002 8.11978L12.88 11.9998L9.00002 15.8798C8.61002 16.2698 8.61002 16.8998 9.00002 17.2898C9.39002 17.6798 10.02 17.6798 10.41 17.2898L15 12.6998C15.39 12.3098 15.39 11.6798 15 11.2898L10.41 6.69978C10.03 6.31978 9.39002 6.31978 9.00002 6.70978Z"
                  fill="black"
                />
              </mask>
              <g mask="url(#mask0_3467_46264)">
                <rect width="24" height="24" fill="#008069" />
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Transactions;
