import { configureStore, createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";

const defaultAuthState = {
  signUpPersonalInfo: [],
  vendorSignInData: [],
  token: "",
  userLoggedIn: false,
  resetPasswordDetails: [],
  otp: "",
  subsciptionPlans: [],
  prevScan: [],
  wallet: [],
  diagnoseData: [],
  manualReportData: [],
};

let signUpSlice = createSlice({
  name: "sign up",
  initialState: defaultAuthState,
  reducers: {
    signUp(state, action) {
      const payload = action.payload;
      state.signUpPersonalInfo.push({
        name: payload.name,
        email: payload.email,
        password: payload.password,
        number: payload.number,
      });
    },

    clearSignUpInfo(state) {
      state.signUpPersonalInfo = [];
    },

    getVendorData(state, action) {
      const payload = action.payload;
      state.vendorSignInData.push({
        email: payload.email,
        fullname: payload.fullname,
        id: payload.id,
        phone: payload.phone,
        role: payload.role,
        balance: payload.wallet.balance,
        funding_account_number: payload.wallet.funding_account.account_number,
        funding_account_name: payload.wallet.funding_account.account_name,
        funding_bank_name: payload.wallet.funding_account.bank_name,
        reference: payload.wallet.reference,
        dongles: payload.dongles,
        status: payload.wallet.status,
      });
    },

    clearVendorInfo(state) {
      state.vendorSignInData = [];
    },

    getDiagnosisData(state, action) {
      const payload = action.payload;
      state.diagnoseData.push(payload);
    },

    getManualDiagnosisData(state, action) {
      const payload = action.payload;
      state.manualReportData.push(payload);
    },

    clearManualDiagData(state) {
      state.manualReportData = [];
    },

    clearDiagData(state) {
      state.diagnoseData = [];
    },

    login(state, action) {
      const payload = action.payload;
      state.token = payload.access;
      if (state.token !== "") {
        state.userLoggedIn = true;
      } else {
        state.userLoggedIn = false;
      }
    },

    logOut(state) {
      state.userLoggedIn = false;
      state.subsciptionPlans = [];
      state.resetPasswordDetails = [];
      state.token = "";
    },

    resetPasswordDetails(state, action) {
      const payload = action.payload;
      state.resetPasswordDetails.push({
        email: payload.email,
        phoneNumber: payload.phone_number,
      });
    },

    resetOTPToDefault(state) {
      state.resetPasswordDetails = [];
    },

    otpHandler(state, action) {
      const payload = action.payload;
      state.otp = payload;
    },

    resetOtp(state) {
      state.otp = "";
    },
    subscriptionPlansHandler(state, payload) {
      state.subsciptionPlans.splice(0, 1, payload.payload);
    },

    previousScanHandler(state, payload) {
      state.prevScan.splice(0, 1, payload.payload);
    },

    walletHandler(state, payload) {
      state.wallet.splice(0, 1, payload.payload);
    },
  },
});

const persistConfig = {
  key: "storeInfo",
  storage,
};

let persistedReducer = persistReducer(persistConfig, signUpSlice.reducer);

const store = configureStore({
  reducer: {
    auth: persistedReducer,
  },
  middleware: [thunk],
});

export const authActions = signUpSlice.actions;
export default store;
