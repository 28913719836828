import axios from "axios";

const url = process.env.REACT_APP__BASE_URL;

const url2 = process.env.REACT_APP__BASE_URL2;

const BASE_URL = `https://api.airtable.com/v0`;
const KEY = `app68nQJRlP24e7PW`;
const TOKEN = `patzMFsm5oLbjVcXr.894019c33e2bbec78545a13be5a6fc451666c856f15f10b3c74eb0f70dc5e1ff`;

const productID = "c5156689-8aeb-4951-9c31-06753b71cb4d";
const apiKey = "0f7706e1-450b-4913-9e7b-755a4db41441";
const phoneID = "29115";
const sendMessageBaseUrl = "https://api.maytapi.com/api";

const Mail_UserID = `Z5ZG0Ua9CXbWizkTO`;

const Service_ID = `service_04mgbhw`;

const template_id = `template_fb93vwr`;

const VendorApi = {
  vendorDashboardData: (payload) => {
    const res = axios({
      url: `${url}/vendor/dashboard/`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  getLastScan: (payload) => {
    const res = axios({
      url: `${url}/vendor/temporary-scans/`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  getLastScanDetails: (payload) => {
    const res = axios({
      url: `${url}/vendor/${payload.id}/temporary-scan/details/`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload.access}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  vendorCustomersData: (payload, page) => {
    const res = axios({
      url: `${url}/vendor/customers/?page=${page || 1}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  postNewCustomer: (payload) => {
    const response = axios({
      url: `${url}/vendor/register/customer/`,
      method: "POST",
      data: JSON.stringify({
        phone: payload.phone,
        email: payload.email,
        fullname: payload.fullname,
        plate_no: payload.plate_no,
        subscription_plan: payload.subscription_plan,
        payment_method: "CARD",
      }),
      headers: {
        Authorization: `Bearer ${payload.access}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  regCollectorHandler: (payload) => {
    const res = axios({
      url: `${url2}/main/app/reg/collector`,
      method: "POST",
      data: JSON.stringify({
        email: payload.email,
        name: payload.name,
        phone_no: payload.phone_no,
        plate_no: payload.plate_no,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  registerUserToPremiumPapers: (payload) => {
    const res = axios({
      url: `${url2}/premiumpapers/app/register`,
      method: "POST",
      data: JSON.stringify({
        name: payload.name,
        plate_no: payload.plate_no,
        phone_no: payload.phone_no,
        email: payload.email,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  premiumPapersHandler: (payload) => {
    const res = axios({
      url: `${url2}/python/premiumpapers/app/register`,
      method: "POST",
      data: JSON.stringify({
        name: payload.name,
        plate_no: payload.plate_no,
        phone_no: payload.phone_no,
        email: payload.email,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  postNewFreeCustomer: (payload) => {
    const response = axios({
      url: `${url}/vendor/register/customer/withoutsub/`,
      method: "POST",
      data: JSON.stringify({
        phone: payload.phone,
        email: payload.email,
        fullname: payload.fullname,
        plate_no: payload.plate_no,
      }),
      headers: {
        Authorization: `Bearer ${payload.access}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  updateCustomerInfo: (payload) => {
    const response = axios({
      url: `${url}/vendor/${payload.customer_id}/update/customer/`,
      method: "POST",
      data: JSON.stringify({
        vehicle_year: payload.year,
        vehicle_make: payload.make,
        vehicle_model: payload.model,
        vehicle_vin: payload.vin,
        vehicle_trim: "string",
      }),
      headers: {
        Authorization: `Bearer ${payload.access}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  sendTicket: (payload) => {
    const response = axios({
      url: `https://api.emailjs.com/api/v1.0/email/send`,
      method: "POST",
      data: JSON.stringify({
        service_id: Service_ID,
        template_id: template_id,
        user_id: Mail_UserID,
        template_params: {
          to_name: payload.to_name,
          from_name: payload.from_name,
          message: payload.message,
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  verifyCustomer: (payload) => {
    const response = axios({
      url: `${url}/vendor/verify/`,
      method: "POST",
      data: JSON.stringify({
        plate_no: payload.plate_no,
      }),
      headers: {
        Authorization: `Bearer ${payload.access}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  customerSubscription: (payload) => {
    const response = axios({
      url: `${url}/vendor/subscribe/customer/`,
      method: "POST",
      data: JSON.stringify({
        customer: payload.customer,
        subscription_plan: payload.subscription_plan,
        payment_method: payload.payment_method,
      }),
      headers: {
        Authorization: `Bearer ${payload.access}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  subscriptionPlans: (payload) => {
    const res = axios({
      url: `${url}/vendor/subscription/plans/`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  vendorScan: (payload) => {
    const response = axios({
      url: `${url}/vendor/scan/`,
      method: "POST",
      data: JSON.stringify({
        customer: payload.customer,
        device: payload.device,
      }),
      headers: {
        Authorization: `Bearer ${payload.access}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  vendorNoSmsScan: (payload) => {
    const response = axios({
      url: `${url}/vendor/scan/no-sms/`,
      method: "POST",
      data: JSON.stringify({
        customer: payload.customer,
        device: payload.device,
      }),
      headers: {
        Authorization: `Bearer ${payload.access}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  vendorNoSmsScanNoValidation: (payload) => {
    const response = axios({
      url: `${url}/vendor/scan/no-sms/no-comparison/`,
      method: "POST",
      data: JSON.stringify({
        customer: payload.customer,
        device: payload.device,
      }),
      headers: {
        Authorization: `Bearer ${payload.access}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  vendorFreeNoSmsScan: (payload) => {
    const response = axios({
      url: `${url}/vendor/free-scan/no-sms/`,
      method: "POST",
      data: JSON.stringify({
        customer: payload.customer,
        device: payload.device,
      }),
      headers: {
        Authorization: `Bearer ${payload.access}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  vendorFreeNoSmsScanNoValidation: (payload) => {
    const response = axios({
      url: `${url}/vendor/free-scan/no-sms/no-comparison/`,
      method: "POST",
      data: JSON.stringify({
        customer: payload.customer,
        device: payload.device,
      }),
      headers: {
        Authorization: `Bearer ${payload.access}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  vendorFreeScan: (payload) => {
    const response = axios({
      url: `${url}/vendor/free-scan/`,
      method: "POST",
      data: JSON.stringify({
        customer: payload.customer,
        device: payload.device,
      }),
      headers: {
        Authorization: `Bearer ${payload.access}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  WalletDataHandler: (payload) => {
    const res = axios({
      url: `${url}/wallet/`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  ReconciliationHandler: (payload) => {
    const res = axios({
      url: `${url}/wallet/paga/payment/reconciliation`,
      method: "POST",
      data: JSON.stringify({
        account_number: payload.accountNumber,
      }),
      headers: {
        Authorization: `Bearer ${payload.token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  getVehicleScans: (payload, page_size) => {
    const res = axios({
      url: `${url}/vendor/scans/?page_size=${page_size || 10}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  getCustomerDetails: (payload) => {
    const res = axios({
      url: `${url}/vendor/${payload.id}/customer/`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload.token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  getVendorDongles: (payload) => {
    const res = axios({
      url: `${url}/vendor/dongles/`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  getScanDetails: (payload) => {
    const res = axios({
      url: `${url}/vendor/${payload.id}/scan/`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  vinDecoder: (payload) => {
    const res = axios({
      url: `${url}/vendor/vindecoder/${payload.vin}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload.token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  fetchCustomerDetails: (payload) => {
    const res = axios({
      url: `https://api.paddycover.com/v1/vehicle/details/${payload.plate_number}`,
      method: "GET",
      headers: {
        // Authorization: `Bearer ${payload.token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  sendWhatsappMessage: (payload) => {
    const res = axios({
      url: `${sendMessageBaseUrl}/${productID}/${phoneID}/sendMessage`,
      method: "POST",
      data: JSON.stringify({
        to_number: payload.phoneNumber,
        type: "text",
        message: payload.message,
      }),
      headers: {
        "Content-Type": "application/json",
        "x-maytapi-key": apiKey,
      },
    });
    return res;
  },

  sendCustomerData: (payload) => {
    const res = axios({
      url: `${BASE_URL}/${KEY}/customer`,
      method: "POST",
      data: JSON.stringify({
        fields: payload,
      }),
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  getCustomerYmmData: () => {
    const res = axios({
      url: `${BASE_URL}/${KEY}/customer`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  sendMessage: (phoneNumber, message) => {
    const res = axios({
      url: "https://api.ng.termii.com/api/sms/send",
      method: "POST",
      data: JSON.stringify({
        api_key:
          "TL7mBuhf7cuNMDho0P1Y06d0NuvUFnASssd16JxIk8LAZjX91hhE21haLuaTdg",
        to: phoneNumber,
        from: "N-Alert",
        sms: message,
        type: "plain",
        channel: "generic",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  },

  vendorSignupHandler: (payload) =>
    axios({
      url: `${url}/auth/users/signup/`,
      method: "POST",
      data: JSON.stringify({
        email: payload.email,
        phone: payload.phone,
        password: payload.password,
        fullname: payload.fullname,
        vehicle_data: {
          plate_number: payload.vehicle_data.plate_number,
          vehicle_year: payload.vehicle_data.vehicle_year,
          vehicle_make: payload.vehicle_data.vehicle_make,
          vehicle_model: payload.vehicle_data.vehicle_model,
          vehicle_trim: payload.vehicle_data.vehicle_trim,
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }),

  setengineHandler: (payload) =>
    axios({
      url: `${url2}/main/app/set/engine?plate_no=${payload.plate_no}&make=${payload.make}&model=${payload.model}&year=${payload.year}&v_type=${payload.v_type}`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }),
};

export default VendorApi;
