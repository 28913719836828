import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./BottomNav.module.css";

function BottomNav(props) {
  return (
    <section className={styles.lowerNavBar}>
      <div className={styles.lowerNavBar_inner}>
        <div>
          <NavLink
            to="/"
            className={(navData) =>
              navData.isActive ? `${styles.active}` : ""
            }
          >
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.1667 14.6662H3.83333C3.65652 14.6662 3.48695 14.596 3.36193 14.471C3.2369 14.346 3.16667 14.1764 3.16667 13.9996V8.66625H1.83333L8.028 2.47092C8.08992 2.40893 8.16344 2.35976 8.24437 2.32621C8.32531 2.29266 8.41206 2.27539 8.49967 2.27539C8.58728 2.27539 8.67403 2.29266 8.75496 2.32621C8.83589 2.35976 8.90942 2.40893 8.97133 2.47092L15.1667 8.66625H13.8333V13.9996C13.8333 14.1764 13.7631 14.346 13.6381 14.471C13.513 14.596 13.3435 14.6662 13.1667 14.6662ZM7.16667 9.99958H9.83333V13.3329H12.5V7.88492L8.5 3.88492L4.5 7.88492V13.3329H7.16667V9.99958Z"
              />
            </svg>
            <p>Home</p>
          </NavLink>
        </div>

        <div>
          <NavLink
            to="/smart-scan"
            className={(navData) =>
              navData.isActive ? `${styles.active}` : ""
            }
          >
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="#BABABA"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.33329 2.66667V6H3.66663V3.33333H6.33329V2H2.99996C2.82315 2 2.65358 2.07024 2.52855 2.19526C2.40353 2.32029 2.33329 2.48986 2.33329 2.66667ZM14.3333 6V2.66667C14.3333 2.48986 14.2631 2.32029 14.138 2.19526C14.013 2.07024 13.8434 2 13.6666 2H10.3333V3.33333H13V6H14.3333ZM13 12.6667H10.3333V14H13.6666C13.8434 14 14.013 13.9298 14.138 13.8047C14.2631 13.6797 14.3333 13.5101 14.3333 13.3333V10H13V12.6667ZM6.33329 14V12.6667H3.66663V10H2.33329V13.3333C2.33329 13.5101 2.40353 13.6797 2.52855 13.8047C2.65358 13.9298 2.82315 14 2.99996 14H6.33329ZM1.66663 7.33333H15V8.66667H1.66663V7.33333Z"
                fill="#BABABA"
              />
            </svg>

            <p>Smartscan</p>
          </NavLink>
        </div>

        <div>
          <NavLink
            to="/customers"
            className={(navData) =>
              navData.isActive ? `${styles.active}` : ""
            }
          >
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.66666 4C8.1722 4 7.68886 4.14662 7.27773 4.42133C6.86661 4.69603 6.54618 5.08648 6.35696 5.54329C6.16774 6.00011 6.11823 6.50277 6.21469 6.98773C6.31116 7.47268 6.54926 7.91814 6.89889 8.26777C7.24852 8.6174 7.69398 8.8555 8.17893 8.95196C8.66388 9.04843 9.16655 8.99892 9.62337 8.8097C10.0802 8.62048 10.4706 8.30005 10.7453 7.88893C11.02 7.4778 11.1667 6.99445 11.1667 6.5C11.1667 5.83696 10.9033 5.20107 10.4344 4.73223C9.96558 4.26339 9.3297 4 8.66666 4ZM8.66666 8C8.36999 8 8.07998 7.91203 7.8333 7.7472C7.58663 7.58238 7.39437 7.34811 7.28084 7.07403C7.16731 6.79994 7.1376 6.49834 7.19548 6.20736C7.25336 5.91639 7.39622 5.64912 7.606 5.43934C7.81578 5.22956 8.08305 5.0867 8.37402 5.02882C8.66499 4.97094 8.96659 5.00065 9.24068 5.11418C9.51477 5.22771 9.74904 5.41997 9.91386 5.66664C10.0787 5.91332 10.1667 6.20333 10.1667 6.5C10.1663 6.8977 10.0081 7.279 9.72688 7.56022C9.44566 7.84144 9.06436 7.9996 8.66666 8Z"
                fill="#BABABA"
              />
              <path
                d="M8.66666 1C7.28219 1 5.92881 1.41054 4.77767 2.17971C3.62652 2.94888 2.72932 4.04213 2.1995 5.32122C1.66969 6.6003 1.53107 8.00776 1.80116 9.36563C2.07126 10.7235 2.73794 11.9708 3.71691 12.9497C4.69588 13.9287 5.94316 14.5954 7.30103 14.8655C8.65889 15.1356 10.0664 14.997 11.3454 14.4672C12.6245 13.9373 13.7178 13.0401 14.4869 11.889C15.2561 10.7378 15.6667 9.38447 15.6667 8C15.6645 6.14413 14.9264 4.36489 13.6141 3.05259C12.3018 1.7403 10.5225 1.00212 8.66666 1ZM5.66666 13.1885V12.5C5.66706 12.1023 5.82522 11.721 6.10644 11.4398C6.38766 11.1586 6.76896 11.0004 7.16666 11H10.1667C10.5644 11.0004 10.9457 11.1586 11.2269 11.4398C11.5081 11.721 11.6663 12.1023 11.6667 12.5V13.1885C10.7562 13.7201 9.72092 14.0002 8.66666 14.0002C7.6124 14.0002 6.57708 13.7201 5.66666 13.1885ZM12.6627 12.463C12.6527 11.8072 12.3855 11.1815 11.9186 10.7208C11.4517 10.2601 10.8226 10.0012 10.1667 10H7.16666C6.51075 10.0012 5.88158 10.2601 5.4147 10.7208C4.94783 11.1815 4.68062 11.8072 4.67066 12.463C3.76394 11.6534 3.12452 10.5874 2.83708 9.40633C2.54963 8.22523 2.62771 6.98467 3.06098 5.84893C3.49425 4.71319 4.26227 3.73583 5.26335 3.04628C6.26442 2.35673 7.45133 1.98751 8.66691 1.98751C9.88249 1.98751 11.0694 2.35673 12.0705 3.04628C13.0715 3.73583 13.8396 4.71319 14.2728 5.84893C14.7061 6.98467 14.7842 8.22523 14.4967 9.40633C14.2093 10.5874 13.5699 11.6534 12.6632 12.463H12.6627Z"
                fill="#BABABA"
              />
            </svg>

            <p>Customers</p>
          </NavLink>
        </div>

        <div>
          <NavLink
            to="/account"
            className={(navData) =>
              navData.isActive ? `${styles.active}` : ""
            }
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.3116 3.72059C15.2421 3.65045 15.1594 3.59482 15.0682 3.55695C14.9769 3.51908 14.8791 3.49972 14.7804 3.5H14.25V2.25C14.2498 2.05116 14.1707 1.86052 14.0301 1.71992C13.8895 1.57931 13.6988 1.50022 13.5 1.5H2.70709C2.12174 1.50001 1.56036 1.73254 1.14645 2.14645C0.732543 2.56036 0.500008 3.12174 0.5 3.70709V12.2929C0.500008 12.8783 0.732543 13.4396 1.14645 13.8535C1.56036 14.2675 2.12174 14.5 2.70709 14.5H14.7508C14.9492 14.4996 15.1394 14.4208 15.2799 14.2808C15.4204 14.1407 15.4998 13.9508 15.5008 13.7524L15.5303 4.25256C15.531 4.15382 15.5119 4.05594 15.4744 3.96462C15.4369 3.87329 15.3815 3.79034 15.3116 3.72059ZM14.5017 13.5H2.70709C2.38695 13.5 2.07992 13.3728 1.85355 13.1465C1.62718 12.9201 1.5 12.613 1.5 12.2929V3.70709C1.5 3.38695 1.62718 3.07992 1.85355 2.85355C2.07992 2.62718 2.38695 2.5 2.70709 2.5H13.25V3.5H2.75V4.5H14.5296L14.5017 13.5Z"
                fill="#BABABA"
              />
              <path d="M12.25 8.25H13.25V9.25H12.25V8.25Z" fill="#BABABA" />
            </svg>
            <p>Wallet</p>
          </NavLink>
        </div>

        {/* <div>
          <NavLink
            to="/papers"
            className={(navData) =>
              navData.isActive ? `${styles.active}` : ""
            }
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_174_628)">
                <path d="M6.66663 3.55566H10.6666V4.44455H6.66663V3.55566Z" />
                <path d="M6.66663 5.3335H10.6666V6.22238H6.66663V5.3335Z" />
                <path d="M6.66663 7.11133H10.6666V8.00022H6.66663V7.11133Z" />
                <path d="M6.66663 8.88867H10.6666V9.77756H6.66663V8.88867Z" />
                <path d="M6.66663 10.6665H10.6666V11.5554H6.66663V10.6665Z" />
                <path d="M4.88892 3.55566H5.7778V4.44455H4.88892V3.55566Z" />
                <path d="M4.88892 5.3335H5.7778V6.22238H4.88892V5.3335Z" />
                <path d="M4.88892 7.11133H5.7778V8.00022H4.88892V7.11133Z" />
                <path d="M4.88892 8.88867H5.7778V9.77756H4.88892V8.88867Z" />
                <path d="M4.88892 10.6665H5.7778V11.5554H4.88892V10.6665Z" />
                <path d="M12.4444 0.888672H3.55551C3.31977 0.888672 3.09367 0.982322 2.92698 1.14902C2.76028 1.31572 2.66663 1.54181 2.66663 1.77756V14.222C2.66663 14.4578 2.76028 14.6838 2.92698 14.8505C3.09367 15.0172 3.31977 15.1109 3.55551 15.1109H12.4444C12.6802 15.1109 12.9062 15.0172 13.0729 14.8505C13.2396 14.6838 13.3333 14.4578 13.3333 14.222V1.77756C13.3333 1.54181 13.2396 1.31572 13.0729 1.14902C12.9062 0.982322 12.6802 0.888672 12.4444 0.888672ZM12.4444 14.222H3.55551V1.77756H12.4444V14.222Z" />
              </g>
              <defs>
                <clipPath id="clip0_174_628">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <p>Papers</p>
          </NavLink>
        </div> */}
      </div>
    </section>
  );
}

export default BottomNav;
