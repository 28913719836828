import React from "react";

function DeviceModal(props) {
  return (
    <div
      onClick={props.onCloseModal}
      style={{
        position: "fixed",
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        onClick={props.onCloseModal}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          width: "184px",
          height: "300px",
          padding: "12px 16px 12px 0px",
          gap: "12px",
          backgroundColor: "#FAFAFA",
          border: "0.5px solid rgba(0, 0, 0, 0.1)",
          borderRadius: "12px",
          marginTop: "200px",
          marginRight: "130px",
          overflow: "scroll",
        }}
      >
        <h3
          style={{
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "20px",
            marginLeft: "8px",
          }}
        >
          Dongle Devices
        </h3>
        {props.children}
      </div>
    </div>
  );
}

export default DeviceModal;
