import axios from "axios";

const url = process.env.REACT_APP__BASE_URL;
const Authentication = {
  loginHandler: (payload) => {
    const response = axios({
      url: `${url}/auth/login/`,
      method: "POST",
      data: JSON.stringify({
        email: payload.email,
        phone: payload.phone,
        password: payload.password,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  fetchUserDetails: (payload) => {
    const response = axios({
      url: `${url}/auth/users/${payload.user_id}/`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${payload.access_token}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  },
  signupHandler: (payload) =>
    axios({
      url: `${url}/auth/users/vendor/signup/`,
      method: "POST",
      data: JSON.stringify({
        email: payload.email,
        phone: payload.phone,
        password: payload.password,
        fullname: payload.fullname,
        vendor_data: {
          workshop_name: payload.vendor_data.workshop_name,
          workshop_address: payload.vendor_data.workshop_address,
          workshop_reg_number: payload.vendor_data.workshop_reg_number,
          identification_type: payload.vendor_data.identification_type,
          identification_other: payload.vendor_data.identification_other,
          identification_number: payload.vendor_data.identification_number,
          is_active: payload.vendor_data.is_active,
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }),

  validateAccount: (payload) => {
    const response = axios({
      url: `${url}/auth/users/validate-account/`,
      method: "POST",
      data: JSON.stringify({
        phone_number: payload.phone_number,
        email: payload.email,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  resetPasswordHandler: (payload) => {
    const response = axios({
      url: `${url}/auth/users/reset-password/`,
      method: "POST",
      data: JSON.stringify({
        phone_number: payload.phone_number,
        email: payload.email,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  confirmOtp: (payload) => {
    const response = axios({
      url: `${url}/auth/users/verify-token/`,
      method: "POST",
      data: JSON.stringify({
        token: payload.token,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },

  createPassword: (payload) => {
    const response = axios({
      url: `${url}/auth/users/create-password/`,
      method: "POST",
      data: JSON.stringify({
        token: payload.token,
        password: payload.password,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },
};
export default Authentication;
