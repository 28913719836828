import React, { useState, useEffect } from "react";
import styles from "./ForgotPasswordForm2.module.css";
import { Link } from "react-router-dom";
import OTPInput from "otp-input-react";
import { useNavigate } from "react-router";
import Logo from "../../../components/logo/Logo";
import Button from "../../../components/button/Button";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Authentication from "../../../utils/api/Authentication";
import { authActions } from "../../../utils/store/redux-store/Index";

function ForgotPasswordForm2() {
  const [OTP, setOTP] = useState("");
  const [formIsValid, setFormIsValid] = useState(false);
  const [countDown, setCountDown] = useState(0);
  const [runTimer, setRunTimer] = useState(true);

  let navigate = useNavigate();
  let dispatch = useDispatch();

  const resetPassword = useSelector((state) => state.auth.resetPasswordDetails);

  useEffect(() => {
    let timerId;
    if (runTimer) {
      setCountDown(300);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      console.log("expired");
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);

  useEffect(() => {
    if (OTP.trim().length >= 4 && countDown !== 0) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [OTP, countDown]);
  let submitHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await Authentication.confirmOtp({
        token: OTP,
      });
      dispatch(authActions.resetOtp());
      dispatch(authActions.otpHandler(OTP));
      navigate("/forgotpassword/form2");
      return res;
    } catch (error) {
      toast.error(error.response.data.errors.message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  let resendTimerHandler = async () => {
    try {
      const res = await Authentication.resetPasswordHandler({
        email: resetPassword[0]?.email,
        phone_number: resetPassword[0]?.phoneNumber,
      });
      setOTP("");
      setRunTimer(false);
      setCountDown(300);
      setRunTimer(true);
      return res;
    } catch (error) {
      toast.error(error.response.data.errors.message, {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const seconds = String(countDown % 60).padStart(2, 0);
  const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);
  return (
    <section className={styles.login}>
      <ToastContainer />
      <div className={styles.login__hero}>
        <Logo />
        <div className={styles.header}>
          <p>Forgot Password</p>
        </div>
        <div className={styles.login__hero__description}>
          <p>We will send the recovery code to your phone number.</p>
          <p>Enter your phone number you registered with on this Motormata.</p>
        </div>
      </div>
      <div className={styles.form}>
        <form onSubmit={submitHandler}>
          <div className={styles.form__inner}>
            <label htmlFor="code">Enter the code you received</label>
            <OTPInput
              value={OTP}
              onChange={setOTP}
              otpType="number"
              inputStyles={{
                width: "3.1rem",
                height: "2.9rem",
                margin: "0 0.5rem",
                borderRadius: 4,
              }}
            />
          </div>
          <div className={styles.timer}>
            <p>
              {minutes}:{seconds}
            </p>
          </div>
          <Button disabled={!formIsValid}>Send</Button>
        </form>
        <div className={styles.login__options}>
          <p>
            Didn’t receive the code?{" "}
            <span onClick={resendTimerHandler}>
              {" "}
              <Link to="">Resend</Link>
            </span>
          </p>
        </div>
      </div>
    </section>
  );
}

export default ForgotPasswordForm2;
