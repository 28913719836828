import React from "react";
import styles from "./VehicleInformation.module.css";

function VehicleInformation(props) {
  return (
    <div>
      <div className={styles.vehicleDetails_2}>
        <div className={styles.vehicleDetails_2_head}>
          <p>Vehicle Infomation</p>
        </div>
        <div className={styles.VehicleInformation}>
          <div>
            <p>Owner’s Name: </p>
            <p>Engine Type:</p>
            <p>Vehicle Body:</p>
          </div>
          <div className={styles.details}>
            <p>
              {props.data?.user.fullname || props.data3?.customer.user.fullname}
            </p>
            <p>
              {props.data2?.scan_data.vehicle_data.vehicle_engine ||
                props.data3?.scan_data.vehicle_data.vehicle_engine}
            </p>
            <p>
              {props.data2?.scan_data.vehicle_data.vehicle_body ||
                props.data3?.scan_data.vehicle_data.vehicle_body}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VehicleInformation;
