import React, { useEffect, useState } from "react";
import styles from "./customerList.module.css";

function VehicleTransactions(props) {
  const [time, setTime] = useState("");
  useEffect(() => {
    const update = props.data?.created_at;
    let newUpdate = update?.slice(0, 16);
    setTime(newUpdate);
  }, [props.data?.created_at]);
  return (
    <div>
      {props.diag && (
        <div className={styles.workShop_list2}>
          <div className={styles.pDetails}>
            <p style={{ color: props.color }} className={styles.pDetails_name}>
              {props.title}
            </p>
            <h5>
              {props.record?.fields?.brand ||
                props.data?.scan_data?.vehicle_data.vehicle_make}{" "}
              {props.record?.fields?.model ||
                props.data?.scan_data?.vehicle_data.vehicle_model}
            </h5>
            <p>{props.data?.customer?.plate_number.toUpperCase()}</p>
          </div>
          <div className={styles.contact}>
            <p className={styles.contact_id}>{time}</p>
            <h5>₦ {Number(props.data?.price).toLocaleString()}</h5>
            <p>{props.data?.reference}</p>
          </div>
        </div>
      )}

      {props.debit && (
        <div className={styles.workShop_list2}>
          <div className={styles.pDetails}>
            <p style={{ color: props.color }} className={styles.pDetails_name}>
              {props.title}
            </p>
            <h5>{props.data?.reference}</h5>
            <p>Transaction: {props.data?.transaction_type}</p>
          </div>
          <div className={styles.contact}>
            <p className={styles.contact_id}>{time}</p>
            <h5>₦ {Number(props.data?.amount).toLocaleString()}</h5>
            <p>Amount</p>
          </div>
        </div>
      )}

      {props.credit && (
        <div className={styles.workShop_list2}>
          <div className={styles.pDetails}>
            <p style={{ color: props.color }} className={styles.pDetails_name}>
              {props.title}
            </p>
            <h5>{props.data?.reference}</h5>
            <p>Transaction ID.</p>
          </div>
          <div className={styles.contact}>
            <p className={styles.contact_id}>{time}</p>
            <h5>₦ {Number(props.data?.amount).toLocaleString()}</h5>
            <p>Amount</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default VehicleTransactions;
