import React, { useState, useEffect } from "react";
import styles from "./SignUp.module.css";
import Button from "../../components/button/Button";
import Input from "../../components/inputs/Input";
import Logo from "../../components/logo/Logo";
import { useNavigate } from "react-router";
import Authentication from "../../utils/api/Authentication";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../utils/store/redux-store/Index";
import Modal from "../../components/UI/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const initialValues = {
  name: "",
  location: "",
  number: "",
  id: "",
  idOptions: [
    "Select your identity type",
    "National Identification Number (NIN)",
  ],
};
const initialBoolenValues = {
  nameInvalid: false,
  locationInvalid: false,
  idInvalid: false,
  numberInvalid: false,
  formIsValid: false,
};

function RegisterWorkshop() {
  const [values, setValues] = useState(initialValues);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [boolen, setBoolen] = useState(initialBoolenValues);

  const signUpPersonalInfo = useSelector(
    (data) => data.auth.signUpPersonalInfo
  );

  //Input state handler//
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  //Input state handler end//
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (values.location.trim().length >= 1) {
      setBoolen({ formIsValid: true });
    } else {
      setBoolen({ formIsValid: false });
    }
  }, [values.location]);

  //Form Submission//
  let submitHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await Authentication.signupHandler({
        email: signUpPersonalInfo[0].email,
        phone: signUpPersonalInfo[0].number,
        password: signUpPersonalInfo[0].password,
        fullname: signUpPersonalInfo[0].name,
        vendor_data: {
          workshop_name: signUpPersonalInfo[0].name,
          workshop_address: values.location,
          workshop_reg_number: "123456789099",
          identification_type: "NATIONAL_ID",
          identification_other: "National Identification Number (NIN)",
          identification_number: "00011111111",
          is_active: true,
        },
      });
      setModal(true);
      setLoading(false);
      return res;
    } catch (err) {
      setLoading(false);
      const errors = [];
      if (err.response.data.errors.message) {
        errors.push(err.response.data.errors.message);
      } else if (err.response.data.errors) {
        const errorMessages = err.response.data.errors;
        Object.keys(errorMessages).forEach((key) => {
          errorMessages[key].forEach((errorMessage) => {
            errors.push(errorMessage);
          });
        });
      }
      toast.error(errors[0], {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  //Form Submission end//

  //Input Blur Handler Handler//
  const workShopLocationInputBlur = () => {
    if (values.location.trim().length === 0) {
      setBoolen({ ...boolen, locationInvalid: true });
    } else {
      setBoolen({ ...boolen, locationInvalid: false });
    }
  };

  const nameInputBlur = () => {
    if (values.name.trim().length === 0) {
      setBoolen({ ...boolen, nameInvalid: true });
    } else {
      setBoolen({ ...boolen, nameInvalid: false });
    }
  };

  const proceedHandlerFinal = () => {
    setModal(false);
    dispatch(authActions.clearSignUpInfo());
    navigate("/login");
  };
  //Input Blur Handler Handler end//

  return (
    <section className={styles.login}>
      <ToastContainer />
      {modal && (
        <Modal
          title="Submitted Successfully"
          message="Thank you for using SmartVendor. Your registration would be approved soon. We would need to inspect your workshop for final verification. Expect our team members for an inspection within the next 48hrs"
          accept="Proceed to login"
          back=""
          confirm={proceedHandlerFinal}
          success="true"
        />
      )}
      <div className={styles.topProgress}>
        <div></div>
        <div className={styles.topProgress_active}></div>
      </div>
      <div className={styles.login__hero}>
        <Logo />
        <div className={styles.header}>
          <p>Register your workshop</p>
        </div>
      </div>
      <div className={styles.form}>
        <form onSubmit={submitHandler}>
          <Input
            label="Name of workshop "
            onBlur={nameInputBlur}
            invalid={boolen.nameInvalid}
            onChange={handleInputChange}
            value={signUpPersonalInfo[0].name}
            type="name"
            placeholder="Enter the name of your workshop"
            name="name"
          />
          <Input
            label="Workshop Location"
            onBlur={workShopLocationInputBlur}
            invalid={boolen.locationInvalid}
            onChange={handleInputChange}
            value={values.location}
            type="text"
            placeholder="Enter or use location"
            name="location"
          />

          <div className={styles.button}>
            {!loading && <Button disabled={!boolen.formIsValid}>Submit</Button>}
            {loading && (
              <Button disabled={boolen.formIsValid}>Loading...</Button>
            )}
          </div>
        </form>
      </div>
    </section>
  );
}

export default RegisterWorkshop;
