import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./SignUp.module.css";
import Button from "../../components/button/Button";
import Input from "../../components/inputs/Input";
import Logo from "../../components/logo/Logo";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router";
import PasswordInput from "../../components/inputs/PasswordInput";
import Authentication from "../../utils/api/Authentication";
import EmailInput from "../../components/inputs/EmailInput";
import { useDispatch } from "react-redux";
import { authActions } from "../../utils/store/redux-store/Index";

const initialValues = {
  name: "",
  password: "",
  email: "",
  number: "",
  confirmPassword: "",
};
const initialBoolenValues = {
  toggle: false,
  toggle2: false,
  nameInvalid: false,
  passwordInvalid: false,
  passwordInvalid2: false,
  phoneNumberInvalid: false,
  formIsValid: false,
};

function Signup() {
  const [values, setValues] = useState(initialValues);
  const [boolen, setBoolen] = useState(initialBoolenValues);
  const [validEmail, setValid] = useState(false);
  const [loading, setLoading] = useState(false);

  //Input state handler//
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  //Input state handler end//
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // dispatch(authActions.clearSignUpInfo());

  useEffect(() => {
    if (
      values.email.trim().length >= 1 &&
      values.email.includes("@") &&
      values.email.includes(".com")
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [values.email]);

  useEffect(() => {
    if (
      values.password.trim().length >= 8 &&
      values.confirmPassword.trim().length >= 8 &&
      values.name.trim().length >= 1 &&
      values.number.trim().length >= 11 &&
      values.password === values.confirmPassword
    ) {
      setBoolen({ formIsValid: true });
    } else {
      setBoolen({ formIsValid: false });
    }
  }, [values.confirmPassword, values.password, values.number, values.name]);

  //Form Submission//
  let submitHandler = async (e) => {
    e.preventDefault();
    dispatch(authActions.clearSignUpInfo());
    try {
      setLoading(true);
      const res = await Authentication.validateAccount({
        email: values.email,
        phone_number: values.number,
      });
      dispatch(
        authActions.signUp({
          name: values.name,
          email: values.email,
          password: values.password,
          number: values.number,
        })
      );
      navigate("/signup/register");
      setLoading(false);
      return res;
    } catch (err) {
      setLoading(false);
      const errors = [];
      if (err.response.data.errors.message) {
        errors.push(err.response.data.errors.message);
      } else if (err.response.data.errors) {
        const errorMessages = err.response.data.errors;
        Object.keys(errorMessages).forEach((key) => {
          errorMessages[key].forEach((errorMessage) => {
            errors.push(errorMessage);
          });
        });
      }
      toast.error(errors[0], {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  //Form Submission end//

  //Password Toggle Handler//
  let passwordToggleHandler = () => {
    setBoolen({ ...boolen, toggle: !boolen.toggle });
  };

  let confirmPasswordToggleHandler = () => {
    setBoolen({ ...boolen, toggle2: !boolen.toggle2 });
  };

  let numberInputBlur = () => {
    if (values.number.trim().length === 0) {
      setBoolen({ ...boolen, phoneNumberInvalid: true });
    } else {
      setBoolen({ ...boolen, phoneNumberInvalid: false });
    }
  };
  //Password Toggle Handler end//

  //Input Blur Handler Handler//
  let passwordInputBlur = () => {
    if (values.password.trim().length === 0) {
      setBoolen({ ...boolen, passwordInvalid: true });
    } else {
      setBoolen({ ...boolen, passwordInvalid: false });
    }
  };

  const confirmPasswordInputBlur = () => {
    if (values.confirmPassword.trim().length === 0) {
      setBoolen({ ...boolen, passwordInvalid2: true });
    } else {
      setBoolen({ ...boolen, passwordInvalid2: false });
    }
  };

  let nameInputBlur = () => {
    if (values.name.trim().length === 0) {
      setBoolen({ ...boolen, nameInvalid: true });
    } else {
      setBoolen({ ...boolen, nameInvalid: false });
    }
  };
  //Input Blur Handler Handler end//

  const type = boolen.toggle ? "name" : "password";
  const type2 = boolen.toggle2 ? "name" : "password";
  return (
    <section className={styles.login}>
      <ToastContainer />
      <div className={styles.topProgress}>
        <div className={styles.topProgress_active}></div>
        <div></div>
      </div>
      <div className={styles.login__hero}>
        <Logo />
        <div className={styles.header}>
          <p>Sign Up</p>
        </div>
      </div>
      <div className={styles.form}>
        <form onSubmit={submitHandler}>
          <Input
            label="Full Name"
            onBlur={nameInputBlur}
            invalid={boolen.nameInvalid}
            onChange={handleInputChange}
            value={values.name}
            type="name"
            placeholder="Enter your full name"
            name="name"
          />
          <EmailInput
            type="email"
            value={values.email}
            label="Email"
            onChange={handleInputChange}
            placeholder="Enter email"
            validEmail={validEmail}
            name="email"
          />
          <Input
            label="Phone Number"
            onBlur={numberInputBlur}
            invalid={boolen.phoneNumberInvalid}
            onChange={handleInputChange}
            value={values.number}
            type="tel"
            placeholder="Enter phone number"
            name="number"
          />
          <PasswordInput
            toggle={boolen.toggle}
            onClick={passwordToggleHandler}
            onBlur={passwordInputBlur}
            passwordInvalid={boolen.passwordInvalid}
            label="Password"
            placeholder="Enter password (Minimum of 8 characters)"
            type={type}
            value={values.password}
            onChange={handleInputChange}
            name="password"
          />

          <PasswordInput
            toggle={boolen.toggle2}
            onClick={confirmPasswordToggleHandler}
            onBlur={confirmPasswordInputBlur}
            passwordInvalid={boolen.passwordInvalid2}
            label="Confirm Password"
            placeholder="Enter password (Minimum of 8 characters)"
            type={type2}
            value={values.confirmPassword}
            onChange={handleInputChange}
            name="confirmPassword"
          />
          <div className={styles.button}>
            {!loading && (
              <Button disabled={!boolen.formIsValid}>Continue</Button>
            )}
            {loading && (
              <Button disabled={boolen.formIsValid}>Loading...</Button>
            )}
          </div>
        </form>
        <div className={styles.login__options}>
          <p>
            Already have an account?{" "}
            <span>
              {" "}
              <Link to="/login">Login</Link>
            </span>
          </p>
        </div>
      </div>
    </section>
  );
}

export default Signup;
