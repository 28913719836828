import React, { useEffect, useState } from "react";
import BottomNav from "../../components/navbar/BottomNav";
import NavBar from "../../components/navbar/NavBar";
import styles from "./Report.module.css";
import image from "../../assets/car.png";
import Button from "../../components/button/Button";
import CarPapers from "../../components/customer-profile/CarPapers";
import VehicleFaultCodes from "../../components/customer-profile/VehicleFaultCodes";
import Contact from "../../components/customer-profile/Contact";
import { useSelector } from "react-redux";
import CustomerProfile from "../../components/profile/CustomerProfile";
import Images from "../../utils/CarImages";
import VendorApi from "../../utils/api/VendorApi";
import LoadingAnimation from "../../components/UI/LoadingAnimation";
import LiveMetricsReport2 from "../../components/customer-profile/LiveMetrics2";
import VehicleInformation2 from "../../components/customer-profile/VehicleInformation2";

function Report(props) {
  const [record, setRecord] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const diagReport = useSelector((data) => data.auth.diagnoseData);

  const carImage = Images?.find(
    (data, index) =>
      data.name?.toUpperCase() ===
      `${record?.fields?.year || diagReport[0].customer?.vehicle_year}-${
        record?.fields?.brand.toUpperCase() ||
        diagReport[0].customer?.vehicle_make?.toUpperCase()
      }-${
        record?.fields?.model.toUpperCase() ||
        diagReport[0].customer?.vehicle_model?.toUpperCase()
      }`
  );

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const res = await VendorApi.getCustomerYmmData();
      const carDetails = res?.data.records?.find(
        (data, index) => data?.fields.customer_id === diagReport[0]?.customer.id
      );
      setIsLoading(false);
      setRecord(carDetails);
    };
    fetchData();
  }, [diagReport]);

  const vehicle_vin = record ? "No vin" : diagReport[0].customer?.vehicle_vin;

  return (
    <div>
      <NavBar title="Report" setShowMenu={props.setShowMenu} />
      {isLoading && (
        <div className={styles.loading}>
          <LoadingAnimation />
        </div>
      )}
      {!isLoading && (
        <div className={styles.head}>
          <div className={styles.main_hero}>
            <CustomerProfile report={diagReport[0]} deviceId="M00109T644" />
          </div>
          <div className={styles.subscription__wrapper}>
            <img src={carImage?.image || image} alt="car" />
          </div>
          <div>
            <div className={styles.vehicleInfo_wrapper}>
              <VehicleInformation2
                data3={diagReport[0]}
                className={styles.vehicleDetails}
                vehicle_make={
                  record?.fields?.brand || diagReport[0].customer?.vehicle_make
                }
                vehicle_model={
                  record?.fields?.model || diagReport[0].customer?.vehicle_model
                }
                vehicle_year={
                  record?.fields?.year || diagReport[0].customer?.vehicle_year
                }
                vin={vehicle_vin}
                plate_number={diagReport[0].customer?.plate_number}
              />
            </div>

            <div className={styles.button}>
              <Button>Generate report</Button>
            </div>
            <div className={styles.vehicleInfo}>
              <div>
                <LiveMetricsReport2 metrics={diagReport[0]} />
                <CarPapers
                  plate_number={diagReport[0].customer?.plate_number}
                />{" "}
              </div>
              <div className={styles.system_codes}>
                <div className={styles.system_codes_head}>
                  <h3>System Fault Code</h3>
                  <p>
                    Here are the error codes diagnosed as at the time of last
                    test.
                  </p>
                </div>
              </div>
              {diagReport[0].scan_data.vehicle_errors.map((data, index) => (
                <VehicleFaultCodes
                  key={index}
                  title={data.code}
                  liveMetrics={diagReport[0]}
                  description={data.description}
                />
              ))}
            </div>
          </div>
          <div>
            <Contact id={diagReport.id} data={diagReport[0].customer} />
          </div>
        </div>
      )}
      <BottomNav />
    </div>
  );
}

export default Report;
