import React, { useEffect, useState } from "react";
import BottomNav from "../../components/navbar/BottomNav";
import NavBar from "../../components/navbar/NavBar";
import styles from "./Report.module.css";
import image from "../../assets/car.png";
import VehicleInformation from "../../components/customer-profile/VehicleInformation";
import CarPapers from "../../components/customer-profile/CarPapers";
import VehicleFaultCodes from "../../components/customer-profile/VehicleFaultCodes";
import Contact from "../../components/customer-profile/Contact";
import { useSelector } from "react-redux";
import CustomerProfile from "../../components/profile/CustomerProfile";
import Images from "../../utils/CarImages";
import { useParams } from "react-router";
import VendorApi from "../../utils/api/VendorApi";
import LiveMetricsReport from "../../components/customer-profile/LiveMetricsReport";
import LoadingAnimation from "../../components/UI/LoadingAnimation";
import VehicleInformation2 from "../../components/customer-profile/VehicleInformation2";

function ReportPage(props) {
  const [reportData, setReportData] = useState(null);
  const [record, setRecord] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();
  const token = useSelector((data) => data.auth.token);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const res = await VendorApi.getCustomerYmmData();
      const carDetails = res?.data.records?.find(
        (data, index) => data?.fields.customer_id === reportData?.customer?.id
      );
      setIsLoading(false);
      setRecord(carDetails);
    };
    fetchData();
  }, [reportData?.customer?.id]);

  useEffect(() => {
    const fetchScanData = async () => {
      try {
        const res = await VendorApi.getScanDetails({
          id,
        });
        setReportData(res.data);
      } catch (err) {
        console.log(err.response.data);
      }
    };
    fetchScanData();
  }, [id, token]);

  const carImage = Images?.find(
    (data, index) =>
      data?.name?.toUpperCase() ===
      `${record?.fields?.year || reportData?.customer?.vehicle_year}-${
        record?.fields?.brand.toUpperCase() ||
        reportData?.customer?.vehicle_make?.toUpperCase()
      }-${
        record?.fields?.model.toUpperCase() ||
        reportData?.customer?.vehicle_model?.toUpperCase()
      }`
  );

  const vehicle_vin = record ? "No vin" : reportData?.customer?.vehicle_vin;
  return (
    <div>
      <NavBar title="Report" setShowMenu={props.setShowMenu} />
      {isLoading && (
        <div className={styles.loading}>
          <LoadingAnimation />
        </div>
      )}
      {!isLoading && (
        <div className={styles.head}>
          <div className={styles.main_hero}>
            <CustomerProfile report={reportData} />
          </div>
          <div className={styles.subscription__wrapper}>
            <img src={carImage?.image || image} alt="car" />
          </div>
          <div className={styles.vehicleInfo}>
            {/* <VehicleDetails
              className={styles.vehicleDetails}
              vehicle_make={
                record?.fields?.brand || reportData?.customer?.vehicle_make
              }
              vehicle_model={
                record?.fields?.model || reportData?.customer?.vehicle_model
              }
              vehicle_year={
                record?.fields?.year || reportData?.customer?.vehicle_year
              }
              vin={vehicle_vin}
              plate_number={reportData?.customer?.plate_number}
            /> */}

            <VehicleInformation2
              data3={reportData}
              vehicle_make={
                record?.fields?.brand || reportData?.customer?.vehicle_make
              }
              vehicle_model={
                record?.fields?.model || reportData?.customer?.vehicle_model
              }
              vehicle_year={
                record?.fields?.year || reportData?.customer?.vehicle_year
              }
              vin={vehicle_vin}
              plate_number={reportData?.customer?.plate_number}
            />
          </div>
          <div>
            <VehicleInformation data3={reportData} />
          </div>
          <div className={styles.vehicleInfo}>
            <div>
              <CarPapers plate_number={reportData?.customer?.plate_number} />
              <LiveMetricsReport data={reportData} color="#008069" />
            </div>
            <div className={styles.system_codes}>
              <div className={styles.system_codes_head}>
                <h3>System Fault Code</h3>
                <p>
                  Here are the error codes diagnosed as at the time of last
                  test.
                </p>
              </div>
            </div>
            {reportData?.scan_data.vehicle_errors.map((data, index) => (
              <VehicleFaultCodes
                title={data.code}
                description={data.description}
              />
            ))}
          </div>
          <div>
            <Contact id={id} data3={reportData} />
          </div>
        </div>
      )}
      <BottomNav />
    </div>
  );
}

export default ReportPage;
