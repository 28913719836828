import React from "react";
import styles from "./Button.module.css";

function WhiteButton(props) {
  return (
    <div className={styles.actions2}>
      <button
        style={{ border: `1px solid ${props.border}` }}
        onClick={props.onClick}
        disabled={props.disabled}
      >
        {props.children}
      </button>
    </div>
  );
}

export default WhiteButton;
