import React from "react";
import "./LoadingAnimation.css";

const LoadingAnimation = () => {
  return (
    <div className="loading-animation-container">
      <div className="loading-animation-row">
        {/* <div className="loading-animation-card"></div> */}
        <div className="loading-animation-card-1"></div>
        {/* <div className="loading-animation-card"></div> */}
      </div>
      <div className="loading-animation-row">
        <div className="loading-animation-card"></div>
        <div className="loading-animation-card"></div>
        <div className="loading-animation-card"></div>
      </div>
      <div className="loading-animation-row">
        <div className="loading-animation-card"></div>
        <div className="loading-animation-card"></div>
        <div className="loading-animation-card"></div>
      </div>
    </div>
  );
};

export default LoadingAnimation;
