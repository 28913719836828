import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router";
import "./App.css";
import Success from "./components/customer-profile/Success";
import Menu from "./components/navbar/Menu";
import Account from "./pages/account/Account";
import CreditTransaction from "./pages/account/CreditTransaction";
import DebitTransaction from "./pages/account/DebitTransaction";
import RecentTransaction from "./pages/account/RecentTransaction";
import Wallet from "./pages/account/Wallet";
import ForgotPassword from "./pages/auth/forgotPassword/ForgotPassword";
import ForgotPasswordForm1 from "./pages/auth/forgotPassword/ForgotPasswordForm1";
import ForgotPasswordForm2 from "./pages/auth/forgotPassword/ForgotPasswordForm2";
import RecoveryPassword from "./pages/auth/forgotPassword/RecoveryPassword";
import Login from "./pages/auth/Login";
import RegisterWorkshop from "./pages/auth/RegisterWorkshop";
import Signup from "./pages/auth/Signup";
import SignupForm from "./pages/auth/SignupForm";
import Customers from "./pages/customers/Customers";
import History from "./pages/history/History";
import Scan from "./pages/history/Scan";
import TopUp from "./pages/history/TopUp";
import Transaction from "./pages/history/Transaction";
import Home from "./pages/home/Home";
import Report from "./pages/SmartScan/Report";
import ReportPage from "./pages/SmartScan/ReportPage";
import ReportPage2 from "./pages/SmartScan/ReportPage2";
import SmartScan from "./pages/SmartScan/SmartScan";
import CarDetails from "./pages/vehicle/CarDetails";
import VendorApi from "./utils/api/VendorApi";
import { authActions } from "./utils/store/redux-store/Index";
import ManualReport from "./pages/SmartScan/ManualReport";

function App() {
  const [showMenu, setShowMenu] = useState(false);
  const [walletData, setWalletData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reportStatus, setReportStatus] = useState(null);

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = useSelector((data) => data.auth.token);
  const loggedIn = useSelector((state) => state.auth.userLoggedIn);
  const vendorInfo = useSelector((data) => data.auth.vendorSignInData);

  const closeMenuHandler = () => {
    setShowMenu(false);
  };

  useEffect(() => {
    if (pathname === "/history") {
      navigate("/history/scan");
    }
    if (pathname === "/account") {
      navigate("/account/recent-transaction");
    }
  }, [navigate, pathname]);

  // wallet handler callback
  const walletDataHandler = useCallback(async () => {
    try {
      const res = VendorApi.WalletDataHandler(token);
      res.then((response) => {
        setWalletData(response.data);
        dispatch(authActions.walletHandler(response.data));
      });
      const Data = {
        token: token,
        accountNumber: vendorInfo[0]?.funding_account_number,
      };
      const reconciliation = VendorApi.ReconciliationHandler(Data);
      return reconciliation;
    } catch (error) {
      console.log(error.response.data.errors);
    }
  }, [token, vendorInfo, dispatch]);

  useEffect(() => {
    walletDataHandler();
  }, [walletDataHandler]);

  const handleClick = () => {
    setLoading(true);
    walletDataHandler();
    setLoading(false);
  };

  const handleReportData = (data) => {
    setReportStatus(data);
  };

  return (
    <div className="App">
      <div className="body">
        <div className="menu" onClick={closeMenuHandler}>
          {showMenu && <Menu setShowMenu={setShowMenu} />}
        </div>
        <Routes>
          {/* forgotpassword  route*/}
          <Route path="/forgotpassword" element={<RecoveryPassword />}>
            <Route path="/forgotpassword" element={<ForgotPasswordForm1 />} />
            <Route path="form2" element={<ForgotPassword />} />
            <Route path="form1" element={<ForgotPasswordForm2 />} />
          </Route>

          {/* Registration/Signup  route*/}
          <Route path="/signup" element={<SignupForm />}>
            <Route path="/signup" element={<Signup />} />
            <Route path="register" element={<RegisterWorkshop />} />
          </Route>

          {/* Login route*/}
          <Route
            path="/login"
            element={
              (!loggedIn && <Login />) || (loggedIn && <Navigate to="/" />)
            }
          />

          {/* Account Page/wallet/subscription */}
          <Route
            path="/account"
            element={
              (loggedIn && (
                <Account setShowMenu={setShowMenu} showMenu={showMenu} />
              )) ||
              (!loggedIn && <Navigate to="/login" />)
            }
          >
            <Route
              path="/account"
              element={<Wallet walletDataHandler={handleClick} />}
            >
              <Route
                path="/account/recent-transaction"
                element={<RecentTransaction walletData={walletData} />}
              />
              <Route
                path="/account/credit-transaction"
                element={<CreditTransaction loading={loading} />}
              />
              <Route
                path="/account/debit-transaction"
                element={<DebitTransaction loading={loading} />}
              />
            </Route>
          </Route>
          {/* Home page */}
          <Route
            path="/"
            element={
              (loggedIn && (
                <Home setShowMenu={setShowMenu} showMenu={showMenu} />
              )) ||
              (!loggedIn && <Navigate to="/login" />)
            }
          />

          {/* Car Details */}
          <Route
            path="/car-details/:id"
            element={
              (loggedIn && (
                <CarDetails setShowMenu={setShowMenu} showMenu={showMenu} />
              )) ||
              (!loggedIn && <Navigate to="/login" />)
            }
          />

          {/*Customers*/}
          <Route
            path="/customers"
            element={
              (loggedIn && (
                <Customers setShowMenu={setShowMenu} showMenu={showMenu} />
              )) ||
              (!loggedIn && <Navigate to="/login" />)
            }
          />

          {/* History page for all transactions */}
          <Route
            path="/history"
            element={
              (loggedIn && (
                <History setShowMenu={setShowMenu} showMenu={showMenu} />
              )) ||
              (!loggedIn && <Navigate to="/login" />)
            }
          >
            <Route path="/history/scan" element={<Scan />} />
            <Route path="/history/top-up" element={<TopUp />} />
            <Route path="/history/debit" element={<Transaction />} />
          </Route>

          {/* Smart Scan */}
          <Route
            path="/smart-scan"
            element={
              (loggedIn && (
                <SmartScan
                  setShowMenu={setShowMenu}
                  showMenu={showMenu}
                  reportStatus={handleReportData}
                />
              )) ||
              (!loggedIn && <Navigate to="/login" />)
            }
          />

          {/* Manual Input of report */}
          <Route path="/manual-report" element={<ManualReport />} />

          <Route
            path="/manual-report/report-page"
            element={
              <ReportPage2 setShowMenu={setShowMenu} showMenu={showMenu} />
            }
          />

          {/* Smart Scan Report */}
          <Route
            path="/smart-scan/report"
            element={
              (loggedIn && (
                <Report setShowMenu={setShowMenu} showMenu={showMenu} />
              )) ||
              (!loggedIn && <Navigate to="/login" />)
            }
          />

          <Route
            path="/smart-scan/report-page2/:id"
            element={
              <ReportPage setShowMenu={setShowMenu} showMenu={showMenu} />
            }
          />

          <Route
            path="/smart-scan/report-page/:id"
            element={
              <ReportPage2 setShowMenu={setShowMenu} showMenu={showMenu} />
            }
          />

          {/* Success */}
          <Route
            path="/success"
            element={
              (loggedIn && <Success status={reportStatus} />) ||
              (!loggedIn && <Navigate to="/login" />)
            }
          />
        </Routes>
      </div>
    </div>
  );
}

export default App;
