import React, { useEffect, useState } from "react";
import CarPapersAPi from "../../utils/api/CarPapersApi";
import styles from "./CarPapers.module.css";

function CarPapers(props) {
  const [insurance, setInsurance] = useState("");
  const [licence, setLicence] = useState("");
  const [road, setRoad] = useState("");
  const [trafficFine, setTrafficFine] = useState("");

  useEffect(() => {
    const fetchCarPaperState = async () => {
      try {
        const res = await CarPapersAPi.getPaperData(props.plate_number);

        const paperDetails = res?.data;

        // Set current state of traffic fine of customer
        setTrafficFine(paperDetails.fine);

        const time = Date.now();
        if (paperDetails?.insurance !== "No Record Found") {
          setInsurance("Active");
        } else {
          setInsurance("Processing");
        }
        if (paperDetails?.vehicle_license !== "No Record Found") {
          setLicence("Active");
        } else {
          setLicence("Processing");
        }
        if (paperDetails?.road !== "No Record Found") {
          setRoad("Active");
        } else {
          setRoad("Processing");
        }
        if (time > Date.parse(paperDetails?.insurance[0]?.expiry_date)) {
          setInsurance("Expired");
        } else if (Date.parse(time < paperDetails?.insurance[0]?.expiry_date)) {
          setInsurance("Active");
        }
        if (paperDetails?.insurance[0]?.expiry_date === "") {
          setInsurance("Processing");
        }
        if (time > Date.parse(paperDetails?.vehicle_license[0]?.expiry_date)) {
          setLicence("Expired");
        } else if (
          time < Date.parse(paperDetails?.vehicle_license[0]?.expiry_date)
        ) {
          setLicence("Active");
        }
        if (paperDetails?.vehicle_license[0]?.expiry_date === "") {
          setLicence("Processing");
        }

        if (time > Date.parse(paperDetails?.road[0]?.expiry_date)) {
          setRoad("Expired");
        } else if (time < Date.parse(paperDetails?.road[0]?.expiry_date)) {
          setRoad("Active");
        }
        if (paperDetails?.road[0]?.expiry_date === "") {
          setRoad("Processing");
        }
      } catch (error) {
        console.log(error.response.data.errors);
      }
    };
    fetchCarPaperState();
  }, [props.plate_number]);

  const bckLicenseClr = licence === "Active" ? "#E2FBD7" : "#FD1E1E1A";
  const bckInsuranceClr = insurance === "Active" ? "#E2FBD7" : "#FD1E1E1A";
  const licenseClr = licence === "Active" ? "#008069" : "#FD1E1E";
  const insuranceClr = insurance === "Active" ? "#008069" : "#FD1E1E";

  const bckRoadClr = road === "Active" ? "#E2FBD7" : "#FD1E1E1A";
  const roadClr = road === "Active" ? "#008069" : "#FD1E1E";

  const bckProcessingInsuranceClr = insurance === "Processing" ? "#FFF5CC" : "";
  const processingInsuranceClr = insurance === "Processing" ? "#FF9900" : "";

  const bckProcessingLicenceClr = licence === "Processing" ? "#FFF5CC" : "";
  const processingLicenceClr = licence === "Processing" ? "#FF9900" : "";

  const bckProcessingRoadClr = road === "Processing" ? "#FFF5CC" : "";
  const processingRoadClr = road === "Processing" ? "#FF9900" : "";

  return (
    <div className={styles.carStatus}>
      <div className={styles.carStatus__inner}>
        <h4>Car Papers </h4>
        <h4>Status</h4>
      </div>
      <div className={styles.carStatus__inner2}>
        <p className={styles.carStatus__inner2__title}>Insurance</p>
        <p
          style={{
            backgroundColor: bckProcessingInsuranceClr || bckInsuranceClr,
            color: processingInsuranceClr || insuranceClr,
          }}
          className={styles.carStatus__inner2__status}
        >
          {insurance}
        </p>
      </div>
      <div className={styles.carStatus__inner2}>
        <p className={styles.carStatus__inner2__title}>Vehicle License</p>
        <p
          style={{
            backgroundColor: bckProcessingLicenceClr || bckLicenseClr,
            color: processingLicenceClr || licenseClr,
          }}
          className={styles.carStatus__inner2__status}
        >
          {licence}
        </p>
      </div>
      <div className={styles.carStatus__inner2}>
        <p className={styles.carStatus__inner2__title}>Roadworthiness</p>
        <p
          style={{
            backgroundColor: bckProcessingRoadClr || bckRoadClr,
            color: processingRoadClr || roadClr,
          }}
          className={styles.carStatus__inner2__status}
        >
          {road}
        </p>
      </div>
      {trafficFine !== "No Record Found" ? (
        <div className={styles.carStatus__inner2}>
          <p className={styles.carStatus__inner2__title}>Traffic Fine</p>
          <p className={styles.carStatus__inner2__status}>
            ₦{trafficFine[0]?.amount?.toLocaleString()}
          </p>
        </div>
      ) : null}
    </div>
  );
}

export default CarPapers;
