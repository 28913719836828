import React from "react";
import styles from "./NavBar.module.css";

function NavBar(props) {
  const menuToggleHandler = () => {
    props.setShowMenu(true);
  };
  return (
    <section className={styles.navBar}>
      <div className={styles.navBar__body}>
        <div onClick={menuToggleHandler} className={styles.navBar__hamburger}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className={styles.navBar__title}>
          <p>{props.title}</p>
        </div>
        <div className={styles.navBar__notifications}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 22C11.4696 22 10.9609 21.7893 10.5858 21.4142C10.2107 21.0391 10 20.5304 10 20H14C14 20.5304 13.7893 21.0391 13.4142 21.4142C13.0391 21.7893 12.5304 22 12 22ZM20 19H4V17L6 16V10.5C6 7.038 7.421 4.793 10 4.18V2H13C12.3479 2.86394 11.9967 3.91762 12 5C12 5.251 12.019 5.502 12.056 5.751H12C11.3995 5.71211 10.7989 5.82436 10.2529 6.07749C9.70704 6.33061 9.23328 6.71655 8.875 7.2C8.25255 8.18456 7.94714 9.33638 8 10.5V17H16V10.5C16 10.289 15.993 10.086 15.979 9.9C16.6405 10.0366 17.3226 10.039 17.985 9.907C17.996 10.118 18 10.319 18 10.507V16L20 17V19ZM17 8C16.2044 8 15.4413 7.68393 14.8787 7.12132C14.3161 6.55871 14 5.79565 14 5C14 4.20435 14.3161 3.44129 14.8787 2.87868C15.4413 2.31607 16.2044 2 17 2C17.7956 2 18.5587 2.31607 19.1213 2.87868C19.6839 3.44129 20 4.20435 20 5C20 5.79565 19.6839 6.55871 19.1213 7.12132C18.5587 7.68393 17.7956 8 17 8Z"
              fill="#3C3E3F"
            />
          </svg>
        </div>
      </div>
    </section>
  );
}

export default NavBar;
