import React, { useState } from "react";
import styles from "./ManualReport.module.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "../../components/inputs/Input";
import Button from "../../components/button/Button";
import { authActions } from "../../utils/store/redux-store/Index";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

const initialValues = {
  plateNumber: "",
  vin: "",
  rpm: "",
  battery: "",
  temperature: "",
  engineLoad: "",
  fuel: "",
  fuelRate: "",
  speed: "",
  pressure: "",
  carMake: "",
  carModel: "",
  carYear: "",
  milage: "",
  fullname: "",
  phone: "",
};

function ManualReport() {
  const [values, setValues] = useState(initialValues);
  const [errArray, setErrArray] = useState([]);
  const [errInputValue, setErrInputValue] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleErrorInputChange = (event) => {
    const value = event.target.value;
    setErrInputValue(value);

    // Split input by commas and filter out empty values
    const valuesArray = value.split(",").filter((item) => item.trim() !== "");

    // Update the state with the array values
    setErrArray(valuesArray);
  };

  //Input change handler
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  let submitHandler = async (e) => {
    e.preventDefault();
    dispatch(authActions.clearManualDiagData());

    dispatch(
      authActions.getManualDiagnosisData({
        customer: {
          vehicle_make: values.carMake,
          vehicle_model: values.carModel,
          vehicle_year: values.carYear,
          vehicle_vin: values.vin,
          plate_number: values.plateNumber,
          user: {
            fullname: values.fullname,
            phone: values.phone,
          },
        },
        scan_data: {
          vehicle_errors: errArray,
          vehicle_live_data: {
            voltage: values.battery,
            engine_speed: values.rpm,
            coolant_temperature: values.temperature,
            engine_load: values.engineLoad,
            remaining_fuel: values.fuel,
            current_fuel_consumption_rate: values.fuelRate,
            vehicle_mileage: values.milage,
            intake_pressure: values.pressure,
            speed: values.speed,
          },
        },
      })
    );
    toast.success("Success", {
      position: "top-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

    navigate("/manual-report/report-page");
  };

  return (
    <section className={styles.login}>
      <ToastContainer />
      <div className={styles.login__hero}></div>
      <div className={styles.form}>
        <form className={styles.form} onSubmit={submitHandler}>
          <div>
            <Input
              type="text"
              value={values.fullname}
              label="FullName"
              onChange={handleInputChange}
              placeholder="FullName"
              name="fullname"
            />
            <Input
              type="text"
              value={values.phone}
              label="Phone number"
              onChange={handleInputChange}
              placeholder="Phone number"
              name="phone"
            />
          </div>
          <div>
            <Input
              type="text"
              value={values.plateNumber}
              label="Plate Number"
              onChange={handleInputChange}
              placeholder="Plate Number"
              name="plateNumber"
            />
            <Input
              type="text"
              value={values.vin}
              label="Vin"
              onChange={handleInputChange}
              placeholder="Vin"
              name="vin"
            />
          </div>
          <div>
            <Input
              type="text"
              value={values.carMake}
              label="Car Make"
              onChange={handleInputChange}
              placeholder="Car Make"
              name="carMake"
            />
            <Input
              type="text"
              value={values.carModel}
              label="Car Model"
              onChange={handleInputChange}
              placeholder="Car Model"
              name="carModel"
            />
          </div>

          <div>
            <Input
              type="number"
              value={values.carYear}
              label="Car Year"
              onChange={handleInputChange}
              placeholder="Car Year"
              name="carYear"
            />
            <Input
              type="number"
              value={values.milage}
              label="Car Milage"
              onChange={handleInputChange}
              placeholder="Car Milage"
              name="milage"
            />
          </div>

          <div>
            <Input
              type="number"
              value={values.battery}
              label="Battery"
              onChange={handleInputChange}
              placeholder="Enter Battery"
              name="battery"
            />
            <Input
              type="number"
              value={values.rpm}
              label="RPM"
              onChange={handleInputChange}
              placeholder="Enter RPM"
              name="rpm"
            />
          </div>
          <div>
            <Input
              type="number"
              value={values.temperature}
              label="Current Temperature"
              onChange={handleInputChange}
              placeholder="Current Temperature"
              name="temperature"
            />
            <Input
              type="number"
              value={values.engineLoad}
              label="Current Engine Load"
              onChange={handleInputChange}
              placeholder="Enter Current Engine Load"
              name="engineLoad"
            />
          </div>
          <div>
            <Input
              type="number"
              value={values.fuel}
              label="Fuel Level"
              onChange={handleInputChange}
              placeholder="Enter Fuel Level"
              name="fuel"
            />
            <Input
              type="number"
              value={values.fuelRate}
              label="Fuel Consumption Rate"
              onChange={handleInputChange}
              placeholder="Enter Fuel Consumption Rate"
              name="fuelRate"
            />
          </div>
          <div>
            <Input
              type="number"
              value={values.speed}
              label="Speed"
              onChange={handleInputChange}
              placeholder="Enter Speed"
              name="speed"
            />
            <Input
              type="number"
              value={values.pressure}
              label="Air Intake Pressure"
              onChange={handleInputChange}
              placeholder="Enter Air Intake Pressure"
              name="pressure"
            />
          </div>

          <div>
            <Input
              value={errInputValue}
              label="Error codes"
              onChange={handleErrorInputChange}
              placeholder="Enter Error Codes"
            />
          </div>
          <Button>Submit</Button>
        </form>
      </div>
    </section>
  );
}

export default ManualReport;
