import React from "react";
import styles from "./SelectInput.module.css";

function SelectInput(props) {
  return (
    <div className={styles.form__inner}>
      <label>{props.label}</label>
      <div className={styles.form__inner__input}>
        <select value={props.value} onChange={props.onChange}>
          {props.option.map((data, index) => (
            <option value={data}>{data}</option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default SelectInput;
