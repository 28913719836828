import React, { useRef, useImperativeHandle } from "react";
import styles from "./PasswordInput.module.css";

const PasswordInput = React.forwardRef((props, ref) => {
  const passwordRef = useRef();

  let password = passwordRef.current?.value;

  useImperativeHandle(ref, () => {
    return {
      value: password,
    };
  });
  return (
    <div className={styles.form__inner}>
      <label
        className={props.passwordInvalid ? `${styles.invalid}` : ""}
        htmlFor="password"
      >
        {props.label}
      </label>
      <div className={styles.form__passwordInput}>
        <input
          value={props.value}
          onChange={props.onChange}
          className={props.passwordInvalid ? `${styles.invalidInput}` : ""}
          onBlur={props.onBlur}
          type={props.type}
          name={props.name}
          ref={passwordRef}
          placeholder={props.placeholder}
          required
        />
        <div onClick={props.onClick}>
          {props.toggle && (
            <svg
              width="19"
              height="19"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="16" height="16" rx="4" fill="#FAFAFA" />
              <path
                d="M1.33337 6.6665C1.33337 6.6665 3.66671 9.33317 8.00004 9.33317C12.3334 9.33317 14.6667 6.6665 14.6667 6.6665M2.66671 7.76317L1.33337 9.33317M14.6667 9.33317L13.336 7.76517M5.94271 9.11984L5.33337 10.9998M10.042 9.12517L10.6667 10.9998"
                stroke="#3C3E3F"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
          {!props.toggle && (
            <svg
              width="19"
              height="19"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="16" height="16" rx="4" fill="#FAFAFA" />
              <path
                d="M14.1714 7.30816C14.4874 7.7215 14.4874 8.2795 14.1714 8.69216C13.1761 9.9915 10.7881 12.6668 8.00009 12.6668C5.21209 12.6668 2.82409 9.9915 1.82876 8.69216C1.67501 8.49425 1.59155 8.25078 1.59155 8.00016C1.59155 7.74955 1.67501 7.50607 1.82876 7.30816C2.82409 6.00883 5.21209 3.3335 8.00009 3.3335C10.7881 3.3335 13.1761 6.00883 14.1714 7.30816V7.30816Z"
                stroke="#3C3E3F"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
                stroke="#3C3E3F"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </div>
      </div>
    </div>
  );
});

export default PasswordInput;
