import React from "react";
import styles from "./EmailInput.module.css";

function EmailInput(props) {
  return (
    <div className={styles.form__inner}>
      <label
        className={props.emailInvalid ? `${styles.invalid}` : ""}
        htmlFor="email"
      >
        {props.label}
      </label>
      <div className={styles.form__emailInput}>
        <input
          value={props.value}
          onChange={props.onChange}
          className={props.emailInvalid ? `${styles.invalidInput}` : ""}
          onBlur={props.onBlur}
          name={props.name}
          type={props.type}
          placeholder={props.placeholder}
          id="email"
          required={props.required}
        />
        <div>
          {!props.validEmail && (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="16" height="16" rx="4" fill="#FAFAFA" />
              <path
                d="M11.3333 2H4.66667C3.19391 2 2 3.19391 2 4.66667V11.3333C2 12.8061 3.19391 14 4.66667 14H11.3333C12.8061 14 14 12.8061 14 11.3333V4.66667C14 3.19391 12.8061 2 11.3333 2Z"
                stroke="#D9D9D9"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 8.00008L7.5 9.33341L10 6.66675"
                stroke="#D9D9D9"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
          {props.validEmail && (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="16" height="16" rx="4" fill="#FAFAFA" />
              <path
                d="M11.3333 2H4.66667C3.19391 2 2 3.19391 2 4.66667V11.3333C2 12.8061 3.19391 14 4.66667 14H11.3333C12.8061 14 14 12.8061 14 11.3333V4.66667C14 3.19391 12.8061 2 11.3333 2Z"
                stroke="#008069"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 8.00008L7.5 9.33341L10 6.66675"
                stroke="#008069"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </div>
      </div>
    </div>
  );
}

export default EmailInput;
