import React, { useEffect, useState } from "react";
import styles from "./customerList.module.css";
import { Link } from "react-router-dom";

function CustomerList(props) {
  const [update, setUpdate] = useState("");

  // Function to send message to the company's whatsapp
  const sendMessageToWhatsapp = () => {
    const phoneNumber = "2349011136486";

    const message = `Please send diagnosis report to the customer with plate number ${
      props?.data?.customer.plate_number.toUpperCase() ||
      props?.data2.plate_number.toUpperCase()
    }`;

    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;

    window.open(whatsappLink, "_blank");
  };

  // Function to sign up customer for mobile app by vendor
  // const signUpCustomerHandler = () => {
  //   props.setOpenModal(true);

  //   const customerInfo = props?.data ? props?.data : props?.data2;
  //   props.setUserData(customerInfo);
  // };

  useEffect(() => {
    const update = props?.data?.customer.updated_at;
    const update2 = props.data2?.updated_at;
    let newUpdate = update?.slice(0, 10);
    let newUpdate2 = update2?.slice(0, 10);
    setUpdate(newUpdate || newUpdate2);
  }, [props?.data?.customer.updated_at, props.data2?.updated_at]);
  return (
    <div className={styles.workShop_list}>
      <Link to={`/car-details/${props?.data?.id || props?.data2?.id}`}>
        {" "}
        <div className={styles.pDetails}>
          <p style={{ color: props?.color }} className={styles.pDetails_name}>
            {props?.data?.customer.vehicle_make ||
              props?.data2.vehicle_make ||
              `Run Scan to get vehicle info.`}{" "}
            {props?.data?.customer.vehicle_model || props?.data2.vehicle_model}
          </p>
          <h5>
            {" "}
            {props?.data?.customer.plate_number.toUpperCase() ||
              props?.data2.plate_number.toUpperCase()}
          </h5>
          <p>Updated at {update}</p>
        </div>
      </Link>

      <div
        className={styles.contact}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <p className={styles.contact_id}>
          {props?.data?.customer.user.fullname || props?.data2.user.fullname}
        </p>

        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <span
            onClick={(e) => {
              e.stopPropagation();
              sendMessageToWhatsapp();
            }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="24" height="24" rx="4" fill="#E2FBD7" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.415 14.382C17.117 14.233 15.656 13.515 15.384 13.415C15.112 13.316 14.914 13.267 14.715 13.565C14.517 13.861 13.948 14.531 13.775 14.729C13.601 14.928 13.428 14.952 13.131 14.804C12.834 14.654 11.876 14.341 10.741 13.329C9.858 12.541 9.261 11.568 9.088 11.27C8.915 10.973 9.069 10.812 9.218 10.664C9.352 10.531 9.515 10.317 9.664 10.144C9.813 9.97004 9.862 9.84604 9.961 9.64704C10.061 9.44904 10.011 9.27604 9.936 9.12704C9.862 8.97804 9.268 7.51504 9.02 6.92004C8.779 6.34104 8.534 6.42004 8.352 6.41004C8.178 6.40204 7.98 6.40004 7.782 6.40004C7.584 6.40004 7.262 6.47404 6.99 6.77204C6.717 7.06904 5.95 7.78804 5.95 9.25104C5.95 10.713 7.014 12.126 7.163 12.325C7.312 12.523 9.258 15.525 12.239 16.812C12.949 17.118 13.502 17.301 13.933 17.437C14.645 17.664 15.293 17.632 15.805 17.555C16.375 17.47 17.563 16.836 17.811 16.142C18.058 15.448 18.058 14.853 17.984 14.729C17.91 14.605 17.712 14.531 17.414 14.382H17.415ZM11.993 21.785H11.989C10.2184 21.7854 8.48037 21.3094 6.957 20.407L6.597 20.193L2.855 21.175L3.854 17.527L3.619 17.153C2.62914 15.5774 2.1053 13.7538 2.108 11.893C2.11 6.44304 6.544 2.00904 11.997 2.00904C14.637 2.00904 17.119 3.03904 18.985 4.90704C19.9054 5.82362 20.6349 6.91361 21.1313 8.11394C21.6277 9.31427 21.8811 10.6011 21.877 11.9C21.875 17.35 17.441 21.785 11.993 21.785ZM20.405 3.48804C19.3032 2.37896 17.9922 1.49958 16.5481 0.900841C15.1039 0.302105 13.5553 -0.00407625 11.992 4.09775e-05C5.438 4.09775e-05 0.102 5.33504 0.1 11.892C0.096963 13.9788 0.644375 16.0294 1.687 17.837L0 24L6.304 22.346C8.04788 23.2962 10.0021 23.794 11.988 23.794H11.993C18.547 23.794 23.883 18.459 23.885 11.901C23.8898 10.3383 23.5848 8.79014 22.9874 7.34607C22.3901 5.90201 21.5124 4.59071 20.405 3.48804Z"
                fill="#008069"
              />
            </svg>
          </span>

          {/* {(props?.data?.customer.vehicle_make ||
            props?.data2.vehicle_make) && (
            <span
              onClick={(e) => {
                e.stopPropagation();
                signUpCustomerHandler();
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1_2872)">
                  <path
                    d="M9.95405 2.20997C11.303 1.9271 12.6958 1.92642 14.045 2.20797C14.1349 2.80126 14.3574 3.36666 14.6958 3.86215C15.0343 4.35764 15.4801 4.77048 16 5.06997C16.5196 5.3705 17.1002 5.55004 17.6987 5.59522C18.2973 5.64041 18.8983 5.55009 19.457 5.33097C20.3753 6.35903 21.0709 7.56601 21.5 8.87597C21.0314 9.25055 20.6532 9.72592 20.3935 10.2668C20.1338 10.8076 19.9993 11.4 20 12C20 13.264 20.586 14.391 21.502 15.124C21.0704 16.4328 20.3739 17.6389 19.456 18.667C18.8974 18.448 18.2966 18.3578 17.6983 18.403C17.0999 18.4481 16.5195 18.6276 16 18.928C15.4805 19.2274 15.0351 19.6399 14.6968 20.1351C14.3585 20.6302 14.1361 21.1951 14.046 21.788C12.6973 22.0715 11.3044 22.0729 9.95505 21.792C9.86559 21.1982 9.64335 20.6322 9.30487 20.1361C8.96639 19.6401 8.52038 19.2268 8.00005 18.927C7.48041 18.6266 6.89975 18.4472 6.30124 18.4022C5.70272 18.3572 5.10176 18.4477 4.54305 18.667C3.62465 17.6386 2.92905 16.4313 2.50005 15.121C2.96844 14.7466 3.34651 14.2716 3.60618 13.7311C3.86586 13.1906 4.00048 12.5986 4.00005 11.999C4.00054 11.3989 3.86575 10.8063 3.60571 10.2655C3.34568 9.72463 2.96707 9.24935 2.49805 8.87497C2.92972 7.56609 3.62616 6.36007 4.54405 5.33197C5.1027 5.55093 5.70352 5.64117 6.30184 5.59598C6.90017 5.5508 7.48062 5.37135 8.00005 5.07097C8.51959 4.77157 8.96503 4.35899 9.30331 3.86388C9.64158 3.36876 9.864 2.80281 9.95405 2.20997ZM12 15C12.7957 15 13.5588 14.6839 14.1214 14.1213C14.684 13.5587 15 12.7956 15 12C15 11.2043 14.684 10.4413 14.1214 9.87865C13.5588 9.31604 12.7957 8.99997 12 8.99997C11.2044 8.99997 10.4413 9.31604 9.87873 9.87865C9.31612 10.4413 9.00005 11.2043 9.00005 12C9.00005 12.7956 9.31612 13.5587 9.87873 14.1213C10.4413 14.6839 11.2044 15 12 15Z"
                    fill="#008069"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1_2872">
                    <rect width="24" height="24" fill="#E2FBD7" />
                  </clipPath>
                </defs>
              </svg>
            </span>
          )} */}
        </div>
      </div>
    </div>
    // </div>
  );
}

export default CustomerList;
