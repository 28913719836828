import axios from "axios";

const url = process.env.REACT_APP__BASE_URL2;

const CarPapersAPi = {
  getCarPapersState: (payload) => {
    const response = axios({
      url: `${url}/noauth/cusinfo/${payload}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  },
  getPaperData: (plate_no) => {
    const res = axios({
      url: `${url}/premiumpapers/app/papers/${plate_no}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res;
  },
};
export default CarPapersAPi;
