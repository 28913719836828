import React from "react";
import styles from "./SearchInput.module.css";

function SearchInput2(props) {
  return (
    <div className={styles.form__inner2}>
      <label
        className={props.emailInvalid ? `${styles.invalid}` : ""}
        htmlFor="email"
      >
        {props.label}
      </label>
      <div className={styles.form__emailInput}>
        <div>
          <svg
            width="20"
            height="20"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.56396 12.8877C7.9502 12.8877 9.22852 12.4395 10.2744 11.6924L14.209 15.627C14.3916 15.8096 14.6323 15.9009 14.8896 15.9009C15.4292 15.9009 15.811 15.4858 15.811 14.9546C15.811 14.7056 15.728 14.4648 15.5454 14.2905L11.6357 10.3726C12.4575 9.29346 12.9473 7.95703 12.9473 6.50439C12.9473 2.99316 10.0752 0.121094 6.56396 0.121094C3.06104 0.121094 0.180664 2.98486 0.180664 6.50439C0.180664 10.0156 3.05273 12.8877 6.56396 12.8877ZM6.56396 11.5098C3.82471 11.5098 1.55859 9.24365 1.55859 6.50439C1.55859 3.76514 3.82471 1.49902 6.56396 1.49902C9.30322 1.49902 11.5693 3.76514 11.5693 6.50439C11.5693 9.24365 9.30322 11.5098 6.56396 11.5098Z"
              fill="#3C3E3F"
            />
          </svg>
        </div>
        <input
          value={props.value}
          onChange={props.onChange}
          className={props.emailInvalid ? `${styles.invalidInput}` : ""}
          onBlur={props.onBlur}
          name={props.name}
          type={props.type}
          placeholder={props.placeholder}
          id="email"
          required={props.required}
        />
      </div>
    </div>
  );
}

export default SearchInput2;
