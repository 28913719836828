import React, { useEffect, useState } from "react";
import styles from "./Recents.module.css";
import Button from "../../components/button/Button";
import VehicleTransactions from "../../components/customer-profile/VehicleTransactions";
import Calender from "../../components/inputs/Calender";
import SearchInput2 from "../../components/inputs/SearchInput2";
import { useSelector } from "react-redux";

const currentDate = new Date();
const year = currentDate.getFullYear();
const month = (currentDate.getMonth() + 1).toLocaleString("en-US", {
  minimumIntegerDigits: 2,
  useGrouping: false,
});
const day = currentDate.getDate();
const date = `${year}-${month}-${day}`;

function Transaction() {
  const [searchInput, setSearchInput] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endFilterDate, setEndFilterDate] = useState(null);
  const [validButton, setValidButton] = useState(false);

  const wallet = useSelector((data) => data.auth.wallet);
  const DebitTransact = wallet?.filter((data, index) => {
    return data?.results[0]?.transaction_type === "DEBIT";
  });

  const formSubmitHandler = (e) => {
    e.preventDefault();
  };
  const searchInputChangeHandler = (e) => {
    setSearchInput(e.target.value);
  };

  useEffect(() => {
    if (startDate !== null && endFilterDate !== null) {
      setValidButton(true);
    } else {
      setValidButton(false);
    }
  }, [startDate, endFilterDate]);

  return (
    <div className={styles.history}>
      <div className={styles.history_header}>
        <form className={styles.form} onSubmit={formSubmitHandler}>
          <div>
            <SearchInput2
              className={styles.input}
              value={searchInput}
              onChange={searchInputChangeHandler}
              name="search"
              placeholder="Search"
              type="text"
            />
          </div>
        </form>
        <div className={styles.calender_filter}>
          <Calender
            placeholderText="Start Date"
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            openToDate={new Date(date)}
          />

          <Calender
            placeholderText="End Date"
            selected={endFilterDate}
            onChange={(date) => setEndFilterDate(date)}
            openToDate={new Date(date)}
          />
          <div className={styles.filterButton}>
            <Button disabled={!validButton}>
              {" "}
              <div>
                <svg
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.95062 15.4844H6.51562V7.93213H6.52041L6.51196 7.92208L1.26562 1.67643V0.515625H15.4844V1.66989L10.4878 7.91561L10.4844 7.91288V7.92537V12.9506L7.95062 15.4844ZM7.48438 14.5V14.5156H7.5H7.54291V14.5221L7.55395 14.511L9.51105 12.554L9.51562 12.5585V12.5429V7.58011L14.3719 1.50976L14.3922 1.48438H14.3597H2.40313H2.36959L2.39116 1.51005L7.48438 7.57357V14.5Z"
                    fill="white"
                    stroke="white"
                    stroke-width="0.03125"
                  />
                </svg>
                Filter
              </div>
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.transactions_list}>
        {DebitTransact[0]?.results?.map((data, index) => (
          <VehicleTransactions
            debit="true"
            title="Debited"
            data={data}
            color="#FD4D1E"
          />
        ))}
      </div>
    </div>
  );
}

export default Transaction;
