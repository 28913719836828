import React, { useEffect, useState } from "react";
import BottomNav from "../../components/navbar/BottomNav";
import NavBar from "../../components/navbar/NavBar";
import styles from "./Report.module.css";
import image from "../../assets/car.png";
import CarPapers from "../../components/customer-profile/CarPapers";
import Contact from "../../components/customer-profile/Contact";
import { useSelector } from "react-redux";
import Images from "../../utils/CarImages";
import { useLocation, useParams } from "react-router";
import VendorApi from "../../utils/api/VendorApi";
import VehicleInformation2 from "../../components/customer-profile/VehicleInformation2";
import LiveMetricsReport2 from "../../components/customer-profile/LiveMetrics2";
import LoadingAnimation from "../../components/UI/LoadingAnimation";
import VehicleFaultCodes from "../../components/customer-profile/VehicleFaultCodes";

function ReportPage2(props) {
  const [reportData, setReportData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { pathname } = useLocation();

  const { id } = useParams();
  const token = useSelector((data) => data.auth.token);

  // Get manual report data
  const manualReport = useSelector((data) => data.auth.manualReportData[0]);

  useEffect(() => {
    if (pathname !== "/manual-report/report-page") {
      const fetchScanData = async () => {
        setIsLoading(true);
        try {
          const res = await VendorApi.getScanDetails({
            id,
          });
          setReportData(res.data);
          setIsLoading(false);
        } catch (err) {
          setIsLoading(false);
          console.log(err.response.data);
        }
      };
      fetchScanData();
    } else {
      setReportData(manualReport);
    }
  }, [id, token, pathname, manualReport]);

  const carImage = Images?.find(
    (data) =>
      data?.name?.toUpperCase() ===
      `${
        reportData?.customer?.vehicle_year
      }-${reportData?.customer?.vehicle_make?.toUpperCase()}-${reportData?.customer?.vehicle_model?.toUpperCase()}`
  );
  const vehicle_vin = !reportData?.customer?.vehicle_vin
    ? "No vin"
    : reportData?.customer?.vehicle_vin;

  return (
    <div>
      <NavBar title="Report" setShowMenu={props.setShowMenu} />
      {isLoading && (
        <div className={styles.loading}>
          <LoadingAnimation />
        </div>
      )}
      {!isLoading && (
        <div className={styles.head}>
          <div className={styles.subscription__wrapper}>
            <img src={carImage?.image || image} alt="car" />
          </div>
          <div>
            <VehicleInformation2
              data3={reportData}
              vehicle_make={reportData?.customer?.vehicle_make}
              vehicle_model={reportData?.customer?.vehicle_model}
              vehicle_year={reportData?.customer?.vehicle_year}
              vin={vehicle_vin}
              plate_number={reportData?.customer?.plate_number}
            />
          </div>
          <div className={styles.vehicleInfo}>
            <div>
              <LiveMetricsReport2 metrics={reportData} color="#008069" />
              <CarPapers plate_number={reportData?.customer?.plate_number} />
            </div>
            <div className={styles.system_codes}>
              <div className={styles.system_codes_head}>
                <h3>System Fault Code</h3>
                <p>
                  Here are the error codes diagnosed as at the time of last
                  test.
                </p>
              </div>
            </div>
            {reportData?.scan_data.vehicle_errors.map((data, index) => (
              <VehicleFaultCodes
                key={index}
                title={data.code || data}
                liveMetrics={reportData}
                description={data.description || null}
              />
            ))}
          </div>
          <div>
            <Contact id={id} data3={reportData} />
          </div>
        </div>
      )}
      <BottomNav />
    </div>
  );
}

export default ReportPage2;
