import React, { useCallback, useEffect, useState } from "react";
import NavBar from "../../components/navbar/NavBar";
import SubscriptionSummary from "../../components/profile/SubscriptionSummary";
import styles from "./CarDetails.module.css";
import image from "../../assets/car.png";
import { Link, useParams } from "react-router-dom";
import VehicleDetails from "../../components/customer-profile/VehicleDetails";
import BottomNav from "../../components/navbar/BottomNav";
import VehicleInformation from "../../components/customer-profile/VehicleInformation";
import VehicleTransactions from "../../components/customer-profile/VehicleTransactions";
import SubscriptionModal from "../../components/UI/SubscriptionModal";
import VendorApi from "../../utils/api/VendorApi";
import { useSelector } from "react-redux";
import Images from "../../utils/CarImages";
import Modal from "../../components/UI/Modal";
import InputModal from "../../components/UI/InputModal";
import unavailableVin from "../../assets/unavailable_vin.png";
import LoadingAnimation from "../../components/UI/LoadingAnimation";

function CarDetails(props) {
  const [modal, setModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [customerData, setCustomerData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [userData2, setUserData2] = useState(null);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [vendorScans, setVendorScans] = useState(null);
  const [uniqueDiag, setUniqueDiag] = useState(null);
  const [specificCustomerData, setSpecificCustomerData] = useState(null);
  const [refreshModal, setRefreshModal] = useState(false);
  const [nullVin, setNullVin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const token = useSelector((data) => data.auth.token);

  const vendorInfo = useSelector((data) => data.auth.vendorSignInData);

  const { id } = useParams();

  const carImage = Images?.find(
    (data, index) =>
      data?.name?.toUpperCase() ===
      `${
        customerData?.vehicle_year
      }-${customerData?.vehicle_make?.toUpperCase()}-${customerData?.vehicle_model?.toUpperCase()}`
  );

  const fetchCustomer = useCallback(() => {
    const fetchCustomerData = async () => {
      try {
        const res = VendorApi.getCustomerDetails({
          token,
          id,
        });
        res.then((response) => {
          setCustomerData(response.data);
        });
      } catch (error) {
        console.log(error.response.data.errors);
      }
    };
    fetchCustomerData();
  }, [id, token]);

  useEffect(() => {
    fetchCustomer();
    const fetchSpecificCustomerData = async () => {
      try {
        setIsLoading(true);
        const res = await VendorApi.vendorCustomersData(token);

        const scansRes = await VendorApi.getVehicleScans(token, 1000000);

        setSpecificCustomerData(res.data.results);

        setVendorScans(scansRes.data.results);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log(error.response.data.errors);
      }
    };
    fetchSpecificCustomerData();
  }, [token, fetchCustomer]);

  useEffect(() => {
    const customerInfo = specificCustomerData?.find((data, index) => {
      return data.id === customerData?.id;
    });
    setUserData(customerInfo);

    const customerInfo2 = vendorScans?.find((data, index) => {
      return data.customer.id === customerData?.id;
    });
    setUserData2(customerInfo2);
  }, [customerData?.id, specificCustomerData, vendorScans]);

  useEffect(() => {
    const uniqueCustomerScans = vendorScans?.filter((data, index) => {
      return data.customer.id === id;
    });
    setUniqueDiag(uniqueCustomerScans);
  }, [vendorScans, id, userData]);
  const subscribeToggleHandler = () => {
    setModal(true);
  };

  const modalCloseHandler = () => {
    setModal(false);
  };
  const backHandler = () => {
    setModal(false);
    setSuccessModal(false);
  };

  const refreshPageHandler = () => {
    setRefreshModal(true);
    fetchCustomer();
    setTimeout(() => {
      setRefreshModal(false);
    }, 2000);
  };

  const linkVinHandler = () => {
    setNullVin(true);
  };

  const closeYmmHandler = () => {
    setNullVin(false);
  };

  // Function to send sms after successful vin decode
  const customerVehicleHandler = async (payload) => {
    try {
      setNullVin(false);

      // sms message to customers
      const smsMsg = `Hurray! We have successfully diagnosed your ${payload.year} ${payload.brand} ${payload.model} vehicle. You can view the report with the link below. https://vendor.motormata.com/smart-scan/report-page/${uniqueDiag[0]?.id}`;

      // Send message to customers that have finished scan flow
      const smsMsgHandler = await VendorApi.sendMessage(
        userData?.user?.phone,
        smsMsg
      );

      const payloadMsg = {
        message: `Dear Customer, here is the link to the recent diagnosis of your ${payload.year} ${payload.brand} ${payload.model}.
            https://vendor.motormata.com/smart-scan/report-page/${uniqueDiag[0]?.id}
      Thank you for your patronage.`,
        phoneNumber: userData?.user?.phone,
      };
      const msgHandler = await VendorApi.sendWhatsappMessage(payloadMsg);
      console.log(msgHandler.data);
      setModal(false);
      window.location.reload();
      return smsMsgHandler;
    } catch (err) {
      console.log(err);
    }
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const res = await VendorApi.getCustomerYmmData();
  //     const carDetails = res?.data.records?.find(
  //       (data, index) => data?.fields.customer_id === id
  //     );
  //     setRecord(carDetails);
  //   };
  //   fetchData();
  // }, [id]);

  // const vehicle_vin = record ? "No vin" : customerData?.vehicle_vin;

  return (
    <div>
      {refreshModal && (
        <Modal title="Refreshing..." message="Just a sec" accept="" back="" />
      )}
      {successModal && (
        <Modal
          success="true"
          title={title}
          message={message}
          accept="Back"
          back=""
          close={backHandler}
          confirm={backHandler}
        />
      )}
      {nullVin && (
        <InputModal
          customer_id={id}
          close={closeYmmHandler}
          vendorInfo={vendorInfo[0]}
          ymmData={customerVehicleHandler}
          image={unavailableVin}
          plate_number={customerData?.plate_number}
        />
      )}
      {modal && (
        <SubscriptionModal
          customerId={id}
          close={modalCloseHandler}
          setTitle={setTitle}
          setMessage={setMessage}
          setSuccessModal={setSuccessModal}
          setModal={setModal}
        />
      )}
      <NavBar title="Car Details" setShowMenu={props.setShowMenu} />
      {isLoading && (
        <div className={styles.loading}>
          <LoadingAnimation />
        </div>
      )}{" "}
      {!isLoading && (
        <div className={styles.head}>
          <div className={styles.main_hero}>
            <SubscriptionSummary data={customerData} />
          </div>
          <div className={styles.options}>
            {customerData?.vehicle_vin ? (
              <div className={styles.options_body} onClick={refreshPageHandler}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 19.9996V14.9996H15M4 3.99959V8.99959H9M19.938 10.9996C19.7287 9.337 19.0026 7.78209 17.8623 6.55423C16.7219 5.32638 15.2248 4.48756 13.5822 4.15612C11.9396 3.82468 10.2343 4.01734 8.70709 4.70694C7.17983 5.39654 5.90764 6.54825 5.07 7.99959M4.062 12.9996C4.27133 14.6622 4.99739 16.2171 6.13774 17.445C7.2781 18.6728 8.7752 19.5116 10.4178 19.8431C12.0604 20.1745 13.7657 19.9818 15.2929 19.2922C16.8202 18.6027 18.0924 17.4509 18.93 15.9996"
                    stroke="#008069"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p>Refresh</p>
              </div>
            ) : (
              <div className={styles.options_body} onClick={linkVinHandler}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 19.9996V14.9996H15M4 3.99959V8.99959H9M19.938 10.9996C19.7287 9.337 19.0026 7.78209 17.8623 6.55423C16.7219 5.32638 15.2248 4.48756 13.5822 4.15612C11.9396 3.82468 10.2343 4.01734 8.70709 4.70694C7.17983 5.39654 5.90764 6.54825 5.07 7.99959M4.062 12.9996C4.27133 14.6622 4.99739 16.2171 6.13774 17.445C7.2781 18.6728 8.7752 19.5116 10.4178 19.8431C12.0604 20.1745 13.7657 19.9818 15.2929 19.2922C16.8202 18.6027 18.0924 17.4509 18.93 15.9996"
                    stroke="#008069"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p>Link VIN</p>
              </div>
            )}

            <div
              onClick={subscribeToggleHandler}
              className={styles.options_body}
            >
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.5 21.3333H18.8333V21V17V16.6667H18.5H6.5H6.16667V17V21V21.3333H6.5H18.5ZM18.5 15.3333H18.8333V15V13V12.6667H18.5H6.5H6.16667V13V15V15.3333H6.5H18.5ZM10.38 5.33333H17.1667V9.66667H16.3333V6.5V6.16667H16H10.38H9.5776L10.1438 6.73522L12.3302 8.93062L11.7392 9.52721L7.97085 5.75L11.7392 1.97279L12.3302 2.56938L10.1438 4.76478L9.5776 5.33333H10.38ZM6.5 11.3333H18.5C18.942 11.3333 19.366 11.5089 19.6785 11.8215C19.9911 12.134 20.1667 12.558 20.1667 13V21C20.1667 21.442 19.9911 21.8659 19.6785 22.1785C19.366 22.4911 18.942 22.6667 18.5 22.6667H6.5C6.05797 22.6667 5.63405 22.4911 5.32149 22.1785C5.00893 21.8659 4.83333 21.442 4.83333 21V13C4.83333 12.558 5.00893 12.134 5.32149 11.8215C5.63405 11.5089 6.05797 11.3333 6.5 11.3333Z"
                  fill="white"
                  stroke="white"
                  stroke-width="0.666667"
                />
              </svg>

              <p>Subscribe</p>
            </div>
          </div>
          <div className={styles.carWrapper}>
            <div className={styles.subscription__wrapper}>
              <img src={carImage?.image || image} alt="car" />
            </div>
          </div>

          <div>
            <VehicleDetails
              className={styles.vehicleDetails}
              vehicle_make={customerData?.vehicle_make}
              vehicle_model={customerData?.vehicle_model}
              vehicle_year={customerData?.vehicle_year}
              vin={customerData?.vehicle_vin}
              plate_number={customerData?.plate_number}
            />
          </div>
          <div>
            <VehicleInformation data3={userData2} data={userData} />
          </div>

          <div className={styles.recentTransactions}>
            <div className={styles.recentTransactionsTitle}>
              <h4>Recent</h4>
            </div>

            {customerData?.vehicle_vin ? (
              <div className={styles.transactions_list}>
                {uniqueDiag?.map((data, index) => {
                  return (
                    <Link
                      key={data.id}
                      to={`/smart-scan/report-page/${data.id}`}
                    >
                      <VehicleTransactions
                        diag="true"
                        data={data}
                        // record={record}
                        title="Diagnosis"
                        color="#5D5FEF"
                      />
                    </Link>
                  );
                })}
              </div>
            ) : (
              <div className={styles.transactions_list}>
                <p>
                  Please register VIN to view this report or escalate to admin.
                </p>
              </div>
            )}
          </div>
        </div>
      )}
      <BottomNav />
    </div>
  );
}

export default CarDetails;
