import React, { useEffect, useState } from "react";
import styles from "./ProfileSummary.module.css";
import photo from "../../assets/Avatar.png";

function CustomerProfile(props) {
  const [customerId, setCustomerId] = useState("");
  useEffect(() => {
    const id = props?.report?.customer?.id.slice(0, 10);
    setCustomerId(id);
  }, [props?.report]);
  return (
    <div
      className={styles.main_hero_header}
      style={{ backgroundColor: props.color }}
    >
      <div className={styles.main_hero_header_inner}>
        <div className={styles.main_hero_displayImage}>
          <div className={styles.main_hero_displayImage_wrapper}>
            <img src={photo} alt="profile" />
          </div>
          <div className={styles.main_hero_profileInfo}>
            <h4>{props?.report?.customer?.user?.fullname}</h4>
            <p style={{ color: "#008069" }}>
              {props.report?.customer?.user?.email}
            </p>
            <p>{props.report?.customer?.user?.phone}</p>
          </div>
        </div>
        <div className={styles.main_hero_profile}>
          <div className={styles.main_hero_profileInfo}>
            <h4>{customerId?.toUpperCase()}</h4>
            <p>Customer ID</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerProfile;
