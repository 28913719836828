import React, { useEffect, useState } from "react";
import styles from "./Modal.module.css";
import Button from "../button/Button";
import Card from "./Card";
import Input from "../inputs/Input";
import VendorApi from "../../utils/api/VendorApi";
import { useSelector } from "react-redux";
import Images from "../../utils/CarImages";
import WhiteButton from "../button/WhiteButton";

function InputModal(props) {
  const [vin, setVin] = useState("");
  // const [vehicleInfo, setVehicleInfo] = useState(null);
  const [formValid, setFormValid] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [car, setCar] = useState(null);
  // const [errMsg, setErrMsg] = useState(false);
  // const [sentMail, setSentMail] = useState(false);

  const token = useSelector((data) => data.auth.token);

  // let debounceTimer = useRef(null);

  useEffect(() => {
    const endOfVin = props.customerVehicleData?.VehicleChasisNo.slice(-4);
    if (vin === endOfVin) {
      // setErrMsg(false);
      setFormValid(true);
    } else {
      // setErrMsg(true);
      setFormValid(false);
    }
  }, [vin, props.customerVehicleData?.VehicleChasisNo]);

  const vinChangeHandler = (e) => {
    setVin(e.target.value);
  };

  // useEffect(() => {
  //   if (vin?.length === 17) {
  //     try {
  //       clearTimeout(debounceTimer.current);
  //       setVehicleInfo(null);

  //       debounceTimer.current = setTimeout(async () => {
  //         setLoading(true);
  //         const response = await VendorApi.vinDecoder({
  //           vin: vin,
  //           token,
  //         });
  //         setVehicleInfo(response.data);
  //         setLoading(false);
  //       }, 1000);
  //     } catch (err) {
  //       setLoading(false);
  //       console.log(err.message);
  //     }
  //   }
  // }, [vin, token]);

  // useEffect(() => {
  //   return () => {
  //     clearTimeout(debounceTimer.current);
  //   };
  // }, []);

  // useEffect(() => {
  //   setSentMail(false);
  // }, []);

  useEffect(() => {
    const carImage = Images?.find(
      (data, index) =>
        data?.name?.toUpperCase() ===
        `${
          props?.customerVehicleData?.YearOfManufacture
        }-${props?.customerVehicleData?.VehicleMake?.toUpperCase()}-${props?.customerVehicleData?.VehicleModel?.toUpperCase()}`
    );
    setCar(carImage);
  }, [
    props.customerVehicleData?.YearOfManufacture,
    props.customerVehicleData?.VehicleMake,
    props.customerVehicleData?.VehicleModel,
  ]);

  const ymmData = {
    vin: props.customerVehicleData?.VehicleChasisNo,
    year: props.customerVehicleData?.YearOfManufacture,
    brand: props.customerVehicleData?.VehicleMake,
    model: props.customerVehicleData?.VehicleModel,
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setFormValid(false);
    try {
      const updateCustomerRes = await VendorApi.updateCustomerInfo({
        year: props.customerVehicleData?.YearOfManufacture,
        make: props.customerVehicleData?.VehicleMake,
        model: props.customerVehicleData?.VehicleModel,
        customer_id: props.customer_id,
        vin: props.customerVehicleData?.VehicleChasisNo,
        access: token,
      });
      props.ymmData(ymmData);
      setFormValid(true);

      return updateCustomerRes;
    } catch (err) {
      setFormValid(true);
      console.log(err.response.error);
    }
  };

  // Function to trigger mail sending

  // const submitErrHandler = async (e) => {
  //   e.preventDefault();
  //   try {
  //     setLoading(true);
  //     const updateCustomerRes = await VendorApi.sendTicket({
  //       to_name: "Admin",
  //       from_name: `Vendor - ${props.vendorInfo?.fullname}`,
  //       message: `Please can you help decode this VIN - ${vin} which belongs to the customer with this plate number - ${props.plate_number}`,
  //     });
  //     setSentMail(true);
  //     setLoading(false);
  //     return updateCustomerRes;
  //   } catch (err) {
  //     setLoading(false);
  //     console.log(err);
  //   }
  // };

  // const carModel = cars?.find((model, index) => model.brand === brand);

  return (
    <div>
      <div className={styles.backdrop}>
        <Card
          className={styles.modal2}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {props.children}
          <header>
            <div className={styles.image}>
              <div className={styles.imageWrapper}>
                <img src={car?.image || props.image} alt={props.help} />
              </div>
            </div>

            <form className={styles.form} onSubmit={submitHandler}>
              <div>
                <p>Plate number - {props.plate_number}</p>
                {/* {!loading && ( */}
                <p>
                  YMM - {props.customerVehicleData?.VehicleMake}-
                  {props.customerVehicleData?.VehicleModel}-
                  {props.customerVehicleData?.YearOfManufacture}
                </p>
                {/* )} */}
                {/* {loading && <p>Loading... Please hold on for a while.</p>} */}
              </div>
              {/* {errMsg && vehicleInfo?.vehicle_make === "" && !sentMail ? (
                <p>
                  Couldn't fetch vehicle data. Please make sure the VIN is up to
                  17 characters and also correct. (Note you can't access the
                  report till the VIN is confirmed. You can also update the VIN
                  on the customer's details page)
                </p>
              ) : (
                <p>
                  {sentMail
                    ? `A mail has been sent to the admin already. Issue will be
                  resolved soon.`
                    : ""}
                </p>
              )} */}
              <Input
                type="email"
                value={vin}
                label="VIN (Enter last 4 digits.)"
                onChange={vinChangeHandler}
                placeholder="Enter VIN last 4 digits"
                name="vin"
              />
            </form>
          </header>
          <footer className={styles.actions}>
            <div>
              {/* {errMsg === false ? ( */}
              <Button
                disabled={!formValid}
                border="#008069"
                type="submit"
                onClick={submitHandler}
              >
                Confirm!
              </Button>
              {/* ) : ( */}
              {/* <Button
                  // disabled={!formValid}
                  border="#008069"
                  type="submit"
                  onClick={!sentMail ? submitErrHandler : null}
                >
                  {!loading ? `Report case to admin` : `Sending message...`}
                </Button> */}
              {/* )} */}

              <WhiteButton onClick={props.close}>Close</WhiteButton>
            </div>
          </footer>
        </Card>
      </div>
    </div>
  );
}

export default InputModal;
