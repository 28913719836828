import React from "react";
import styles from "./ProfileStats.module.css";

function ProfileStats(props) {
  return (
    <div className={styles.main_body_stats}>
      <div className={styles.main_body_stats_card}>
        <div>
          {" "}
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12.5" r="12" fill="#D7FBDB" />
            <g clip-path="url(#clip0_4507_2825)">
              <path
                d="M12.6665 13.416L12.6668 13.416C13.0312 13.4157 13.3807 13.2707 13.6384 13.013C13.8962 12.7552 14.0411 12.4058 14.0415 12.0413V12.041C14.0415 11.7691 13.9609 11.5032 13.8098 11.2771C13.6587 11.051 13.4439 10.8748 13.1927 10.7707C12.9414 10.6666 12.665 10.6394 12.3983 10.6924C12.1315 10.7455 11.8865 10.8764 11.6942 11.0687C11.5019 11.261 11.371 11.506 11.3179 11.7728C11.2649 12.0395 11.2921 12.316 11.3962 12.5672C11.5002 12.8185 11.6765 13.0332 11.9026 13.1843C12.1287 13.3354 12.3946 13.416 12.6665 13.416ZM11.7637 10.6899C12.0309 10.5113 12.3451 10.416 12.6665 10.416C13.0975 10.416 13.5108 10.5872 13.8156 10.892C14.1203 11.1967 14.2915 11.61 14.2915 12.041C14.2915 12.3624 14.1962 12.6766 14.0176 12.9438C13.8391 13.211 13.5853 13.4193 13.2884 13.5423C12.9914 13.6653 12.6647 13.6975 12.3495 13.6348C12.0343 13.5721 11.7447 13.4173 11.5175 13.1901C11.2902 12.9628 11.1354 12.6733 11.0727 12.358C11.01 12.0428 11.0422 11.7161 11.1652 11.4192C11.2882 11.1222 11.4965 10.8684 11.7637 10.6899Z"
                fill="#3C3E3F"
                stroke="#008069"
                stroke-width="0.5"
              />
              <path
                d="M12.6665 7.91602C11.6282 7.91602 10.6131 8.22392 9.74976 8.8008C8.8864 9.37768 8.2135 10.1976 7.81614 11.1569C7.41878 12.1162 7.31481 13.1718 7.51738 14.1902C7.71996 15.2086 8.21997 16.1441 8.9542 16.8783C9.68842 17.6126 10.6239 18.1126 11.6423 18.3151C12.6607 18.5177 13.7163 18.4137 14.6756 18.0164C15.6349 17.619 16.4548 16.9461 17.0317 16.0828C17.6086 15.2194 17.9165 14.2044 17.9165 13.166C17.9149 11.7741 17.3613 10.4397 16.3771 9.45546C15.3928 8.47124 14.0584 7.9176 12.6665 7.91602ZM10.4165 17.0574V16.541C10.4168 16.2427 10.5354 15.9568 10.7463 15.7458C10.9573 15.5349 11.2432 15.4163 11.5415 15.416H13.7915C14.0898 15.4163 14.3758 15.5349 14.5867 15.7458C14.7976 15.9568 14.9162 16.2427 14.9165 16.541V17.0574C14.2337 17.4561 13.4572 17.6662 12.6665 17.6662C11.8758 17.6662 11.0993 17.4561 10.4165 17.0574ZM15.6635 16.5133C15.656 16.0214 15.4556 15.5521 15.1055 15.2066C14.7553 14.8611 14.2834 14.6669 13.7915 14.666H11.5415C11.0496 14.6669 10.5777 14.8611 10.2275 15.2066C9.87738 15.5521 9.67698 16.0214 9.66951 16.5133C8.98947 15.906 8.5099 15.1066 8.29432 14.2208C8.07873 13.3349 8.13729 12.4045 8.46225 11.5527C8.7872 10.7009 9.36321 9.96789 10.114 9.45073C10.8648 8.93357 11.755 8.65665 12.6667 8.65665C13.5784 8.65665 14.4686 8.93357 15.2194 9.45073C15.9702 9.96789 16.5462 10.7009 16.8711 11.5527C17.1961 12.4045 17.2547 13.3349 17.0391 14.2208C16.8235 15.1066 16.3439 15.906 15.6639 16.5133H15.6635Z"
                fill="#3C3E3F"
              />
            </g>
            <defs>
              <clipPath id="clip0_4507_2825">
                <rect
                  width="12"
                  height="12"
                  fill="white"
                  transform="translate(6.6665 7.16602)"
                />
              </clipPath>
            </defs>
          </svg>
          <p>Total</p>
        </div>

        <h2>{props?.vendorInfo?.total_customer || 0}</h2>
        <p>Customers </p>
      </div>

      <div
        className={styles.main_body_stats_card}
        style={{ backgroundColor: "#D7FBDB" }}
      >
        <div>
          {" "}
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12.5" r="12" fill="#F5FFF1" />
            <g clip-path="url(#clip0_4507_2839)">
              <path
                d="M18.1391 9.96746L18.1392 9.96752C18.1901 10.0184 18.2305 10.0788 18.2579 10.1454C18.2852 10.212 18.2991 10.2834 18.2986 10.3553V10.3554L18.2765 17.4802C18.2758 17.6249 18.2179 17.7634 18.1154 17.8655C18.0129 17.9676 17.8743 18.0251 17.7296 18.0254H8.69682C8.26195 18.0254 7.84489 17.8526 7.53739 17.5451C7.22989 17.2376 7.05714 16.8206 7.05713 16.3857V9.94634C7.05714 9.51146 7.22989 9.0944 7.53739 8.7869C7.84489 8.4794 8.26195 8.30665 8.69682 8.30664H16.7915C16.9365 8.30681 17.0755 8.36448 17.178 8.467C17.2805 8.56953 17.3382 8.70854 17.3384 8.85353V9.79102V9.80664H17.354L17.7518 9.80664L17.7518 9.80664C17.8238 9.80644 17.8951 9.82055 17.9616 9.84816C18.0281 9.87577 18.0884 9.91632 18.1391 9.96746ZM17.5427 17.3066H17.5583L17.5584 17.2911L17.5793 10.5411L17.5794 10.5254H17.5637H8.74463V9.80664H16.604H16.6196V9.79102V9.04102V9.02539H16.604H8.69682C8.45257 9.02539 8.21833 9.12242 8.04562 9.29513C7.87291 9.46784 7.77588 9.70209 7.77588 9.94634V16.3857C7.77588 16.6299 7.87291 16.8642 8.04562 17.0369C8.21833 17.2096 8.45257 17.3066 8.69682 17.3066H17.5427Z"
                fill="#3C3E3F"
                stroke="#008069"
                stroke-width="0.03125"
              />
              <path
                d="M15.854 13.3535H16.604V14.1035H15.854V13.3535Z"
                fill="#3C3E3F"
              />
            </g>
            <defs>
              <clipPath id="clip0_4507_2839">
                <rect
                  width="12"
                  height="12"
                  fill="white"
                  transform="translate(6.6665 7.16602)"
                />
              </clipPath>
            </defs>
          </svg>
          <p>Wallet</p>
        </div>
        <h2>
          ₦{Number(props?.vendorInfo?.wallet_balance || 0).toLocaleString()}
        </h2>
        <p>Balance</p>
      </div>

      <div
        className={styles.main_body_stats_card}
        style={{ backgroundColor: "#D7FBDB" }}
      >
        <div>
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12.5" r="12" fill="#F5FFF1" />
            <g clip-path="url(#clip0_4523_16504)">
              <path
                d="M12.6665 13.416L12.6668 13.416C13.0312 13.4157 13.3807 13.2707 13.6384 13.013C13.8962 12.7552 14.0411 12.4058 14.0415 12.0413V12.041C14.0415 11.7691 13.9609 11.5032 13.8098 11.2771C13.6587 11.051 13.4439 10.8748 13.1927 10.7707C12.9414 10.6666 12.665 10.6394 12.3983 10.6924C12.1315 10.7455 11.8865 10.8764 11.6942 11.0687C11.5019 11.261 11.371 11.506 11.3179 11.7728C11.2649 12.0395 11.2921 12.316 11.3962 12.5672C11.5002 12.8185 11.6765 13.0332 11.9026 13.1843C12.1287 13.3354 12.3946 13.416 12.6665 13.416ZM11.7637 10.6899C12.0309 10.5113 12.3451 10.416 12.6665 10.416C13.0975 10.416 13.5108 10.5872 13.8156 10.892C14.1203 11.1967 14.2915 11.61 14.2915 12.041C14.2915 12.3624 14.1962 12.6766 14.0176 12.9438C13.8391 13.211 13.5853 13.4193 13.2884 13.5423C12.9914 13.6653 12.6647 13.6975 12.3495 13.6348C12.0343 13.5721 11.7447 13.4173 11.5175 13.1901C11.2902 12.9628 11.1354 12.6733 11.0727 12.358C11.01 12.0428 11.0422 11.7161 11.1652 11.4192C11.2882 11.1222 11.4965 10.8684 11.7637 10.6899Z"
                fill="#3C3E3F"
                stroke="#008069"
                stroke-width="0.5"
              />
              <path
                d="M12.6665 7.91602C11.6282 7.91602 10.6131 8.22392 9.74976 8.8008C8.8864 9.37768 8.2135 10.1976 7.81614 11.1569C7.41878 12.1162 7.31481 13.1718 7.51738 14.1902C7.71996 15.2086 8.21997 16.1441 8.9542 16.8783C9.68842 17.6126 10.6239 18.1126 11.6423 18.3151C12.6607 18.5177 13.7163 18.4137 14.6756 18.0164C15.6349 17.619 16.4548 16.9461 17.0317 16.0828C17.6086 15.2194 17.9165 14.2044 17.9165 13.166C17.9149 11.7741 17.3613 10.4397 16.3771 9.45546C15.3928 8.47124 14.0584 7.9176 12.6665 7.91602V7.91602ZM10.4165 17.0574V16.541C10.4168 16.2427 10.5354 15.9568 10.7463 15.7458C10.9573 15.5349 11.2432 15.4163 11.5415 15.416H13.7915C14.0898 15.4163 14.3758 15.5349 14.5867 15.7458C14.7976 15.9568 14.9162 16.2427 14.9165 16.541V17.0574C14.2337 17.4561 13.4572 17.6662 12.6665 17.6662C11.8758 17.6662 11.0993 17.4561 10.4165 17.0574V17.0574ZM15.6635 16.5133C15.656 16.0214 15.4556 15.5521 15.1055 15.2066C14.7553 14.8611 14.2834 14.6669 13.7915 14.666H11.5415C11.0496 14.6669 10.5777 14.8611 10.2275 15.2066C9.87738 15.5521 9.67698 16.0214 9.66951 16.5133C8.98947 15.906 8.50991 15.1066 8.29432 14.2208C8.07873 13.3349 8.13729 12.4045 8.46225 11.5527C8.7872 10.7009 9.36321 9.96789 10.114 9.45073C10.8648 8.93357 11.755 8.65665 12.6667 8.65665C13.5784 8.65665 14.4686 8.93357 15.2194 9.45073C15.9702 9.96789 16.5462 10.7009 16.8711 11.5527C17.1961 12.4045 17.2547 13.3349 17.0391 14.2208C16.8235 15.1066 16.3439 15.906 15.6639 16.5133H15.6635Z"
                fill="#3C3E3F"
              />
            </g>
            <defs>
              <clipPath id="clip0_4523_16504">
                <rect
                  width="12"
                  height="12"
                  fill="white"
                  transform="translate(6.6665 7.16602)"
                />
              </clipPath>
            </defs>
          </svg>

          <p>Active </p>
        </div>
        <h2>{props?.vendorInfo?.active_customers || 0}</h2>
        <p>Customers</p>
      </div>

      <div className={styles.main_body_stats_card}>
        <div>
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12.5" r="12" fill="#FFDFD7" />
            <g clip-path="url(#clip0_4523_2832)">
              <path
                d="M12.6665 13.416L12.6668 13.416C13.0312 13.4157 13.3807 13.2707 13.6384 13.013C13.8962 12.7552 14.0411 12.4058 14.0415 12.0413V12.041C14.0415 11.7691 13.9609 11.5032 13.8098 11.2771C13.6587 11.051 13.4439 10.8748 13.1927 10.7707C12.9414 10.6666 12.665 10.6394 12.3983 10.6924C12.1315 10.7455 11.8865 10.8764 11.6942 11.0687C11.5019 11.261 11.371 11.506 11.3179 11.7728C11.2649 12.0395 11.2921 12.316 11.3962 12.5672C11.5002 12.8185 11.6765 13.0332 11.9026 13.1843C12.1287 13.3354 12.3946 13.416 12.6665 13.416ZM11.7637 10.6899C12.0309 10.5113 12.3451 10.416 12.6665 10.416C13.0975 10.416 13.5108 10.5872 13.8156 10.892C14.1203 11.1967 14.2915 11.61 14.2915 12.041C14.2915 12.3624 14.1962 12.6766 14.0176 12.9438C13.8391 13.211 13.5853 13.4193 13.2884 13.5423C12.9914 13.6653 12.6647 13.6975 12.3495 13.6348C12.0343 13.5721 11.7447 13.4173 11.5175 13.1901C11.2902 12.9628 11.1354 12.6733 11.0727 12.358C11.01 12.0428 11.0422 11.7161 11.1652 11.4192C11.2882 11.1222 11.4965 10.8684 11.7637 10.6899Z"
                fill="#3C3E3F"
                stroke="#FD4D1E"
                stroke-width="0.5"
              />
              <path
                d="M12.6665 7.91602C11.6282 7.91602 10.6131 8.22392 9.74976 8.8008C8.8864 9.37768 8.2135 10.1976 7.81614 11.1569C7.41878 12.1162 7.31481 13.1718 7.51738 14.1902C7.71996 15.2086 8.21997 16.1441 8.9542 16.8783C9.68842 17.6126 10.6239 18.1126 11.6423 18.3151C12.6607 18.5177 13.7163 18.4137 14.6756 18.0164C15.6349 17.619 16.4548 16.9461 17.0317 16.0828C17.6086 15.2194 17.9165 14.2044 17.9165 13.166C17.9149 11.7741 17.3613 10.4397 16.3771 9.45546C15.3928 8.47124 14.0584 7.9176 12.6665 7.91602ZM10.4165 17.0574V16.541C10.4168 16.2427 10.5354 15.9568 10.7463 15.7458C10.9573 15.5349 11.2432 15.4163 11.5415 15.416H13.7915C14.0898 15.4163 14.3758 15.5349 14.5867 15.7458C14.7976 15.9568 14.9162 16.2427 14.9165 16.541V17.0574C14.2337 17.4561 13.4572 17.6662 12.6665 17.6662C11.8758 17.6662 11.0993 17.4561 10.4165 17.0574ZM15.6635 16.5133C15.656 16.0214 15.4556 15.5521 15.1055 15.2066C14.7553 14.8611 14.2834 14.6669 13.7915 14.666H11.5415C11.0496 14.6669 10.5777 14.8611 10.2275 15.2066C9.87738 15.5521 9.67698 16.0214 9.66951 16.5133C8.98947 15.906 8.5099 15.1066 8.29432 14.2208C8.07873 13.3349 8.13729 12.4045 8.46225 11.5527C8.7872 10.7009 9.36321 9.96789 10.114 9.45073C10.8648 8.93357 11.755 8.65665 12.6667 8.65665C13.5784 8.65665 14.4686 8.93357 15.2194 9.45073C15.9702 9.96789 16.5462 10.7009 16.8711 11.5527C17.1961 12.4045 17.2547 13.3349 17.0391 14.2208C16.8235 15.1066 16.3439 15.906 15.6639 16.5133H15.6635Z"
                fill="#3C3E3F"
              />
            </g>
            <defs>
              <clipPath id="clip0_4523_2832">
                <rect
                  width="12"
                  height="12"
                  fill="white"
                  transform="translate(6.6665 7.16602)"
                />
              </clipPath>
            </defs>
          </svg>

          <p>Inactive</p>
        </div>

        <h2>{props?.vendorInfo?.inactive_customers || 0}</h2>
        <p>Customers </p>
      </div>
    </div>
  );
}

export default ProfileStats;
