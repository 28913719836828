import React from "react";
import styles from "./Button.module.css";

function NormalButton(props) {
  return (
    <div className={styles.actions3}>
      <button onClick={props.onClick} disabled={props.disabled}>
        {props.children}
      </button>
    </div>
  );
}

export default NormalButton;
