import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Button from "../../components/button/Button";
import EmailInput from "../../components/inputs/EmailInput";
import Input from "../../components/inputs/Input";
import BottomNav from "../../components/navbar/BottomNav";
import NavBar from "../../components/navbar/NavBar";
import ProfileSummary from "../../components/profile/ProfileSummary";
import DeviceModal from "../../components/UI/DeviceModal";
import Modal from "../../components/UI/Modal";
import VendorApi from "../../utils/api/VendorApi";
import styles from "./SmartScan.module.css";
import "react-toastify/dist/ReactToastify.css";
import image1 from "../../assets/car1.png";
import image2 from "../../assets/car4.png";
import image3 from "../../assets/car5.png";
import image5 from "../../assets/chasis.png";
import unavailableVin from "../../assets/unavailable_vin.png";
import image4 from "../../assets/CarMetrics.png";
import { authActions } from "../../utils/store/redux-store/Index";
import InputModal from "../../components/UI/InputModal";

const texts = [
  {
    id: 1,
    error: "P  -  Powertrain",
    phase: "1 of 5",
    image: image1,
  },
  {
    id: 2,
    error: "B  -  Body",
    phase: "2 of 5",
    image: image3,
  },
  {
    id: 3,
    error: "C  -  Chasis",
    phase: "3 of 5",
    image: image5,
  },
  {
    id: 4,
    error: "U  -  Communication",
    phase: "4 of 5",
    image: image2,
  },
  {
    id: 5,
    error: "Live Metrics",
    phase: "5 of 5",
    image: image4,
  },
];

const initialValues = {
  first: "",
  number: "",
  email: "",
  plateNumber: "",
};

const initialBoolenValues = {
  firstNameInvalid: false,
  numberInvalid: false,
  plateNumberInvalid: false,
  formIsValid: false,
};

function SmartScan(props) {
  const [newUser, setNewUser] = useState(false);
  const [values, setValues] = useState(initialValues);
  const [validEmail, setValidEmail] = useState(false);
  const [validNumber, setValidNumber] = useState(false);
  const [modal, setModal] = useState(false);
  const [diagModal, setDiagModal] = useState(false);
  const [diagModalPrgress, setDiagModalProgress] = useState(false);
  const [modalDisplay, setModalDisplay] = useState(false);
  const [validPlateNumber, setValidPlateNumber] = useState(false);
  const [boolen, setBoolen] = useState(initialBoolenValues);
  const [plateNumber, setPlateNumber] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [formIsValid, setFormIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customerData, setCustomerData] = useState(null);
  const [textIndex, setTextIndex] = useState(0);
  const [balance, setBalance] = useState(null);
  const [dongles, setDongles] = useState(null);
  const [successModal, setSuccessModal] = useState(false);
  const [subscribeModal, setSubscribeModal] = useState(false);
  const [message, setMessage] = useState("");
  const [errMessage, setErrMessage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [scanId, setscanId] = useState(null);
  const [nullVin, setNullVin] = useState(false);
  const [diagFinalDisabledBtn, setDiagFinalDisabledBtn] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [customerVehicleData, setCustomerVehicleData] = useState(null);

  // Create number variable for rendering customer number immediately after updating
  let number = useRef();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const token = useSelector((data) => data.auth.token);
  const prevScan = useSelector((data) => data.auth.prevScan);
  const vendorInfo = useSelector((data) => data.auth.vendorSignInData);

  // console.log(prevScan);

  // Fetch the subscription plans.
  // useEffect(() => {
  //   const fetchPlans = async () => {
  //     try {
  //       const res = await VendorApi.subscriptionPlans(token);
  //       setPlans(res.data.data);
  //     } catch (err) {
  //       console.log(err.response.data.errors);
  //     }
  //   };
  //   fetchPlans();
  // }, [token]);

  useEffect(() => {
    const fetchVendorDongles = async () => {
      try {
        const res = await VendorApi.getVendorDongles(token);
        const activeDongles = res.data.data.filter(
          (data, index) => data.is_online === true
        );
        setDongles(activeDongles);
      } catch (err) {
        console.log(err.response.data.errors);
      }
    };
    fetchVendorDongles();

    const interval = setInterval(fetchVendorDongles, 30000);

    return () => clearInterval(interval);
  }, [token]);

  useEffect(() => {
    if (textIndex === 4) {
      navigate("/success");
    }
  }, [textIndex, navigate]);

  useEffect(() => {
    const fetchVendorData = async () => {
      try {
        const res = VendorApi.vendorDashboardData(token);
        res.then((response) => {
          setBalance(response.data.wallet_balance);
        });
      } catch (error) {
        console.log(error.response.data.errors);
      }
    };
    fetchVendorData();
  }, [token]);

  useEffect(() => {
    if (
      values.first.trim().length >= 1 &&
      values.number.trim().length >= 11 &&
      values.plateNumber.trim().length >= 0 &&
      values.email.trim().length >= 1 &&
      values.email.includes("@") &&
      values.email.includes(".com")
    ) {
      setBoolen({ formIsValid: true });
    } else {
      setBoolen({ formIsValid: false });
    }
    if (plateNumber.trim().length >= 3) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [
    values.first,
    values.email,
    values.number,
    values.plateNumber,
    plateNumber,
  ]);

  useEffect(() => {
    if (
      values.email.trim().length >= 1 &&
      values.email.includes("@") &&
      values.email.includes(".com")
    ) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
    if (
      values.plateNumber.trim().length >= 3 ||
      plateNumber.trim().length >= 3
    ) {
      setValidPlateNumber(true);
    } else {
      setValidPlateNumber(false);
    }
    if (values.number.trim().length >= 11) {
      setValidNumber(true);
    } else {
      setValidNumber(false);
    }
  }, [values.email, values.number, values.plateNumber, plateNumber]);

  const plateNumberHandler = (e) => {
    setPlateNumber(e.target.value);
  };

  // Initial submission of Plate number
  const formSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      // Verify the customer with the using the provided platenumber
      const res = await VendorApi.verifyCustomer({
        plate_no: plateNumber.replace(/[-,:./\\]/g, "").toLowerCase(),
        access: token,
      });
      // Prompt registration for new customer after getting a "Not found" status
      if (res.data.status === "NOT_FOUND") {
        setNewUser(true);
        setDiagModal(false);
        setSuccessModal(false);
      } else {
        // If it is a not new user, then fetch their customer detials
        const customerDetailsRes = await VendorApi.getCustomerDetails({
          id: res.data.data.id,
          token: token,
        });
        // Check if the customer has previous scans, if yes, continue with subscription scan flow
        if (customerDetailsRes.data.total_scans > 0) {
          if (
            res.data.status === "NO_ACTIVE_SUBSCRIPTION" ||
            res.data.status === "NOT_SUBSCRIBED"
          ) {
            setSubscribeModal(true);
            setDiagModal(false);
            // set all customer details
            setMessage(res.data);
          }
          if (res.data.status === "VALID") {
            setPhoneNumber(
              res.data.data.user.phone.startsWith("+234")
                ? res.data.data.user.phone
                : `+234${res.data.data.user.phone.substring(1)}`
            );
            setCustomerData(res.data.data.id);
            setDiagModal(true);
          }
        } else {
          // If total scan is zero, continue with free scan flow
          setPhoneNumber(
            res.data.data.user.phone.startsWith("+234")
              ? res.data.data.user.phone
              : `+234${res.data.data.user.phone.substring(1)}`
          );
          setCustomerData(res.data.data.id);
          setDiagModal(true);
        }
      }
    } catch (err) {
      setSuccessModal(true);
      setDiagModal(false);
      console.log(err);
      setMessage(err?.response?.data?.errors?.plate_no[0]);
    }
  };

  // registering a new customer
  const registrationFormSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      // const res = await VendorApi.postNewCustomer({
      //   fullname: values.first,
      //   phone: `+234${values.number.substring(1)}`,
      //   email: values.email.toLowerCase(),
      //   plate_no: plateNumber.replace(/[-,:./\\]/g, "").toLowerCase(),
      //   access: token,
      //   subscription_plan: plans[0].id,
      // });

      // Api to register a new customer without subscription
      const res = await VendorApi.postNewFreeCustomer({
        fullname: values.first,
        phone: `+234${values.number.substring(1)}`,
        email: values.email.toLowerCase(),
        access: token,
        plate_no: plateNumber.replace(/[-,:./\\]/g, "").toLowerCase(),
      });

      // Register customer with reg collector endpoint
      const regCollectorRes = await VendorApi.regCollectorHandler({
        name: values.first,
        phone_no: `+234${values.number.substring(1)}`,
        email: values.email.toLowerCase(),
        plate_no: plateNumber.replace(/[-,:./\\]/g, "").toLowerCase(),
      });

      // Register customer to premium papers
      const premiumPapersRes = await VendorApi.premiumPapersHandler({
        name: values.first,
        phone_no: `+234${values.number.substring(1)}`,
        email: values.email.toLowerCase(),
        plate_no: plateNumber.replace(/[-,:./\\]/g, "").toLowerCase(),
      });

      setNewUser(false);
      return { res, regCollectorRes, premiumPapersRes };
    } catch (err) {
      setLoading(false);
      setSuccessModal(true);
      const errors = [];
      if (err.response.data.errors.message) {
        errors.push(err.response.data.errors.message);
      } else if (err.response.data.errors) {
        const errorMessages = err.response.data.errors;
        Object.keys(errorMessages).forEach((key) => {
          errorMessages[key].forEach((errorMessage) => {
            errors.push(errorMessage);
          });
        });
      }
      // setMessage(
      //   err.response.data.errors.message || err.response.data.errors.email[0]
      // );
      setMessage(errors[0]);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  let numberInputBlur = () => {
    if (values.number.trim().length === 0) {
      setBoolen({ ...boolen, numberInvalid: true });
    } else {
      setBoolen({ ...boolen, numberInvalid: false });
    }
  };

  //Input Blur Handler Handler//

  const plateNumberInputBlur = () => {
    if (values.plateNumber.trim().length === 0) {
      setBoolen({ ...boolen, plateNumberInvalid: true });
    } else {
      setBoolen({ ...boolen, plateNumberInvalid: false });
    }
  };

  const nameInputBlur = () => {
    if (values.first.trim().length === 0) {
      setBoolen({ ...boolen, firstNameInvalid: true });
    } else {
      setBoolen({ ...boolen, firstNameInvalid: false });
    }
  };

  const openDeviceModal = () => {
    const fetchVendorDongles = async () => {
      try {
        const res = await VendorApi.getVendorDongles(token);
        const activeDongles = res.data.data.filter(
          (data, index) => data.is_online === true
        );
        setDongles(activeDongles);
      } catch (err) {
        console.log(err.response.data.errors);
      }
    };
    fetchVendorDongles();
    setModalDisplay(true);
  };

  const closeModalHandler = () => {
    setModalDisplay(false);
  };

  const deviceIdHandler = (payload) => {
    setDeviceId(payload);
  };

  const backHandler = () => {
    setMessage("");
    setSuccessModal(false);
    setSubscribeModal(false);
    setModal(false);
  };

  const closeYmmHandler = () => {
    setNullVin(false);
    setDiagModal(false);
  };

  const backDiagHandler = () => {
    setDiagModal(false);
  };

  // Function to navigate user to their profile for subscription
  const naviagetUserToProfile = () => {
    navigate(`/car-details/${message.data.id}`);
  };

  const proceedHandlerFinal = () => {
    setDiagModal(true);
    setNewUser(false);
  };

  // run diagnosis for customers without vin
  const customerVehicleHandler = async (payload) => {
    try {
      setNullVin(false);
      setDiagModalProgress(true);

      // sms message to customers
      const smsMsg = `Hurray! We have successfully diagnosed your ${payload.year} ${payload.brand} ${payload.model} vehicle. You can view the report with the link below. https://vendor.motormata.com/smart-scan/report-page/${scanId}`;

      // Send message to customers that have finished scan flow
      const smsMsgHandler = await VendorApi.sendMessage(phoneNumber, smsMsg);

      const payloadMsg = {
        message: `Dear Customer, here is the link to the recent diagnosis of your ${payload.year} ${payload.brand} ${payload.model}.
            https://vendor.motormata.com/smart-scan/report-page/${scanId}
      Thank you for your patronage.`,
        phoneNumber: phoneNumber,
      };
      // Send whatsapp messages to customers
      const msgHandler = await VendorApi.sendWhatsappMessage(payloadMsg);
      props.reportStatus(msgHandler.data);
      setDiagModal(false);
      setModal(false);
      let intervalId = setInterval(() => {
        setTextIndex((p) => p + 1);
      }, 2000);
      setTimeout(() => {
        clearInterval(intervalId);
        console.log("Interval cleared");
      }, 9000);
      return smsMsgHandler;
    } catch (err) {
      console.log(err);
    }
  };

  //run diagnosis for customers
  const proceedToDiagHandlerFinal = async () => {
    try {
      setDiagFinalDisabledBtn(true);
      dispatch(authActions.clearDiagData());
      // Verify the customer with the using the provided platenumber
      const verifyRes = await VendorApi.verifyCustomer({
        plate_no: plateNumber.replace(/[-,:./\\]/g, "").toLowerCase(),
        access: token,
      });

      // Get the amount of scans a customer has
      const customerDetailsRes = await VendorApi.getCustomerDetails({
        id: verifyRes.data.data.id,
        token: token,
      });

      const customerRes = await VendorApi.fetchCustomerDetails({
        plate_number: plateNumber.replace(/[-,:./\\]/g, "").toLowerCase(),
        // access: token,
      });

      setCustomerVehicleData(customerRes.data.data);

      if (customerDetailsRes.data.total_scans > 0) {
        // Check if to use scanner with validation or not
        if (customerDetailsRes.data.vehicle_vin === "") {
          // Run scan based on subscription
          const res = await VendorApi.vendorNoSmsScan({
            access: token,
            customer: customerData,
            device: deviceId || dongles[0]?.device?.dongle,
          });
          setscanId(res?.data?.data?.id);
          const customerRes = await VendorApi.getScanDetails({
            id: res?.data?.data?.id,
          });
          dispatch(authActions.getDiagnosisData(res.data.data));
          // check if the vin exist from a previous scan
          if (
            prevScan[0]?.customer?.vehicle_vin ===
              res.data?.data?.customer?.vehicle_vin &&
            prevScan[0]?.customer?.plate_number !==
              res.data?.data?.customer?.plate_number
          ) {
            setNullVin(true);
          } else if (
            prevScan[0]?.customer?.vehicle_vin === "" &&
            res.data?.data?.customer?.vehicle_vin === null
          ) {
            setNullVin(true);
          } else if (res.data?.data?.customer?.vehicle_vin === null) {
            setNullVin(true);
          } else {
            setNullVin(false);
            dispatch(authActions.previousScanHandler(res.data.data));
            setDiagModalProgress(true);

            if (res?.data?.data?.id) {
              // sms message to customers
              const smsMsg = `Hurray! We have successfully diagnosed your ${res?.data?.data?.customer?.vehicle_year} ${res?.data?.data?.customer?.vehicle_make} ${res?.data?.data?.customer?.vehicle_model} vehicle. You can view the report with the link below. https://vendor.motormata.com/smart-scan/report-page/${res?.data?.data?.id}`;

              // Send message to customers that have finished scan flow
              const smsMsgHandler = await VendorApi.sendMessage(
                phoneNumber,
                smsMsg
              );
              const payload = {
                message: `Dear ${
                  customerRes.data.customer.user.fullname
                }, here is the link to the recent diagnosis of your ${
                  res?.data?.data?.customer?.vehicle_year
                } ${res?.data?.data?.customer?.vehicle_make} ${
                  res?.data?.data?.customer?.vehicle_model
                } with plate number ${res?.data?.data?.customer?.plate_number?.toUpperCase()}.
        https://vendor.motormata.com/smart-scan/report-page/${
          res?.data?.data?.id
        }
  Thank you for your patronage.`,
                phoneNumber: phoneNumber,
              };
              const msgHandler = await VendorApi.sendWhatsappMessage(payload);
              props.reportStatus(msgHandler.data);

              setDiagModal(false);
              setModal(false);
              setInterval(() => {
                setTextIndex((p) => p + 1);
              }, 2000);
              return smsMsgHandler;
            }
          }
        } else {
          const res = await VendorApi.vendorNoSmsScanNoValidation({
            access: token,
            customer: customerData,
            device: deviceId || dongles[0]?.device?.dongle,
          });
          setscanId(res?.data?.data?.id);
          const customerRes = await VendorApi.getScanDetails({
            id: res?.data?.data?.id,
          });
          dispatch(authActions.getDiagnosisData(res.data.data));
          // check if the vin exist from a previous scan
          if (
            prevScan[0]?.customer?.vehicle_vin ===
              res.data?.data?.customer?.vehicle_vin &&
            prevScan[0]?.customer?.plate_number !==
              res.data?.data?.customer?.plate_number
          ) {
            setNullVin(true);
          } else if (
            prevScan[0]?.customer?.vehicle_vin === "" &&
            res.data?.data?.customer?.vehicle_vin === null
          ) {
            setNullVin(true);
          } else if (res.data?.data?.customer?.vehicle_vin === null) {
            setNullVin(true);
          } else {
            setNullVin(false);
            dispatch(authActions.previousScanHandler(res.data.data));
            setDiagModalProgress(true);

            if (res?.data?.data?.id) {
              // sms message to customers
              const smsMsg = `Hurray! We have successfully diagnosed your ${res?.data?.data?.customer?.vehicle_year} ${res?.data?.data?.customer?.vehicle_make} ${res?.data?.data?.customer?.vehicle_model} vehicle. You can view the report with the link below. https://vendor.motormata.com/smart-scan/report-page/${res?.data?.data?.id}`;

              // Send message to customers that have finished scan flow
              const smsMsgHandler = await VendorApi.sendMessage(
                phoneNumber,
                smsMsg
              );
              const payload = {
                message: `Dear ${
                  customerRes.data.customer.user.fullname
                }, here is the link to the recent diagnosis of your ${
                  res?.data?.data?.customer?.vehicle_year
                } ${res?.data?.data?.customer?.vehicle_make} ${
                  res?.data?.data?.customer?.vehicle_model
                } with plate number ${res?.data?.data?.customer?.plate_number?.toUpperCase()}.
        https://vendor.motormata.com/smart-scan/report-page/${
          res?.data?.data?.id
        }
  Thank you for your patronage.`,
                phoneNumber: phoneNumber,
              };
              const msgHandler = await VendorApi.sendWhatsappMessage(payload);
              props.reportStatus(msgHandler.data);

              setDiagModal(false);
              setModal(false);
              setInterval(() => {
                setTextIndex((p) => p + 1);
              }, 2000);
              return smsMsgHandler;
            }
          }
        }
      } else {
        // const lastScan = await VendorApi.getLastScan(token);

        // console.log(lastScan.data);
        // Run free scan based on no subscription
        const res = await VendorApi.vendorFreeNoSmsScanNoValidation({
          access: token,
          customer: customerData,
          device: deviceId || dongles[0]?.device?.dongle,
        });
        setscanId(res?.data?.data?.id);
        const customerRes = await VendorApi.getScanDetails({
          id: res?.data?.data?.id,
        });
        dispatch(authActions.getDiagnosisData(res.data.data));
        // check if the vin exist from a previous scan
        if (
          prevScan[0]?.customer?.vehicle_vin ===
            res.data?.data?.customer?.vehicle_vin &&
          prevScan[0]?.customer?.plate_number !==
            res.data?.data?.customer?.plate_number
        ) {
          setNullVin(true);
        } else if (
          prevScan[0]?.customer?.vehicle_vin === "" &&
          res.data?.data?.customer?.vehicle_vin === null
        ) {
          setNullVin(true);
        } else if (res.data?.data?.customer?.vehicle_vin === null) {
          setNullVin(true);
        } else {
          setNullVin(false);
          dispatch(authActions.previousScanHandler(res.data.data));
          setDiagModalProgress(true);

          if (res?.data?.data?.id) {
            // sms message to customers
            const smsMsg = `Hurray! We have successfully diagnosed your ${res?.data?.data?.customer?.vehicle_year} ${res?.data?.data?.customer?.vehicle_make} ${res?.data?.data?.customer?.vehicle_model} vehicle. You can view the report with the link below. https://vendor.motormata.com/smart-scan/report-page/${res?.data?.data?.id}`;

            // Send message to customers that have finished scan flow
            const smsMsgHandler = await VendorApi.sendMessage(
              phoneNumber,
              smsMsg
            );

            const payload = {
              message: `Dear ${
                customerRes.data.customer.user.fullname
              }, here is the link to the recent diagnosis of your ${
                res?.data?.data?.customer?.vehicle_year
              } ${res?.data?.data?.customer?.vehicle_make} ${
                res?.data?.data?.customer?.vehicle_model
              } with plate number ${res?.data?.data?.customer?.plate_number?.toUpperCase()}.
        https://vendor.motormata.com/smart-scan/report-page/${
          res?.data?.data?.id
        }
  Thank you for your patronage.`,
              phoneNumber: phoneNumber,
            };
            const msgHandler = await VendorApi.sendWhatsappMessage(payload);
            console.log(msgHandler.data);
            props.reportStatus(msgHandler.data);
            setDiagModal(false);
            setModal(false);
            setInterval(() => {
              setTextIndex((p) => p + 1);
            }, 2000);
            return smsMsgHandler;
          }
        }
      }
      setDiagFinalDisabledBtn(false);
    } catch (err) {
      setDiagFinalDisabledBtn(false);
      const errors = [];
      if (err.response.data.errors.message) {
        errors.push(err.response.data.errors.message);
      } else if (err.response.data.errors) {
        const errorMessages = err.response.data.errors;
        Object.keys(errorMessages).forEach((key) => {
          errorMessages[key].forEach((errorMessage) => {
            errors.push(errorMessage);
          });
        });
      }
      setErrMessage(errors[0]);
      setModal(true);
    }
    // Set an interval to switch the text every 2 seconds
  };

  useEffect(() => {
    if (isChecked) {
      number.current = `${values.number
        .replace(/[-,:./\\]/g, "")
        .toLowerCase()}@email.com`;

      setValues((prevValues) => ({ ...prevValues, email: number.current }));
    } else {
      setValues((prevValues) => ({ ...prevValues, email: "" }));
      // Perform additional actions when checkbox is unchecked
    }
  }, [values.number, isChecked]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Toggle the state when the checkbox is clicked
    // Perform any action you want here when the checkbox is clicked
  };

  const dongle = dongles === null ? "Loading..." : dongles[0]?.device?.dongle;

  return (
    <div>
      {diagModal && (
        <Modal
          title="Diagnosis"
          message="Your vehicle will be diagnosed shortly. Pay attention to the 5 phases."
          close={backDiagHandler}
          back="Back"
          accept="Proceed"
          disabled={diagFinalDisabledBtn}
          confirm={proceedToDiagHandlerFinal}
        />
      )}
      {nullVin && (
        <InputModal
          customer_id={customerData}
          customerVehicleData={customerVehicleData}
          close={closeYmmHandler}
          vendorInfo={vendorInfo[0]}
          ymmData={customerVehicleHandler}
          image={unavailableVin}
          plate_number={plateNumber.replace(/[-,:./\\]/g, "").toLowerCase()}
        />
      )}
      {modal && (
        <Modal
          title="Error!"
          message={errMessage}
          close={backHandler}
          back="Back"
          accept=""
          confirm={proceedHandlerFinal}
        />
      )}
      {diagModalPrgress && (
        <Modal
          message="You’re almost done! 
Your car is under diagnosis"
          currPhase={texts[textIndex].phase}
          errCode={texts[textIndex].error}
          image={texts[textIndex].image}
          back=""
          accept=""
          loader="true"
        />
      )}
      {successModal && (
        <Modal
          title="Error!"
          message={message}
          accept="Back"
          back=""
          close={backHandler}
          confirm={backHandler}
        />
      )}

      {/* Modal to prompt vendor to subscribe for the customers */}
      {subscribeModal && (
        <Modal
          title="Error!"
          message={message.status}
          accept="Subscribe"
          back=""
          close={backHandler}
          confirm={naviagetUserToProfile}
        />
      )}

      <NavBar title="Smartscan" setShowMenu={props.setShowMenu} />
      <div className={styles.head}>
        <div className={styles.main_hero}>
          <ProfileSummary
            vendorInfo={vendorInfo}
            deviceId={
              deviceId?.slice(0, 10) || dongle?.slice(0, 10) || "No device"
            }
          />
        </div>
        <div className={styles.vend_status}>
          <div className={styles.vend_status_card} onClick={openDeviceModal}>
            <div>
              <p>Active Dongle</p>
              <p>
                {deviceId?.slice(0, 10)?.toLocaleUpperCase() ||
                  dongle?.slice(0, 10)?.toLocaleUpperCase() ||
                  "No device"}
              </p>
            </div>
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.7358 12.4074L15.3281 9.81414L16.2715 10.7569L12.5 14.5284L8.72851 10.7569L9.67189 9.81414L12.2643 12.4074L12.5 12.6433L12.7358 12.4074Z"
                fill="#008069"
                stroke="#008069"
                stroke-width="0.666667"
              />
            </svg>
          </div>
          <div className={styles.vend_status_card}>
            {modalDisplay && (
              <DeviceModal onCloseModal={closeModalHandler}>
                {dongles?.map((data, index) => {
                  return (
                    <div
                      style={{
                        backgroundColor:
                          data?.device?.dongle === deviceId ? "#008069" : "",
                      }}
                      onClick={() => deviceIdHandler(data?.device?.dongle)}
                      className={styles.modalInner}
                      key={index}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.096 1.76426C11.407 1.87528 11.6146 2.16983 11.6146 2.50004V7.55212L16.6667 7.55213C16.9685 7.55213 17.2434 7.72603 17.3726 7.99882C17.5019 8.27161 17.4625 8.59446 17.2713 8.82809L9.77131 17.9948C9.56221 18.2503 9.215 18.3468 8.90401 18.2358C8.59302 18.1248 8.38541 17.8303 8.38541 17.5L8.38541 12.448H3.33332C3.03145 12.448 2.7566 12.2741 2.62733 12.0013C2.49806 11.7285 2.53751 11.4056 2.72867 11.172L10.2287 2.00532C10.4378 1.74975 10.785 1.65325 11.096 1.76426ZM4.98195 10.8855H9.16666C9.37386 10.8855 9.57257 10.9678 9.71908 11.1143C9.8656 11.2608 9.94791 11.4595 9.94791 11.6667L9.94791 15.3114L15.018 9.11462L10.8333 9.11462C10.4018 9.11462 10.0521 8.76485 10.0521 8.33337V4.68864L4.98195 10.8855Z"
                          fill={
                            data?.device?.dongle === deviceId
                              ? "#FFFFFF"
                              : "#6B7280"
                          }
                        />
                      </svg>
                      <p
                        style={{
                          color:
                            data?.device?.dongle === deviceId ? "#FFFFFF" : "",
                          fontWeight: "500",
                        }}
                      >
                        {data.device.dongle.slice(0, 10)}
                      </p>
                    </div>
                  );
                })}
                {dongles?.length === 0 && <p>No Device Active</p>}
              </DeviceModal>
            )}
            <svg
              width="37"
              height="36"
              viewBox="0 0 37 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="18.5" cy="18" r="18" fill="#E2FBD7" />
              <path
                d="M20.4663 15.4428L20.2274 16.0004H20.834H24.5C25.2439 16.0004 25.5852 16.9248 25.02 17.4084C25.02 17.4084 25.02 17.4084 25.02 17.4084L19.092 22.4784L19.0846 22.4848L19.0774 22.4915L13.0486 28.1819L13.0485 28.182C12.4257 28.7705 11.4375 28.098 11.7575 27.302L11.7576 27.3019L14.952 19.3495L15.1725 18.8004H14.5808L12.5 18.8004L12.4996 18.8004C12.3672 18.8005 12.2368 18.7677 12.1201 18.705C12.0035 18.6423 11.9042 18.5516 11.8312 18.4411C11.7583 18.3305 11.7139 18.2036 11.7021 18.0716C11.6903 17.9397 11.7115 17.8069 11.7636 17.6852L11.7637 17.6852L15.3636 9.28519C15.3636 9.28518 15.3637 9.28517 15.3637 9.28516C15.4254 9.14117 15.528 9.01848 15.6589 8.9323C15.7897 8.84612 15.943 8.80025 16.0996 8.80039H16.1H22.1C22.1 8.80039 22.1 8.80039 22.1 8.80039C22.2324 8.80041 22.3627 8.83328 22.4792 8.89605C22.5958 8.95882 22.6949 9.04954 22.7678 9.16006C22.8406 9.27059 22.8849 9.39747 22.8967 9.52934C22.9085 9.6612 22.8873 9.79391 22.8352 9.91559L22.8351 9.91562L20.4663 15.4428ZM18.2796 16.4852L18.2797 16.4851L20.6473 10.9579L20.8861 10.4004H20.2796H16.8908H16.627L16.5231 10.6429L13.9527 16.6429L13.7139 17.2004H14.3204H16.3565C16.4873 17.2005 16.616 17.2327 16.7315 17.2941C16.847 17.3555 16.9456 17.4443 17.0188 17.5527C17.092 17.6611 17.1375 17.7858 17.1513 17.9159C17.1651 18.046 17.1469 18.1774 17.0981 18.2988L17.098 18.2988L15.2776 22.8288L15.9234 23.2689L18.0139 21.2956L21.5104 18.3043L22.3333 17.6004H21.2504H19.014C18.8817 17.6002 18.7515 17.5673 18.6351 17.5045C18.5186 17.4416 18.4196 17.3509 18.3468 17.2404C18.274 17.1299 18.2298 17.003 18.2181 16.8712C18.2064 16.7395 18.2275 16.6068 18.2796 16.4852Z"
                fill="#008069"
                stroke="#008069"
                stroke-width="0.8"
              />
            </svg>
            <div>
              <p> ₦{Number(balance || 0).toLocaleString()}</p>
              <p>Wallet bal.</p>
            </div>
          </div>
        </div>
        <div className={styles.vend_diagnosis_form}>
          <h3>Diagnose Customer’s Car?</h3>
          {!newUser && (
            <div className={styles.vend_diagnosis_form_inner}>
              <p>
                Enter customer’s plate number to proceed to diagnosis process.
              </p>
              <form onSubmit={formSubmitHandler} className={styles.form}>
                <EmailInput
                  onChange={plateNumberHandler}
                  value={plateNumber}
                  validEmail={validPlateNumber}
                  label="Plate Number"
                  placeholder="Enter plate number"
                  required="required"
                />
                <div className={styles.button}>
                  <Button disabled={!formIsValid}>Proceed</Button>
                </div>
              </form>
            </div>
          )}

          {newUser && (
            <div className={styles.vend_diagnosis_form_inner}>
              <p>
                Enter Customer’s ID or Phone number and Plate number below to
                proceed to diagnosis process.
              </p>
              <form
                onSubmit={registrationFormSubmitHandler}
                className={styles.form}
              >
                <Input
                  label="Full Name"
                  onBlur={nameInputBlur}
                  invalid={boolen.firstNameInvalid}
                  onChange={handleInputChange}
                  value={values.first}
                  type="name"
                  placeholder="Enter your full name"
                  name="first"
                />
                <EmailInput
                  type="tel"
                  value={values.number}
                  label="Phone Number"
                  onChange={handleInputChange}
                  onBlur={numberInputBlur}
                  emailInvalid={boolen.numberInvalid}
                  placeholder="Enter phone number"
                  validEmail={validNumber}
                  name="number"
                />
                <EmailInput
                  type="email"
                  value={values.email}
                  label="Email Address"
                  onChange={handleInputChange}
                  placeholder="Enter email"
                  validEmail={validEmail}
                  name="email"
                />
                <div className={styles.check_box}>
                  <p>Click the box if the customer does not provide email.</p>
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                </div>
                <EmailInput
                  type="text"
                  value={plateNumber.replace(/[-,:./\\]/g, "").toLowerCase()}
                  label="Plate number"
                  onChange={handleInputChange}
                  onBlur={plateNumberInputBlur}
                  emailInvalid={boolen.plateNumberInvalid}
                  placeholder="Enter plate number"
                  validEmail={validPlateNumber}
                  name="plateNumber"
                />

                <div className={styles.button}>
                  {!loading && (
                    <Button disabled={!boolen.formIsValid}>Proceed</Button>
                  )}
                  {loading && (
                    <Button disabled={boolen.formIsValid}>Loading...</Button>
                  )}
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
      <BottomNav />
    </div>
  );
}

export default SmartScan;
