import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./NavBar.module.css";

function NavBar() {
  return (
    <div className={styles.navLinks}>
      <NavLink
        to="/history/scan"
        className={(navData) => (navData.isActive ? `${styles.active}` : "")}
      >
        Scan
      </NavLink>
      <NavLink
        to="/history/top-up"
        className={(navData) => (navData.isActive ? `${styles.active}` : "")}
      >
        Top up
      </NavLink>
      <NavLink
        to="/history/debit"
        className={(navData) => (navData.isActive ? `${styles.active}` : "")}
      >
        Debit
      </NavLink>
    </div>
  );
}

export default NavBar;
