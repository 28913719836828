import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import VendorApi from "../../utils/api/VendorApi";
import Button from "../button/Button";
import SelectInput from "../inputs/SelectInput";
import Card from "./Card";
import styles from "./Modal.module.css";

const listPayment = ["Select payment type", "Bank Transfer", "Cash"];

function SubscriptionModal(props) {
  const [formIsValid, setFormIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [payment, setPayment] = useState("");
  const [clickedItem, setClickedItem] = useState(null);
  const [plans, setPlans] = useState(null);
  const [subPlan, setSubPlan] = useState("");

  const token = useSelector((data) => data.auth.token);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const res = await VendorApi.subscriptionPlans(token);
        setPlans(res.data.data);
      } catch (err) {
        console.log(err.response.data.errors);
      }
    };
    fetchPlans();
  }, [token]);

  useEffect(() => {
    if (payment !== "" && subPlan !== "") {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
    if (payment === "Select payment type") {
      setPayment("");
    }
  }, [subPlan, payment]);
  const selectInputChange = (e) => {
    setPayment(e.target.value);
  };
  const subscriptionToggleHandler = (payload, index) => {
    setClickedItem(index);
    setSubPlan(payload);
  };

  const subscriptionSubmitHandler = async () => {
    setLoading(true);
    try {
      const res = await VendorApi.customerSubscription({
        access: token,
        subscription_plan: subPlan,
        payment_method: "CARD",
        customer: props.customerId,
      });
      props.setTitle("Subscription Successful");
      props.setMessage("Customer subscription successful.");
      props.setSuccessModal(true);
      props.setModal(false);
      setLoading(false);
      return res;
    } catch (err) {
      setLoading(false);
      props.setSuccessModal(true);
      props.setModal(false);
      props.setTitle("Subscription Failed!");
      props.setMessage(err.response.data.errors.message);
    }
    setClickedItem(null);
    setSubPlan("");
    setPayment("");
  };
  return (
    <div className={styles.backdrop} onClick={props.close}>
      <Card
        className={styles.modal_subscription}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {props.children}
        <header>
          <h3 className={styles.header}>Subscription</h3>
        </header>
        <div className={styles.content}>
          <p>
            Don't wait until it's too late! Subscribe to our vehicle diagnosis
            plan today and enjoy peace of mind knowing your car is always in top
            condition.
          </p>
        </div>
        <div className={styles.subscriptionOption}>
          {plans?.map((data, index) => {
            const color = index % 2 === 0 ? "#E2FBD7" : "#E7E7E7";
            return (
              <div
                onClick={() => {
                  subscriptionToggleHandler(data.id, index);
                }}
                style={{ backgroundColor: color }}
              >
                <p>{`NGN ${data.price} ${data.description}`}</p>
                {clickedItem === index ? (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 1H5C2.79086 1 1 2.79086 1 5V15C1 17.2091 2.79086 19 5 19H15C17.2091 19 19 17.2091 19 15V5C19 2.79086 17.2091 1 15 1Z"
                      fill="#168912"
                      stroke="white"
                      stroke-width="1.33333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path d="M7 10L9.25 12L13 8" fill="#168912" />
                    <path
                      d="M7 10L9.25 12L13 8"
                      stroke="white"
                      stroke-width="1.33333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 1H5C2.79086 1 1 2.79086 1 5V15C1 17.2091 2.79086 19 5 19H15C17.2091 19 19 17.2091 19 15V5C19 2.79086 17.2091 1 15 1Z"
                      fill="white"
                      stroke="#168912"
                      stroke-width="1.33333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path d="M7 10L9.25 12L13 8" fill="white" />
                    <path
                      d="M7 10L9.25 12L13 8"
                      stroke="#168912"
                      stroke-width="1.33333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                )}
              </div>
            );
          })}
        </div>
        <div className={styles.selectInput}>
          <SelectInput
            onChange={selectInputChange}
            option={listPayment}
            value={payment}
            name="idType"
          />
        </div>

        <footer className={styles.actions2}>
          {!loading && (
            <Button
              onClick={subscriptionSubmitHandler}
              disabled={!formIsValid}
              type="submit"
            >
              Proceed
            </Button>
          )}
          {loading && <Button disabled={formIsValid}>Loading...</Button>}
        </footer>
      </Card>
    </div>
  );
}

export default SubscriptionModal;
