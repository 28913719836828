import React, { useState } from "react";
import Button from "../button/Button";
import Footer from "../footer/Footer";
import styles from "./Contact.module.css";
import VendorApi from "../../utils/api/VendorApi";

function Contact(props) {
  const [loading, setLoading] = useState(false);
  const [sentMail, setSentMail] = useState(false);

  // Function to trigger mail sending
  const submitErrHandler = async (e) => {
    e.preventDefault();
    const message = `Hi Admin, I am ${
      props.data?.user.fullname || props.data3?.customer.user.fullname
    } with PLATE NUMBER ${
      props.data?.plate_number || props.data3?.customer?.plate_number
    } and PHONE NUMBER ${
      props.data?.user?.phone || props.data3?.customer?.user?.phone
    } and this is a link to my recent diagnosis. https://vendor.motormata.com/smart-scan/report-page/${
      props.id
    }. Please I need to book a session with a mechanic.`;
    try {
      setLoading(true);
      const updateCustomerRes = await VendorApi.sendTicket({
        to_name: "Admin",
        from_name: `Customer - ${
          props.data?.user.fullname || props.data3?.customer.user.fullname
        }`,
        message,
      });
      setSentMail(true);
      setLoading(false);
      return updateCustomerRes;
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <div>
      <div className={styles.contact}>
        <div className={styles.warning}>
          <p>
            Kindly take action on the following Error Code listed above to avoid
            further damage to your vehicle. You can contact us now for immediate
            service.
          </p>
        </div>
        <div className={styles.buttons}>
          {!loading ? (
            <Button onClick={!sentMail ? submitErrHandler : null}>
              {!sentMail
                ? `Book a Session with an Online Mechanic`
                : `Request has been made to a mechanic, We will reach out to you soon.`}
            </Button>
          ) : (
            <Button>Loading...</Button>
          )}{" "}
          {/* <a
            href={`https://wa.me/2347080639910?text=${encodeURIComponent(
              message
            )}`}
          >
            <WhiteButton>
              Live chat
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.915 14.382C17.617 14.233 16.156 13.515 15.884 13.415C15.612 13.316 15.414 13.267 15.215 13.565C15.017 13.861 14.448 14.531 14.275 14.729C14.101 14.928 13.928 14.952 13.631 14.804C13.334 14.654 12.376 14.341 11.241 13.329C10.358 12.541 9.761 11.568 9.588 11.27C9.415 10.973 9.569 10.812 9.718 10.664C9.852 10.531 10.015 10.317 10.164 10.144C10.313 9.97004 10.362 9.84604 10.461 9.64704C10.561 9.44904 10.511 9.27604 10.436 9.12704C10.362 8.97804 9.768 7.51504 9.52 6.92004C9.279 6.34104 9.034 6.42004 8.852 6.41004C8.678 6.40204 8.48 6.40004 8.282 6.40004C8.084 6.40004 7.762 6.47404 7.49 6.77204C7.217 7.06904 6.45 7.78804 6.45 9.25104C6.45 10.713 7.514 12.126 7.663 12.325C7.812 12.523 9.758 15.525 12.739 16.812C13.449 17.118 14.002 17.301 14.433 17.437C15.145 17.664 15.793 17.632 16.305 17.555C16.875 17.47 18.063 16.836 18.311 16.142C18.558 15.448 18.558 14.853 18.484 14.729C18.41 14.605 18.212 14.531 17.914 14.382H17.915ZM12.493 21.785H12.489C10.7184 21.7854 8.98037 21.3094 7.457 20.407L7.097 20.193L3.355 21.175L4.354 17.527L4.119 17.153C3.12914 15.5774 2.6053 13.7538 2.608 11.893C2.61 6.44304 7.044 2.00904 12.497 2.00904C15.137 2.00904 17.619 3.03904 19.485 4.90704C20.4054 5.82362 21.1349 6.91361 21.6313 8.11394C22.1277 9.31427 22.3811 10.6011 22.377 11.9C22.375 17.35 17.941 21.785 12.493 21.785ZM20.905 3.48804C19.8032 2.37896 18.4922 1.49958 17.0481 0.900841C15.6039 0.302105 14.0553 -0.00407625 12.492 4.09775e-05C5.938 4.09775e-05 0.602 5.33504 0.6 11.892C0.596963 13.9788 1.14437 16.0294 2.187 17.837L0.5 24L6.804 22.346C8.54788 23.2962 10.5021 23.794 12.488 23.794H12.493C19.047 23.794 24.383 18.459 24.385 11.901C24.3898 10.3383 24.0848 8.79014 23.4874 7.34607C22.8901 5.90201 22.0124 4.59071 20.905 3.48804Z"
                  fill="#008069"
                />
              </svg>
            </WhiteButton>
          </a> */}
        </div>
      </div>
      <div className={styles.footer}>
        <Footer />
      </div>
    </div>
  );
}

export default Contact;
