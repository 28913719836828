import React from "react";
import styles from "./ProfileSummary.module.css";
import photo from "../../assets/Avatar.png";

function ProfileSummary(props) {
  return (
    <div
      className={styles.main_hero_header}
      style={{ backgroundColor: props.color }}
    >
      <div className={styles.main_hero_header_inner}>
        <div className={styles.main_hero_displayImage}>
          <div className={styles.main_hero_displayImage_wrapper}>
            <img src={photo} alt="profile" />
          </div>
          <div className={styles.main_hero_profileInfo}>
            <h4>{props?.vendorInfo[0]?.fullname}</h4>
            <p style={{ color: "#008069" }}>{props.vendorInfo[0]?.email}</p>
            <p>{props?.vendorInfo[0]?.phone}</p>
          </div>
        </div>
        <div className={styles.main_hero_profile}>
          <div className={styles.main_hero_profileInfo}>
            <h4>{props.deviceId?.toUpperCase()}</h4>
            <p>Device ID</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileSummary;
