import React from "react";
import styles from "./History.module.css";
import { Outlet } from "react-router";
import NavBar from "../../components/navbar/NavBar";
import NavBar2 from "./NavBar";
import BottomNav from "../../components/navbar/BottomNav";

function History(props) {
  return (
    <section>
      <NavBar title="History" setShowMenu={props.setShowMenu} />
      <div className={styles.head}>
        <div className={styles.main_hero}>
          <div className={styles.navBar}>
            <NavBar2 />
          </div>

          <Outlet />
        </div>
      </div>
      <BottomNav />
    </section>
  );
}

export default History;
